/** This file is used for Spotfire demos after demo login as opposed to public demonstrations */

import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { Auth } from 'aws-amplify';
import { CircularProgress, Grid } from '@material-ui/core';

const getHeight = () => window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

export default function Spotfire() {
  const [ready, setReady] = useState(false);
  const [height, setHeight] = useState(getHeight());
  const [windowHeight] = useDebounce(height, 300);
  const { template } = useParams();
  const history = useHistory();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        setReady(true);
      })
      .catch(() => {
        history.push('/login');
      });
  }, []);

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      const h = getHeight();
      setHeight(h);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    const templates = {
      'add5a9b9-aeeb-4b20-b72c-bdba46c8d56c': '/Users/54d4hecncmnuee4ogbpgcvzn36evhdmp/Public/Safety-Plots-Demo',
      '7ea5e1a6-8623-4ff9-a170-d03bfb98b67a': '/Users/54d4hecncmnuee4ogbpgcvzn36evhdmp/Public/Claims-Plots-Demo',
      'd9d6f51a-f581-48dc-a0fd-422a1e6743af': '/Users/54d4hecncmnuee4ogbpgcvzn36evhdmp/Public/Benefits-Plots-Demo',
      '9f4da7eb-3801-4c02-a4f8-395547faaa84': '/Users/54d4hecncmnuee4ogbpgcvzn36evhdmp/Public/Transparency-Plots-Demo',
      'cc8ea052-e096-45c1-9a80-bbc4d94f3fc7': '/Users/54d4hecncmnuee4ogbpgcvzn36evhdmp/Public/Rate-Plots-Demo',
      'f467a255-2b26-4118-a078-fdd185cc0c22': '/Users/54d4hecncmnuee4ogbpgcvzn36evhdmp/Public/Populations-Health-Plots-Demo',
    };
    if (template) {
      window.spotfire.webPlayer.createApplication(
        'https://spotfire-next.cloud.tibco.com/spotfire/wp/',
        {
          showAbout: false,
          showAuthor: false,
          showClose: false,
          showCustomizableHeader: false,
          showDodPanel: false,
          showFilterPanel: true,
          showLogin: false,
          showLogout: false,
          showMenu: false,
          showPageNavigation: true,
          showStatusBar: false,
          showToolBar: true,
          showUndoRedo: false,
        },
        templates[template] || '/Users/pvmpku6fpvv3moe73giez7mile5fwgpv/Public/Safety-Plots--Demo',
        null,
        false,
        11.6,
        (response, app) => {
          // onReadyCallback
          // console.log('onReadyCallback');
          // console.log(response);

          if (response.status === 'OK') {
            const doc = app.openDocument('sf', 0);
          }
        }
      );
    }
  }, [template]);

  if (!ready) return <CircularProgress />;

  return (
    <>
      <Grid container style={{ height: windowHeight - 65 }}>
        <Grid item xs={12} id="sf" />
      </Grid>
    </>
  );
}
