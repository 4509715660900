import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Typography, Box, Button, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import sanityClient from '../modules/sanity';

export default function News() {
  const [news, setNews] = useState([]);

  const classes = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    item: {
      marginBottom: theme.spacing(4),
    },
  }))();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "newsPost"]| order(releaseDate desc)[0..20] {
      title,
      slug,
      releaseDate,
      body
    }`
      )
      .then((data) => {
        setNews(data);
      })
      .catch(console.error);
  }, []);

  return (
    <>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Grid container direction="row" justify="center" alignItems="stretch" spacing={2}>
            {news &&
              news.map((post, idx) => (
                <Grid item md={8} xs={12} key={post.slug.current} className={classes.item}>
                  <Typography variant="body2" color="inherit">
                    {moment(post.releaseDate).format('MMMM D, YYYY')}
                  </Typography>
                  <Typography component="h3" variant="h5" color="inherit" gutterBottom>
                    <Link component={RouterLink} to={`/about-us/news/${post.slug.current}`} color="inherit">
                      {post.title}
                    </Link>
                  </Typography>
                  {/* 
                  <Box display="flex" justifyContent="flex-start">
                    <Button component={RouterLink} to={`/about-us/news/${post.slug.current}`} variant="outlined">
                      Read more...
                    </Button>
                  </Box> */}
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
