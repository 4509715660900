import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import Amplify, { API, Auth, graphqlOperation } from 'aws-amplify';
import ReactGA from './modules/google-analytics';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as uuid from 'uuid';
import awsconfig from './aws-exports';
import CartContext from './contexts/CartContext';
import UserContext from './contexts/UserContext';
import { getCart, updateCart } from './modules/cart';
import theme from './modules/theme';
import Routes from './Routes';

Amplify.configure(awsconfig);

function App() {
  // const [email, setEmail] = useState(null);
  const [profile, setProfile] = useState(null);
  const [subscribedDrugs, setSubscribedDrugs] = useState([]);
  const [drugs, setDrugs] = useState([]);
  const [cookies, setCookie] = useCookies(['cartId']);
  const [error, setError] = useState(null);

  const location = useLocation();

  useEffect(() => {
    async function auth() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        // console.log(user)
        setProfile(user.attributes);
      } catch (e) {
        // setEmail(null);
        setProfile(null);
      }
    }

    auth();
  }, []);

  // should also be invoked when an order has been completed.
  const updateSubscribedDrugs = function updateSubscribedDrugs() {
    // check if profile is set (user logged in)
    if (!profile) {
      setSubscribedDrugs([]);
      return;
    }

    // console.log(profile);
    const operation = graphqlOperation(
      `query GetCustomer($username: String!) {
          getCustomer(username: $username) {
            subscribedDrugs
          }
        }
        `,
      { username: profile.sub }
    );

    API.graphql(operation)
      .then((result) => {
        // console.log(result);
        setSubscribedDrugs(result.data.getCustomer?.subscribedDrugs || []);
      })
      .catch((error) => {
        setError(error);
        console.error(error);
      });
  };

  useEffect(() => {
    if (profile) {
      updateSubscribedDrugs();
    } else {
      setSubscribedDrugs([]);
    }
  }, [profile]);

  // supposed to run once to load cart from storage if available
  useEffect(() => {
    const cartId = cookies.cartId || uuid.v4();
    setCookie('cartId', cartId, {
      path: '/',
      expires: moment().add(1, 'year').toDate(),
    });

    getCart(cartId)
      .then((result) => {
        if (result.data.getCart) {
          setDrugs(result.data.getCart.drugs);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);

  // used in shopping experience
  function toggleDrug(drug) {
    const drugsCopy = drugs.slice(); // make a copy
    if (drugsCopy.includes(drug)) {
      drugsCopy.splice(drugsCopy.indexOf(drug), 1);
    } else {
      drugsCopy.push(drug);
    }

    setDrugs(drugsCopy);
    updateCart(cookies.cartId, drugsCopy);
  }

  // invoked after successful order completion
  function resetCart() {
    setDrugs([]);
    return updateCart(cookies.cartId, []);
  }

  return (
    <div className="App">
      <Helmet titleTemplate="%s | careplots.com"></Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserContext.Provider
          value={{
            profile,
            setProfile,
            subscribedDrugs,
            updateSubscribedDrugs,
          }}>
          <CartContext.Provider value={{ drugs, toggleDrug, resetCart }}>
            {/* <NavBar /> */}
            {/* <Container> */}
            <Routes />
            {/* </Container> */}
          </CartContext.Provider>
        </UserContext.Provider>
        {/* <Footer /> */}
      </ThemeProvider>
    </div>
  );
}

export default App;
