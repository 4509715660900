import React from 'react';
import { useParams } from 'react-router-dom';
import ReportPublicDataComponent from '../ReportPublicDataComponent';
import LineChartWithFilters from '../LineChartWithFilters';

const metricId = 'safety_adverse_reactions_trents';

function SafetyTopAdverseReactionsTrends() {
  const { drug } = useParams();
  return (
    <ReportPublicDataComponent title="When were these Adverse Reactions Reported by providers to FDA? Is there a particular 'spike' in recent reports?" metricId={metricId} drug={drug}>
      <LineChartWithFilters />
    </ReportPublicDataComponent>
  );
}

export default SafetyTopAdverseReactionsTrends;
