import { Typography } from '@material-ui/core';
import React from 'react';
import Helmet from 'react-helmet';

function SolutionsEffectivenessAndEfficacyEvaluationReports() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="As drug prices continue to rise, the share of overall health care spending attributable to prescription drugs continues to grow."
        />
        <meta
          name="twitter:description"
          content="As drug prices continue to rise, the share of overall health care spending attributable to prescription drugs continues to grow."
        />
        <meta
          property="og:description"
          content="As drug prices continue to rise, the share of overall health care spending attributable to prescription drugs continues to grow."
        />
      </Helmet>
      <Typography variant="body1" gutterBottom>
        With consumerism in healthcare on the rise, many individual states have either passed or proposed legislation that requires
        healthcare providers to publish their prices online in order to be more transparent. In today&apos;s era of consumerism and
        high-deductible health plans, patients expect to know their financial responsibility of care in advance. With accurate
        patient cost share estimates, hospitals, insurers and pharmaceutical companies can educate their patients on the cost of
        their care and ease the anxiety of growing out-of-pocket healthcare costs. Health care organizations positioned to compete
        on value will fare better than those who are not.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Our leading experts each with more than twenty plus years of healthcare strategy, policy, and claims data experience can
        work with your team to evaluate your organization&apos;s transparency readiness:
      </Typography>
      <ol>
        <li>
          <Typography variant="body1">Comply with regulatory reporting requirements</Typography>
        </li>
        <li>
          <Typography variant="body1">
            Implement an enterprise advanced analytics strategy enabling more effective measurement and timely resolution of
            clinical/financial performance variation
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Embrace value-driven, prospective or capitated managed care incentives; de-emphasize charge-sensitive payment
            arrangements
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Implement patient-level costing and clinical reporting, enabling measurement and reduction of clinical and cost
            variation
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Benchmark the cost of procedures to peers and regional or national costs and Medicare
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Case mix adjustment and risk stratification for your patient population and comparison to regional norms
          </Typography>
        </li>
      </ol>

      <Typography variant="body1" gutterBottom>
        If you would like to learning more about how Careplots can help your organization to navigate price transparency regulatory
        requirements, schedule a call with us and we will provide you a customized opportunity analysis.
      </Typography>
    </>
  );
}

export default SolutionsEffectivenessAndEfficacyEvaluationReports;
