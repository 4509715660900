import { Typography } from '@material-ui/core';
import React from 'react';
import Helmet from 'react-helmet';

function SolutionsRealWorldEvidence() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Careplots is a Real World Data platform that is adding the patient voice in the Real World Evidence determination of the product effectiveness."
        />
        <meta
          name="twitter:description"
          content="Careplots is a Real World Data platform that is adding the patient voice in the Real World Evidence determination of the product effectiveness."
        />
        <meta
          property="og:description"
          content="Careplots is a Real World Data platform that is adding the patient voice in the Real World Evidence determination of the product effectiveness."
        />
      </Helmet>

      <Typography variant="body1" gutterBottom>
        Regulators and manufacturers continue to grapple with how to harness the value of real-world data for demonstrating product
        effectiveness, a process that is related with the concept of Real-world evidence. Triggered by the explosion of COVID19
        cases and the unpreparedness to tackle the virus at its core, new mandates to establish common Real World Data frameworks to
        enable Real World Evidence analyses have been initiated by regulatory agencies.
      </Typography>

      <Typography variant="body1" gutterBottom>
        After many decades in which the Randomized Clinical Trial was the gold standard rule for bringing new products to the
        market, while post-marketing sources were considered secondary data pillars, the needs for prospective monitoring of the
        product performance and the continuous retrospective data analysis are driving the emphasis on integrated multi-sourced
        ‘real world data&apos; systems to shift from measuring product ‘safety&apos; to measuring product ‘effectiveness&apos; and
        to construct ‘personalized health guides&apos;.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Connecting different big healthcare databases to get a more complete picture of patient medical history is critical to
        advancing the use of Real World Evidence and Real World Data as a &quot;complementary resource&quot; in fulfilling the
        promise of personalized medicine development or in evaluating regulatory changes to product labeling about drug
        effectiveness or modifying indications about patient populations or comparative effectiveness or safety information.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Careplots is a Real World Data platform that is adding the patient voice in the Real World Evidence determination of the
        product effectiveness, as many of the data scientific data sources may lack the ‘real world experience&apos; details felt by
        patients themselves. Ultimately, our platform is designed to support complex Real World Evidence analyses and clinical
        decision making in order to understand treatment efficiency, effectiveness and patient propensity to respond positively and
        to heal.
      </Typography>
    </>
  );
}

export default SolutionsRealWorldEvidence;
