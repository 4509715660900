import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { CircularProgress, Grid } from '@material-ui/core';

const getHeight = () => window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

export default function SpotfirePublic() {
  const [ready, setReady] = useState(false);
  const [height, setHeight] = useState(getHeight());
  const [windowHeight] = useDebounce(height, 300);
  const { template } = useParams();

  useEffect(() => {
    setReady(true);
  }, []);

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      const h = getHeight();
      setHeight(h);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    const templates = {
      '8783d4cf-1ffa-4070-aecc-5b4fabea0e8e': '/Users/54d4hecncmnuee4ogbpgcvzn36evhdmp/Public/Safety-Plots-Vax-COVID19',
    };
    if (template) {
      window.spotfire.webPlayer.createApplication(
        'https://spotfire-next.cloud.tibco.com/spotfire/wp/',
        {
          showAbout: false,
          showAuthor: false,
          showClose: false,
          showCustomizableHeader: false,
          showDodPanel: false,
          showFilterPanel: true,
          showLogin: false,
          showLogout: false,
          showMenu: false,
          showPageNavigation: true,
          showStatusBar: false,
          showToolBar: true,
          showUndoRedo: false,
        },
        templates[template] || '/Users/pvmpku6fpvv3moe73giez7mile5fwgpv/Public/Safety-Plots--Demo',
        null,
        false,
        11.6,
        (response, app) => {
          // onReadyCallback
          // console.log('onReadyCallback');
          // console.log(response);

          if (response.status === 'OK') {
            app.openDocument('sf-public', 0);
          }
        }
      );
    }
  }, [template]);

  if (!ready) return <CircularProgress />;

  return (
    <>
      <Grid container style={{ height: windowHeight - 65 }}>
        <Grid item xs={12} id="sf-public" />
      </Grid>
    </>
  );
}
