export function formatNumber(number, fixed = 0) {
  const formatter = new Intl.NumberFormat('en-US', {});

  return formatter.format(number.toFixed(fixed));
}

export function formatCurrency(number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(number);
}

export default formatNumber;
