/* eslint-disable camelcase */
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TextStatement from '../TextStatement';
import formatNumber from '../../modules/text';
import ReportPublicDataComponent from '../ReportPublicDataComponent';

const metricId = 'safety_adverse_reactions_reported';

const RenderComponent = ({ data }) => {
  const [count, setCount] = useState(-1);
  useEffect(() => {
    if (data && data.length > 0) {
      const { cnt } = data[0];
      setCount(cnt);
    }
  }, [data]);

  if (count < 0) {
    return <Typography variant="body1">N/A</Typography>;
  }

  return (
    <TextStatement height={120}>
      <Typography variant="body1">There have been <b>{formatNumber(count)}</b> adverse reactions reported by health care professionals, manufacturers and patients to the FDA.</Typography>
    </TextStatement>
  );
};

RenderComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    cnt: PropTypes.number,
  })),
};

RenderComponent.defaultProps = {
  data: [],
};

function SafetyAdverseReactions() {
  const { drug } = useParams();
  return (
    <ReportPublicDataComponent metricId={metricId} drug={drug}>
      <RenderComponent />
    </ReportPublicDataComponent>
  );
}

export default SafetyAdverseReactions;
