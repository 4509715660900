import { Typography } from '@material-ui/core';
import React from 'react';
import Helmet from 'react-helmet';

function AboutUsMission() {
  return (
    <>
      <Helmet>
        <meta name="description" content="Our objective is to be the analytic partner of choice for health care organizations." />
        <meta
          name="twitter:description"
          content="Our objective is to be the analytic partner of choice for health care organizations."
        />
        <meta
          property="og:description"
          content="Our objective is to be the analytic partner of choice for health care organizations."
        />
      </Helmet>
      <Typography variant="body1" gutterBottom>
        Our objective is to be the data analytic partner of choice for health care organizations that are looking to implement
        innovative value-based care payment models. Our mission is to be the catalyst for massive, measurable, data-informed
        decisions for gauging health care management effectiveness, outcomes and performance through:{' '}
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            Data: integrate data in a flexible, open and scalable platform to power healthcare's digital transformation
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Analytics: deliver analytic applications and services that generate insight on how to measurably improve outcomes and
            pricing models
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Expertise: provide technology, financial and operational experts who enable and accelerate improvement
          </Typography>
        </li>
      </ul>
      <Typography variant="body1" gutterBottom>
        Careplots is partnering with risk bearing entities, public and private insurers and drug manufacturers to deploy our data
        analytics capabilities and subject matter expertise to support the development of outcomes-based contracts. From payers and
        providers to life sciences organizations and major employers, anyone who participates in the healthcare ecosystem can
        benefit from Careplots capabilities.
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            For health plans, our platform provides fast data capture and processing mechanisms, producing powerful analytics and
            insights into performance of drugs and devices, patient and populations medical outcomes and economic performance
            monitoring and benchmarks.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            For life sciences companies, our platform empowers health economics and outcomes research, the identification of optimal
            treatment protocols, and outcomes-based contracting.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            For consumers, our platform enable patients and seekers of health care information to easily find medical products that
            worked for individuals with similar characteristics and symptomatic.
          </Typography>
        </li>
      </ul>
    </>
  );
}

export default AboutUsMission;
