import { Container, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import Helmet from 'react-helmet';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import HomeSection from '../components/HomeSection';
import InfoSection from '../components/InfoSection';
import Products from './Products';
import Services from './Services';
import Solutions from './Solutions';
import NewsHome from './NewsHome';

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(8),
  },
  footerContainer: {
    position: 'relative',
    background: 'rgba(0,0,0,0.4)',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    marginTop: theme.spacing(8),
  },
}));

export default function Home() {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta name="description" content="Empowering healthcare transformation through data innovation." />
        <meta name="twitter:description" content="Empowering healthcare transformation through data innovation." />
        <meta property="og:description" content="Empowering healthcare transformation through data innovation." />
      </Helmet>
      <Hero />

      <InfoSection
        title="Let us help you manage your data end to end."
        text="Leverage our modern health care data integration framework in a secure and scalable cloud environment."
        link="/services/data-management"
        backgroundImage="/images/data-management.jpg"
      />

      <InfoSection
        title="Let us help you modernize your data infrastructure."
        text="Leverage our experience in building secure computing data platforms within public and private clouds,  designed to protect data, promote analysis, enable research and collaboration."
        link="/services/data-infrastructure"
        backgroundImage="/images/data-infrastructure.jpg"
      />

      <HomeSection backgroundImage="/images/backgrounds/services.jpg">
        <div className={classes.root}>
          <Typography variant="h2" align="center" style={{ textTransform: 'uppercase' }} gutterBottom>
            Services
          </Typography>
          <Services hideHelmet />
        </div>
      </HomeSection>

      <InfoSection
        title="Let us help you make sense of your data."
        text="Leverage our consulting services, industry experience, tools and methodologies to provide highest quality solutions to your clients."
        link="/solutions/"
        backgroundImage="/images/understand-data.jpg"
      />

      <HomeSection backgroundImage="/images/backgrounds/solutions.jpg">
        <div className={classes.root}>
          <Typography variant="h2" align="center" style={{ textTransform: 'uppercase' }} gutterBottom>
            Solutions
          </Typography>
          <Solutions hideHelmet />
        </div>
      </HomeSection>

      <InfoSection
        title="Use our software products to execute your health care transformation strategy."
        text="Leverage our cutting edge products including advanced analytic and reporting packages to drive comprehensive performance measurement across health care providers, payers and manufacturers."
        link="/products/"
        backgroundImage="/images/software-tools.jpg"
      />

      <HomeSection backgroundImage="/images/backgrounds/products.jpg">
        <div className={classes.root}>
          <Typography variant="h2" align="center" style={{ textTransform: 'uppercase' }} gutterBottom>
            Products
          </Typography>
          <Products hideHelmet />
        </div>
      </HomeSection>

      <NewsHome backgroundImage="/images/backgrounds/news.jpg" />

      <HomeSection backgroundImage="/images/pills1-min.jpg">
        <Container className={classes.footerContainer}>
          <Footer />
        </Container>
      </HomeSection>
    </>
  );
}
