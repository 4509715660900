import { Typography } from '@material-ui/core';
import React from 'react';
import Helmet from 'react-helmet';

function AboutUsTechnology() {
  return (
    <>
      <Helmet>
        <meta name="description" content="We're providing a secure, scalable and robust cloud-based software platform " />
        <meta name="twitter:description" content="We're providing a secure, scalable and robust cloud-based software platform " />
        <meta property="og:description" content="We're providing a secure, scalable and robust cloud-based software platform " />
      </Helmet>

      <Typography variant="body1" gutterBottom>
        We&apos;re providing a secure, scalable and robust cloud-based software platform that automates and quantifies the efficacy
        and effectiveness of medical products to treat specific conditions and serves as a comprehensive valuation guide to measure
        outcomes for payment or contract purposes. The application architecture is supported by a unified data integration platform
        automates data checks, normalization, validations, unified data modeling, and has a master patient index, integrating
        regulatory data with social media and health care clinical and administrative data.
      </Typography>

      <Typography variant="body1" gutterBottom>
        The analytic layer is supported by a comprehensive library of specialized clinical algorithms from domains such as natural
        language processing, case mix adjustment and risk stratification, episode of care, reinforced machine learning, as well as
        advanced visualizations &amp; reporting user interfaces. The infrastructure architecture is designed on highly secure cloud
        environment that is HIPPA compliant requirements. The platform is flexible and customizable, supporting both ‘reports on
        demand&apos; from Careplots ‘real world data’ repository, as well as &apos;software-as-a-service&apos; implementation
        utilizing customer&apos;s data with a faster deployment than conventional services models.
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          marginBottom: 20,
        }}>
        <img src="/images/tech.png" alt="Technology" />
      </div>

      {/* <img src="/images/tech.png" alt="Technology" style={{ opacity: 0.8 }} /> */}
    </>
  );
}

export default AboutUsTechnology;
