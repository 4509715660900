import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Typography, makeStyles, Grid, Link } from '@material-ui/core';
import ReportPublicDataComponent from '../ReportPublicDataComponent';

const metricId = 'data_other_drugs_in_atc4_class';

const RenderComponent = ({ data }) => {
  if (!data || data.length === 0) {
    return <Typography variant="body1">N/A</Typography>;
  }

  const classes = makeStyles(() => ({
    drugName: {
      textTransform: 'capitalize',
      display: 'block',
    },
  }))();

  const items = [...data];
  const half = Math.ceil(items.length / 2);
  const firstHalf = items.splice(0, half);
  const secondHalf = items.splice(-half);

  return (
    <Grid container>
      <Grid item xs={6}>
        {firstHalf.map((d) => (
          <Link
            component={RouterLink}
            to={`/drug-details/${d.proprietaryname.toLowerCase()}`}
            variant="body1"
            className={classes.drugName}
            key={d.proprietaryname}>
            {d.proprietaryname.toLowerCase()}
          </Link>
        ))}
      </Grid>
      <Grid item xs={6}>
        {secondHalf.map((d) => (
          <Link
            component={RouterLink}
            to={`/drug-details/${d.proprietaryname.toLowerCase()}`}
            variant="body1"
            className={classes.drugName}
            key={d.proprietaryname}>
            {d.proprietaryname.toLowerCase()}
          </Link>
        ))}
      </Grid>
    </Grid>
  );
};

RenderComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ proprietaryname: PropTypes.string })),
};

RenderComponent.defaultProps = {
  data: [],
};

function OtherDrugsInClass() {
  const { drug } = useParams();
  return (
    <ReportPublicDataComponent title="Other Drugs in Class" metricId={metricId} drug={drug}>
      <RenderComponent />
    </ReportPublicDataComponent>
  );
}

export default OtherDrugsInClass;
