import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Typography, Button, Box, makeStyles, Link } from '@material-ui/core';

function InfoBlock({ title, text, icon, iconColor, to, sidePadding }) {
  const classes = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(sidePadding || 8),
      paddingRight: theme.spacing(sidePadding || 8),
      paddingBottom: theme.spacing(sidePadding || 4),
      // background: 'rgb(49,27,146)',
      background: 'linear-gradient(180deg, rgba(13,8,32,1) 0%, rgba(117,117,117,0.8) 100%)',
      // backgroundImage: 'linear-gradient(rgba(14, 10, 33, 0.8), rgba(183, 203, 222, 0.2)), url(/images/pills5.jpeg)',
    },
    icon: {
      fontSize: 40,
      color: iconColor,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    title: {
      textTransform: 'uppercase',
      color: iconColor,
      marginBottom: theme.spacing(4),
    },
  }))();
  const DisplayIcon = icon;
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Link component={RouterLink} to={to} color="inherit">
            <DisplayIcon className={classes.icon} />
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="h3" align="center" className={classes.title} gutterBottom>
            <Link component={RouterLink} to={to} color="inherit">
              {title}
            </Link>
          </Typography>
          <Typography variant="body1" aliign="center">
            {text}
          </Typography>
          <Box display="flex" justifyContent="flex-end">
            <Button component={RouterLink} to={to} variant="outlined">
              Learn more...
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default InfoBlock;
