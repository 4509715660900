import { Typography } from '@material-ui/core';
import React from 'react';
import Helmet from 'react-helmet';

function SolutionsMultiSourcePharmacovigilance() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Adverse drug reactions come at a considerable cost on society as they are a significant cause of morbidity and mortality worldwide."
        />
        <meta
          name="twitter:description"
          content="Adverse drug reactions come at a considerable cost on society as they are a significant cause of morbidity and mortality worldwide."
        />
        <meta
          property="og:description"
          content="Adverse drug reactions come at a considerable cost on society as they are a significant cause of morbidity and mortality worldwide."
        />
      </Helmet>

      <Typography variant="body1" gutterBottom>
        Adverse drug reactions come at a considerable cost on society as they are a significant cause of morbidity and mortality
        worldwide. In fact, ADRs account for nearly 7% of hospital admissions in the U.S. alone, according to some estimates, and
        fully half of these may be avoidable, according to other studies. That presents important challenges for pharmaceutical
        companies, as they face intense pressure to analyze data sooner, monitor risks more broadly and carefully, and accurately
        report every known patient event around the world. That pressure has only intensified with the explosion in data related to
        intelligent and personalized medicines.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Although drugs are passed through a series of trials to establish their efficacy and safety in human beings before they get
        marketing approval, not all the adverse effects caused by a drug can be detected in trials, because trials results are
        limited by strict inclusion criteria, relatively small sample size and short study period. Hence, it becomes necessary for
        the pharma industry to have a system that continuously scan the market for signs of serious safety issues and is able to
        disclose such type of adverse drug reaction(s) (ADRs) as soon as these are detected.
      </Typography>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          marginBottom: 20,
        }}>
        <img src="/images/safety-plots-AE-Wordcloud-dark.png" alt="Social ADR" style={{ opacity: 0.8 }} />
      </div>

      <Typography variant="body1" gutterBottom>
        Using proprietary machine learning (ML) and natural language processing (NLP) algorithms, Careplots platform provides
        accurate signal detection as well as proactive risk management, with tools and methods for identifying, analyzing, and
        evaluating, communicating potentially serious issues. Our platform continuously monitors data signals from spontaneous
        reporting systems worldwide, numerous social media sites and large claims data repositories, collecting, validating and
        standardizing millions of data points that are curated and conformed to clinical standards.
      </Typography>
    </>
  );
}

export default SolutionsMultiSourcePharmacovigilance;
