import { Grid } from '@material-ui/core';
import { green, indigo, orange, purple, red } from '@material-ui/core/colors';
import React from 'react';
import Helmet from 'react-helmet';
import Bio from '../components/Bio';

function AboutUs() {
  return (
    <>
      <Helmet>
        <meta name="description" content="Careplots executive team members." />
        <meta name="twitter:description" content="Careplots executive team members." />
        <meta property="og:description" content="Careplots executive team members." />
      </Helmet>
      <Grid container direction="row" justify="center" alignItems="stretch" spacing={2}>
        <Grid item xs={12} sm={6} md={4} style={{ display: 'flex' }}>
          <Bio
            name="Cristian C. Ilie"
            title="Chief Executive Officer"
            initials="CI"
            backgroundColor={indigo.A100}
            bio="Cristian is a seasoned professional with decades of corporate and startup experience, specializing in white space innovation at the intersection of health, business and technology. An entrepreneur with record of business acquisition, experienced in managing startups and growing them to maturity, he lead the development of innovative digital platforms powering applications in population health, precision medicine, health data sensors and care management. He also has extensive corporate experience, having held senior positions at E&amp;Y, UPMC, Tibco, Blue Cross, NORC at University of Chicago."
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} style={{ display: 'flex' }}>
          <Bio
            name="Peter J. Lee"
            title="Chief Operations Officer"
            initials="PL"
            backgroundColor={orange[500]}
            bio="Peter is a senior healthcare executive also with 20+ years of corporate experience, expert in transforming companies into innovative data driven organizations for large health plans and life sciences organizations. He lead data engineering teams in constructing major Big Data platforms as well as software engineering teams in building advanced analytic platforms in the value based health care market. He previously held various senior positions at Accenture, Blue Cross plans, UnitedHealth Group, NORC at University of Chicago, as well as government health agencies. "
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ display: 'flex' }}>
          <Bio
            name="Kumar Sharma"
            title="Chief Data and Security Officer"
            initials="KS"
            backgroundColor={green[500]}
            bio="Kumar is a technology visionary with a core ability to make the complex simple. His 20 years history of advising executives in the healthcare, insurance and software industries has cemented ability to use technology to generate tangible value. He is well versed in implementation of enterprise software solution and he has vast expertise in leading enterprise architecture teams directing data security, operations, governance and analytics, as well as technical and engineering experience in leading multi-disciplinary and global product development organizations."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={5} style={{ display: 'flex' }}>
          <Bio
            name="Aleksey G. Budovsky"
            title="Chief Technology Officer"
            initials="AB"
            backgroundColor={purple.A100}
            sidePadding={1}
            bio="Aleksey is a multi-hat and full stack technology officer with more than 20 years experience in the frontlines of the digital health space, leading software development teams and overseeing system and product design, architecture, development, testing, and deployment of mobile applications for consumers and web applications for health care businesses."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={5} style={{ display: 'flex' }}>
          <Bio
            name="Dan Froicu, MD"
            title="Head of Medical Affairs"
            initials="DF"
            sidePadding={1}
            backgroundColor={red.A100}
            bio="Dan is a medical doctor with over 20 years of experience in administering patient care and medical affairs including sales, contracting and medical management. Dan completed his surgery internship at Albert Einstein in New York then Anesthesiology at Yale University and he worked for pharmaceutical and medical device companies such as Bayer and Storz."
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AboutUs;
