import React from 'react';

const RequestReportContext = React.createContext({
  drug: null,
  setDrug: (drug) => { },
  drugName: null,
  setDrugName: (drug) => { },
  condition: null,
  setCondition: (condition) => { },
});

export default RequestReportContext;
