import { Grid } from '@material-ui/core';
import { blue, green, lime, orange, pink, red, purple, indigo } from '@material-ui/core/colors';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import TransformIcon from '@material-ui/icons/Transform';
import PublicIcon from '@material-ui/icons/Public';
import React from 'react';
import HealingIcon from '@material-ui/icons/Healing';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoBlock from '../components/InfoBlock';

export default function Solutions({ hideHelmet }) {
  return (
    <>
      {!hideHelmet && (
        <Helmet>
          <meta name="description" content="Careplots solutions." />
          <meta name="twitter:description" content="Careplots solutions." />
          <meta property="og:description" content="Careplots solutions." />
        </Helmet>
      )}
      <Grid container direction="row" justify="center" alignItems="stretch" spacing={4}>
        <Grid item xs={12} md={6} style={{ display: 'flex' }}>
          <InfoBlock
            sidePadding={4}
            title="Value-Based Payment"
            text="We put our data, analytics, technology, and domain expertise within reach, helping customers discover better, faster paths to value based care financial relationships."
            icon={LocalOfferIcon}
            iconColor={green.A200}
            to="/solutions/value-based-care"
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ display: 'flex' }}>
          <InfoBlock
            title="Population health management"
            text="Use our software products and subject matter expertise to accelerate your populations health management strategy for medical management, CMS direct contracting, ACO operations."
            icon={HealingIcon}
            iconColor={blue.A100}
            to="/solutions/population-health"
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ display: 'flex' }}>
          <InfoBlock
            sidePadding={4}
            title="Health Care Cost Transparency"
            text="Use our expertise in designing and operating consumer tools for health care cost transparency to evaluate and plan your organization's transparency readiness."
            icon={AttachMoneyIcon}
            iconColor={blue.A200}
            to="/solutions/health-care-cost-transparency"
          />
        </Grid>
        {/* <Grid item xs={12} md={4} style={{ display: 'flex' }}>
          <InfoBlock
            title="Clinical wasteful spending"
            text="With accurate patient estimates, hospitals, insurers and pharmaceutical companies can educate their patients on the cost of their care and ease the anxiety of growing out-of-pocket healthcare costs."
            icon={ReportProblemIcon}
            iconColor={indigo.A100}
            to="/solutions/clinical-wasteful-spending"
          />
        </Grid> */}
        {/* <Grid item xs={12} md={4} style={{ display: 'flex' }}>
          <InfoBlock
            sidePadding={4}
            title="Social Determinants of Health"
            text="Careplots data platform is focusing on the social determinants impact on medical products efficacy and effectiveness."
            icon={ImportContactsIcon}
            iconColor={lime.A200}
            to="/solutions/social-determinants-of-health"
          />
        </Grid> */}

        <Grid item xs={12} md={6} style={{ display: 'flex' }}>
          <InfoBlock
            sidePadding={4}
            title="Social pharmacovigilance"
            text="Use our pharmacovigilance innovative tools and methods for proactive risk management by identifying, analyzing, evaluating, communicating potentially serious adverse drug reactions (ADR) as soon as they are detected."
            icon={NotificationsActiveIcon}
            iconColor={red.A200}
            to="/solutions/multi-source-pharmacovigilance"
          />
        </Grid>

        {/* <Grid item xs={12} md={4} style={{ display: 'flex' }}>
          <InfoBlock
            sidePadding={4}
            title="Medical Device Vigilance"
            text="The Medical Device Vigilance solution is designed to collect information on post-market incidents or adverse events related to medical devices and to distribute or disseminate such information to prevent adverse events from recurring."
            icon={DevicesOtherIcon}
            iconColor={orange.A200}
            to="/solutions/medical-device-vigilance"
          />
        </Grid>

        <Grid item xs={12} md={4} style={{ display: 'flex' }}>
          <InfoBlock
            sidePadding={4}
            title="Real World Evidence"
            text="Regulators and manufacturers continue to grapple with how to harness the value of real-world data (RWD) for demonstrating product effectiveness, a process that is related with the concept of Real-world evidence (RWE)."
            icon={PublicIcon}
            iconColor={pink.A200}
            to="/solutions/social-determinants-of-health"
          />
        </Grid>

        <Grid item xs={12} md={4} style={{ display: 'flex' }}>
          <InfoBlock
            sidePadding={4}
            title="HealthCare Digital Transformation"
            text="The Healthcare industry was slow to adopt digital solutions. Very few companies in healthcare sector are able to combine digital activity with strong leadership to turn technology into transformation."
            icon={TransformIcon}
            iconColor={purple.A200}
            to="/solutions/healthcare-digital-transformation-solutions"
          />
        </Grid> */}
      </Grid>
    </>
  );
}

Solutions.propTypes = {
  hideHelmet: PropTypes.bool,
};

Solutions.defaultProps = {
  hideHelmet: false,
};
