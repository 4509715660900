/* eslint-disable max-len */
import React, { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  Button, Grid, FormControl, Select, Input, InputLabel, Link, FormHelperText, MenuItem, Typography,
} from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import './SignUp.css';
import { formStyles } from '../components/formStyles';
import LoginFormsContainer from './LoginFormsContainer';

export default function SignUp() {
  // const { setEmail } = useContext(UserContext);
  const history = useHistory();
  const classes = formStyles();
  const [error, setError] = useState(null);

  const signupSchema = yup.object({
    email: yup.string().required('Please enter email address.').email('Please enter a valid email address.'),
    password: yup.string().required('Please enter password').matches(/(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      'Password must be at least 8 characters in length and contain: at least one upper case letter, at least one lower case letter, at least one number or a special character.'),
    firstName: yup.string().required('Please enter your first name'),
    lastName: yup.string().required('Please enter your last name'),
    address1: yup.string().required('Please enter address'),
    city: yup.string().required('Please enter city'),
    state: yup.string().required('Please select state'),
    zip: yup.string().required('Please enter zip'),
  });

  const createUser = async (values) => {
    setError(null); // reset error
    try {
      await Auth.signUp({
        username: values.email,
        password: values.password,
        attributes: {
          email: values.email,
          'custom:company': values.company,
          'custom:firstName': values.firstName,
          'custom:lastName': values.lastName,
          'custom:address1': values.address1,
          'custom:address2': values.address2,
          'custom:city': values.city,
          'custom:state': values.state,
          'custom:zip': values.zip,
        },
      });
      // setEmail(values.email); // set email
      history.push('/verify');
    } catch (e) {
      setError(e);
    }
  };

  return (
    <LoginFormsContainer>
      <Formik
        onSubmit={createUser}
        initialValues={{
          email: '',
          password: '',
          company: '',
          firstName: '',
          lastName: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          zip: '',
        }}
        validationSchema={signupSchema}
      >
        {({
          handleSubmit, handleChange, values, errors, touched,
        }) => (
            <form noValidate onSubmit={handleSubmit}>
              <h3>Login credentials</h3>

              <FormControl fullWidth>
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  autoFocus
                  required
                  type="email"
                  name="email"
                  autoComplete="username"
                  value={values.email}
                  onChange={handleChange}
                />
                {touched.email && errors.email && <FormHelperText error>{errors.email}</FormHelperText>}
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  required
                  type="password"
                  name="password"
                  autoComplete="new-password"
                  value={values.password}
                  onChange={handleChange}
                />
                {touched.password && errors.password && <FormHelperText error>{errors.password}</FormHelperText>}
              </FormControl>
              <h3>Contact information</h3>

              <FormControl fullWidth>
                <InputLabel htmlFor="firstName">First name</InputLabel>
                <Input
                  required
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                />
                {touched.firstName && errors.firstName && <FormHelperText error>{errors.firstName}</FormHelperText>}
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor="lastName">Last name</InputLabel>
                <Input
                  required
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                />
                {touched.lastName && errors.lastName && <FormHelperText error>{errors.lastName}</FormHelperText>}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel htmlFor="company">Company name</InputLabel>
                <Input
                  type="text"
                  name="company"
                  value={values.company}
                  onChange={handleChange}
                  placeholder="Your company name"
                />
                {touched.company && errors.company && <FormHelperText>{errors.company}</FormHelperText>}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel htmlFor="address1">Address</InputLabel>
                <Input
                  placeholder="1234 Main St"
                  type="text"
                  name="address1"
                  value={values.address1}
                  onChange={handleChange}
                />
                {touched.address1 && errors.address1 && <FormHelperText error>{errors.address1}</FormHelperText>}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel htmlFor="address2">Address 2</InputLabel>
                <Input
                  placeholder="Apartment, studio, or floor"
                  type="text"
                  name="address2"
                  value={values.address2}
                  onChange={handleChange}
                />
                {touched.address2 && errors.address2 && <FormHelperText error>{errors.address2}</FormHelperText>}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel htmlFor="city">City</InputLabel>
                <Input
                  type="text"
                  required
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                />
                {touched.city && errors.city && <FormHelperText error>{errors.city}</FormHelperText>}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel htmlFor="state">State</InputLabel>
                <Select name="state" onChange={handleChange} value={values.state} required>
                  <MenuItem>Choose...</MenuItem>
                  <MenuItem value="AL">Alabama</MenuItem>
                  <MenuItem value="AK">Alaska</MenuItem>
                  <MenuItem value="AZ">Arizona</MenuItem>
                  <MenuItem value="AR">Arkansas</MenuItem>
                  <MenuItem value="CA">California</MenuItem>
                  <MenuItem value="CO">Colorado</MenuItem>
                  <MenuItem value="CT">Connecticut</MenuItem>
                  <MenuItem value="DE">Delaware</MenuItem>
                  <MenuItem value="FL">Florida</MenuItem>
                  <MenuItem value="GA">Georgia</MenuItem>
                  <MenuItem value="HI">Hawaii</MenuItem>
                  <MenuItem value="ID">Idaho</MenuItem>
                  <MenuItem value="IL">Illinois</MenuItem>
                  <MenuItem value="IN">Indiana</MenuItem>
                  <MenuItem value="IA">Iowa</MenuItem>
                  <MenuItem value="KS">Kansas</MenuItem>
                  <MenuItem value="KY">Kentucky</MenuItem>
                  <MenuItem value="LA">Louisiana</MenuItem>
                  <MenuItem value="ME">Maine</MenuItem>
                  <MenuItem value="MD">Maryland</MenuItem>
                  <MenuItem value="MA">Massachusetts</MenuItem>
                  <MenuItem value="MI">Michigan</MenuItem>
                  <MenuItem value="MN">Minnesota</MenuItem>
                  <MenuItem value="MS">Mississippi</MenuItem>
                  <MenuItem value="MO">Missouri</MenuItem>
                  <MenuItem value="MT">Montana</MenuItem>
                  <MenuItem value="NE">Nebraska</MenuItem>
                  <MenuItem value="NV">Nevada</MenuItem>
                  <MenuItem value="NH">New Hampshire</MenuItem>
                  <MenuItem value="NJ">New Jersey</MenuItem>
                  <MenuItem value="NM">New Mexico</MenuItem>
                  <MenuItem value="NY">New York</MenuItem>
                  <MenuItem value="NC">North Carolina</MenuItem>
                  <MenuItem value="ND">North Dakota</MenuItem>
                  <MenuItem value="OH">Ohio</MenuItem>
                  <MenuItem value="OK">Oklahoma</MenuItem>
                  <MenuItem value="OR">Oregon</MenuItem>
                  <MenuItem value="PA">Pennsylvania</MenuItem>
                  <MenuItem value="RI">Rhode Island</MenuItem>
                  <MenuItem value="SC">South Carolina</MenuItem>
                  <MenuItem value="SD">South Dakota</MenuItem>
                  <MenuItem value="TN">Tennessee</MenuItem>
                  <MenuItem value="TX">Texas</MenuItem>
                  <MenuItem value="UT">Utah</MenuItem>
                  <MenuItem value="VT">Vermont</MenuItem>
                  <MenuItem value="VA">Virginia</MenuItem>
                  <MenuItem value="WA">Washington</MenuItem>
                  <MenuItem value="WV">West Virginia</MenuItem>
                  <MenuItem value="WI">Wisconsin</MenuItem>
                  <MenuItem value="WY">Wyoming</MenuItem>
                </Select>
                {touched.state && errors.state && <FormHelperText error>{errors.state}</FormHelperText>}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel htmlFor="zip">Zip</InputLabel>
                <Input
                  type="text"
                  required
                  name="zip"
                  value={values.zip}
                  onChange={handleChange}
                />
                {touched.zip && errors.zip && <FormHelperText error>{errors.zip}</FormHelperText>}
              </FormControl>
              {error && <Typography variant="body2" color="error" gutterBottom>{error.message}</Typography>}
              <Button
                type="submit"
                variant="contained"
                fullWidth
                color="primary"
                className={classes.submit}
              >
                Create profile
              </Button>
              <Grid container>
                <Grid item xs style={{ display: 'flex' }}>
                  <Typography variant="body1" color="inherit">Have an account?&nbsp;</Typography>
                  <Link component={RouterLink} to="/login" variant="body1" color="inherit">
                    Log in
                  </Link>
                </Grid>
                <Grid item>
                  &nbsp;
                </Grid>
              </Grid>
            </form>
          )}
      </Formik>
    </LoginFormsContainer>
  );
}
