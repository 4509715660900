import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { lightBlue } from '@material-ui/core/colors';

function LabelValue({ label, children }) {
  const classes = makeStyles((theme) => ({
    label: {
      marginTop: theme.spacing(2),
      color: lightBlue['200'],
      fontWeight: 'bold',
      fontSize: 'larger',
      textTransform: 'uppercase',
    },
    text: {
      textTransform: 'capitalize',
    },
  }))();

  return (
    <>
      <Typography variant="body1" className={classes.label}>{label}</Typography>
      <Typography variant="body1" className={classes.text}>
        {children}
      </Typography>
    </>
  );
}

LabelValue.propTypes = {
  label: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

LabelValue.defaultProps = {
  label: 'Undefined',
};

export default LabelValue;
