import React from 'react';
import Disclaimers from './Disclaimers';

function DeviceDisclaimers() {
  return (
    <Disclaimers text="Careplots uses medical device safety data reported to FDA by manufacturers, facilities and other healthcare professionals. This is a valuable source of information for consumers to understand potential risks associated with certain device uses, however FDA advises that the these reports cannot be used in isolation to reach conclusions about the existence, severity, or frequency of problems associated with devices due to under-reporting and lack of verification that the device caused the reported event. While the data collected from regulators from other countries and patient data from worldwide social media sites will be integrated in Careplots platform, these inferences may become more plausible. Patients are encouraged to discuss medical devices safety with their physicians, but ultimately to follow the guidelines and treatments prescribed by doctors, as they can best assess risk and benefits for each individual patient profile." />
  );
}

export default DeviceDisclaimers;
