import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

function BenefitPlots() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Benefit Plots is a web based comprehensive calculator and recommendation engine for consumers looking to select best care coverage options"
        />
        <meta
          name="twitter:description"
          content="Benefit Plots is a web based comprehensive calculator and recommendation engine for consumers looking to select best care coverage options"
        />
        <meta
          property="og:description"
          content="Benefit Plots is a web based comprehensive calculator and recommendation engine for consumers looking to select best care coverage options"
        />
      </Helmet>
      <Typography variant="body1" gutterBottom>
        Benefit Plots is a web based comprehensive calculator and recommendation engine for consumers looking to select best care
        coverage options at the most cost-effective costs, maximizing the out of pocket cost savings for employees. The product is
        designed for employees in the employer-based insurance market, as well as for private individuals in the individual
        insurance market.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Time is typically limited to choose the best health insurance plan but rushing and picking the wrong one can be costly.
        Benefit Plots offer a start-to-finish guide to choosing the best plan for an employee and family, whether it&apos;s through
        the federal marketplace or an employer. When comparing different plans, one family&apos;s medical needs should be calculated
        based the amount and type of treatment has received in the past.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Benefit Plots makes possible to forecast medical expenses, calculating trends can help one make an informed decision. The
        consumer&apos;s portion of costs consists of the deductible, copayments and coinsurance and the total one can spend out of
        pocket in a year is limited, out-of-pocket maximum being listed in plan information. In general, the lower the premium, the
        higher the out-of-pocket costs, however the consumer goal is to narrow down choices based on out-of-pocket costs.
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: 20 }}>
        <img src="/images/BenefitPlots-AskTablets.png" alt="Benefit plots data survey" />
      </div>
      <Typography variant="body1" gutterBottom>
        A plan that pays a higher portion of medical costs, but has higher monthly premiums, may be better if primary physician or a
        specialist visits are frequent, or emergency care is frequent or if expensive or brand-name medications are needed on a
        regular basis, a baby is expected, or planned surgery is coming up, or if a there was a history of chronic condition such as
        diabetes or cancer.
      </Typography>

      <Typography variant="body1" gutterBottom>
        The data provided by consumers is anonymized, aggregated and risk stratified, enabling Careplots platform to intake better
        quality, richer and more accurate patient generated data, expanding the clinical, insurance coverage and demographics data
        with socio-economics factors, family medical history, employment categories, lifestyle habits, census and geographical
        characteristics, facilitating understanding social determinants of health impact on medical products performance and
        effectiveness.
      </Typography>
    </>
  );
}

BenefitPlots.propTypes = {
  color: PropTypes.string,
};

BenefitPlots.defaultProps = {
  color: '#fff',
};

export default BenefitPlots;
