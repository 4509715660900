import React, { useEffect, useState, useContext } from 'react';
import { API } from 'aws-amplify';
import { CircularProgress, Typography } from '@material-ui/core';
import ReportContext from '../contexts/ReportContext';
import TitledComponent from './TitledComponent';

function ReportDataComponent(props) {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const { token } = useContext(ReportContext);

  useEffect(() => {
    async function getData() {
      try {
        const response = await API.get('reportData', '/data', { queryStringParameters: { metricId: props.metricId, token } });
        if (response.data) {
          setData(JSON.parse(response.data.json));
        }
      } catch (e) {
        setError(e);
      }
      setReady(true);
    }

    getData();
  }, [props.metricId]);

  if (!ready) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Typography variant="body1" color="error" align="center">
        {error.message}
      </Typography>
    );
  }

  return (
    <TitledComponent title={props.title} alignTitle={props.alignTitle}>
      {React.cloneElement(props.children, { data })}
    </TitledComponent>
  );
}

export default ReportDataComponent;
