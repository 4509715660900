import { Auth, API, graphqlOperation } from 'aws-amplify';
import { updateCart as updateCartMutation, createCart as createCartMutation } from '../graphql/mutations';
import { getCart as getCartQuery } from '../graphql/queries';

export async function getCart(id) {
  return API.graphql(Object.assign(graphqlOperation(getCartQuery, { id }), { authMode: 'AWS_IAM' }));
}

export async function updateCart(id, drugs) {
  let userId = null;
  try {
    const user = await Auth.currentAuthenticatedUser();
    // console.log(user);
    userId = user.username;
  } catch (e) {
    // nothing to do
  }

  const getCartResult = await getCart(id);

  if (!getCartResult.data.getCart) {
    // need to create a new cart with this id
    await API.graphql(Object.assign(graphqlOperation(createCartMutation, { input: { id, drugs: [], userId } }), { authMode: 'AWS_IAM' }));
  }

  return API.graphql(Object.assign(graphqlOperation(updateCartMutation, { input: { id, drugs, userId } }), { authMode: 'AWS_IAM' }));
}
