import { Typography } from '@material-ui/core';
import React from 'react';
import Helmet from 'react-helmet';

function SolutionsValueBasedCare() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Careplots domain experts have the expertise in assisting public and private health care organizations to navigate the
        challenging aspects of adopting and implementing value-based care risk sharing arrangements."
        />
        <meta
          name="twitter:description"
          content="Careplots domain experts have the expertise in assisting public and private health care organizations to navigate the
        challenging aspects of adopting and implementing value-based care risk sharing arrangements."
        />
        <meta
          property="og:description"
          content="Careplots domain experts have the expertise in assisting public and private health care organizations to navigate the
        challenging aspects of adopting and implementing value-based care risk sharing arrangements."
        />
      </Helmet>

      <Typography variant="body1" gutterBottom>
        Value-based healthcare is a healthcare delivery model in which providers, including hospitals and physicians, are paid based
        on patient health outcomes and not based on the amount of healthcare they deliver, as in the fee-for-service model. Under
        value-based care agreements, providers are incentivized and rewarded for improving the health of their patients. The new
        healthcare delivery models stress a team-oriented approach to patient care as well as greater sharing of patient data so
        that the care is coordinated and outcomes can be measured more effectively. However, the transition to value-based care has
        proven to be more difficult as poorly designed and executed models can lead to short-term financial hits for the providers.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Careplots domain experts have the expertise in assisting public and private health care organizations to navigate the
        challenging aspects of adopting and implementing value-based care risk sharing arrangements. While the Careplots technology
        platform is designed to assist suppliers of medical products to implement value-based payment model, Careplots can also
        offer consulting services for value based model implementation for primary and facility care providers.
      </Typography>

      <Typography variant="body1" gutterBottom>
        CMS Direct Contracting (DC), a delivery model that emphasizes coordinated approach to patient care, led by a patient's
        primary physician who directs a patient&apos;s total clinical care team including primary, specialty, and acute care. This
        model relies on the sharing CMS claims data and clinical records (EHRs) among all providers on the coordinated care team,
        allowing providers to see results of tests and procedures performed by other clinicians on the team. This data sharing has
        the potential to reduce redundant care and associated costs.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Accountable Care Organization (ACO) model in which doctors, hospitals, and other healthcare providers work as a networked
        team to deliver the best possible coordinated care at the lowest possible cost. Each member of the team shares both risk and
        reward, with incentives to improve access to care, quality of care, and patient health outcomes while reducing costs. ACOs
        stress coordination and data sharing among team members to help achieve these goals among their entire patient population.
        Clinical and claims data are also shared with payers to demonstrate improvements in outcomes such as hospital readmissions,
        adverse events, patient engagement, and population health.
      </Typography>
      <Typography variant="body1" gutterBottom>
        CMS&apos;s Hospital Value-Based Purchasing Program (VBP), in which acute care hospitals receive adjusted payments based on
        the quality of care they deliver. The program encourages hospitals to improve the quality and safety of acute inpatient care
        for all patients by: eliminating or reducing adverse events, adopting evidence-based care standards and protocols that make
        the best outcomes for patients, creating better patient care experiences, increasing care transparency for consumers, etc.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Bundles of care payment models, in which providers and/or healthcare facilities are paid a single payment for all the care
        performed to treat a patient undergoing a specific episode of care. These arrangements are designed to pay multiple
        providers for coordinating the total amount required for a single, pre-defined episode of care. The model has been a popular
        method for embracing value-based care without fully immersing providers in downside financial risk contracts.
      </Typography>

      <Typography variant="body1" gutterBottom>
        The success of these models is dependent on advanced technology and data sharing solutions, including clinical, claims and
        patient survey, to continuously monitor quality of care and performance metrics in order to improve clinical outcomes. The
        methodology is complex, requiring capabilities to build longitudinal patient data profiles, execute risk stratification and
        aggregating data into episodes of care and benchmarking performance and efficiency measures by peer. If you are interested
        in learning more about how Careplots can help your organization to design and to implement effective value-based care
        delivery models, schedule a call with us and we will provide you a free customized opportunity analysis.
      </Typography>
    </>
  );
}

export default SolutionsValueBasedCare;
