import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import NavBar from '../Navbar';

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    // marginBottom: theme.spacing(2),
    // marginTop: theme.spacing(1),
    backgroundImage: 'url(/images/pills5-min.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: 600,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    background: 'linear-gradient(90deg, rgba(13,8,32,1) 0%, rgba(117,117,117,0.2) 100%)',
    // backgroundColor: 'rgba(0,0,0,.3)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingTop: theme.spacing(12),
      paddingRight: 0,
    },
  },
}));

function Hero() {
  const classes = useStyles();
  return (
    <Paper className={classes.mainFeaturedPost} square>
      {/* Increase the priority of the hero background image */}
      {/* {<img style={{ display: 'none' }} src={post.image} alt={post.imageText} />} */}
      <div className={classes.overlay} />
      <NavBar />
      <Grid container>
        <Grid item md={6} xs={10}>
          <div className={classes.mainFeaturedPostContent}>
            <Typography component="h1" variant="h4" color="inherit" gutterBottom>
              Which Drugs &amp; Devices Really Work For You?
            </Typography>
            <Typography variant="h6" color="inherit" paragraph>
              Find your answers with Careplots, a Real World Evidence data platform that tracks patient safety, social sentiment,
              clinical efficacy and health economic outcomes for health products, powering efficient design for value based payment
              models and consumer personalized health guides.
            </Typography>
            {/* <Link variant="subtitle1" href="#">
                {post.linkText}
              </Link> */}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Hero;
