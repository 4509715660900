import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './product.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import Amplify from 'aws-amplify';
import App from './App';
import * as serviceWorker from './serviceWorker';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <Router>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </Router>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <Router>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </Router>,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
