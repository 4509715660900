/* eslint-disable camelcase */
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import ReportPublicDataComponent from '../ReportPublicDataComponent';
import TextStatement from '../TextStatement';
import formatNumber from '../../modules/text';

const metricId = 'social_adverse_reactions_reported';

const RenderComponent = ({ data }) => {
  if (!data || data.length === 0) {
    return <Typography variant="body1">N/A</Typography>;
  }

  const {
    adverse_reactions_reported,
  } = data[0];

  return (
    <TextStatement height={120}>
      <Typography variant="body1">There have been <b>{formatNumber(adverse_reactions_reported)}</b> adverse reactions reported by patients in social media channels. <i>Data collection is ongoing</i>.</Typography>
    </TextStatement>
  );
};

RenderComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    adverse_reactions_reported: PropTypes.number,
  })),
};

RenderComponent.defaultProps = {
  data: [],
};

function SocialAdverseReactions() {
  const { drug } = useParams();
  return (
    <ReportPublicDataComponent title="" metricId={metricId} drug={drug}>
      <RenderComponent />
    </ReportPublicDataComponent>
  );
}

export default SocialAdverseReactions;
