import { makeStyles, Typography } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import React from 'react';

function TitledComponent(props) {
  const { title, alignTitle, children } = props;

  const classes = makeStyles((theme) => ({
    title: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      color: orange.A700,
      textTransform: 'capitalize',
    },
  }))();

  return (
    <>
      {title && (
        <Typography variant="h6" align={alignTitle} className={classes.title}>
          {title}
        </Typography>
      )}
      {children}
    </>
  );
}

TitledComponent.propTypes = {
  title: PropTypes.string,
  alignTitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

TitledComponent.defaultProps = {
  alignTitle: 'left',
  title: '',
};

export default TitledComponent;
