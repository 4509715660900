import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import pink from '@material-ui/core/colors/pink';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let baseTheme = createMuiTheme({
  typography: {
    // fontSize: 18,
    htmlFontSize: 14,
    fontFamily: '"Arial", sans-serif',
  },
  palette: {
    type: 'dark',
    background: {
      default: grey[800],
      paper: '#272c34',
    },
    text: {
      primary: '#fff',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
      hint: 'rgba(255, 255, 255, 0.5)',
      icon: 'rgba(255, 255, 255, 0.5)',
    },
    action: {
      active: '#fff',
      hover: 'rgba(255, 255, 255, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(255, 255, 255, 0.16)',
      selectedOpacity: 0.16,
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(255, 255, 255, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
    primary: {
      main: blue[500],
      contrastText: '#fff',
    },
    secondary: {
      main: pink.A400,
      contrastText: '#fff',
    },
  },
});

baseTheme = responsiveFontSizes(baseTheme);

const theme = createMuiTheme({
  ...baseTheme,
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: baseTheme.spacing(2), // 16px
      },
    },
  },
});

export default theme;
