import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

function APCDPlots({ color }) {
  const classes = makeStyles((theme) => ({
    subtitle: {
      marginTop: theme.spacing(2),
      color,
    },
  }))();
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Powerful Software Platform for Operating the next generation of All Payer Claims Database Systems."
        />
        <meta
          name="twitter:description"
          content="Powerful Software Platform for Operating the next generation of All Payer Claims Database Systems."
        />
        <meta
          property="og:description"
          content="Powerful Software Platform for Operating the next generation of All Payer Claims Database Systems."
        />
      </Helmet>

      <Typography variant="h5" component="h2" className={classes.subtitle} gutterBottom>
        Key features
      </Typography>

      <Typography variant="body1" gutterBottom>
        Powerful software platform for operating the next generation of All Payer Claims Database Systems. Engineered for
        seamingless integration with public data initiatives such as Health Information Exchanges (HIE), public health or state
        Medicaid data lakes. Automated software as a service solution including data submission, data validation, data processing,
        most common value adds, and analytic reports. Modern scalable platform as a service (PaaS) for cloud enabled self-service
        analysis model. Scalable to include data for as many commercial and public payers as required. Scalable to supports monthly
        loading without increasing the services costs.
      </Typography>
      {/* <PageImage>
        <img src="/images/apcd-data-management-platform.jpg" alt="Salesforce CRM" />
      </PageImage> */}

      <Typography variant="h5" component="h2" className={classes.subtitle} gutterBottom>
        Health data standardization
      </Typography>
      <Typography variant="body1" gutterBottom>
        Providing Common Data Layout (CDL) mapping model for commercial, Medicare, Medicare data. For states looking to convert
        legacy APCD data to CDL 2 standards or just starting an APCD. Support for the Observational Medical Outcomes Partnership
        (OMOP) Common Data Model (CDM). Support for Observational Health Data Sciences and Informatics (ODHSI) standardized
        vocabularies.
      </Typography>

      <Typography variant="h5" component="h2" className={classes.subtitle} gutterBottom>
        Data submission
      </Typography>
      <Typography variant="body1" gutterBottom>
        Modern data submission portal based on Salesforce CRM software. Allows for the tracking of work flow &amp; status, alerts
        and other reporting capabilities with minimal system configuration. Improved end user experience, registration, navigation,
        data uploads, notifications, data checks. Enables interfaces for the submission of CDL formatted data as well as collection
        of alternative payment data. Faster performance and ease of scale for other digital capabilities without the dependency on
        physical on-premise custom technology stack. Faster digital enablement and customer solutions with prebuilt and easy to use
        APIs, enabling automated workflows and easier feature additions.
      </Typography>

      <Typography variant="h5" component="h2" className={classes.subtitle} gutterBottom>
        Health data exchanges
      </Typography>
      <Typography variant="body1" gutterBottom>
        Leveraging advancements in FHIR standards for patient data exchanges from EHRs and Health Insurance systems. Enabling
        frequent data updates via FHIR 4.X BULK protocol Leveraging CMS innovations to standardize API data exchange for public and
        commercial insurers (CARIN) Engineered for administrative and clinical data integration based on Mulesoft API architecture
        alleviating a big limitation regarding data timeliness and reducing data latency.
      </Typography>

      <Typography variant="h5" component="h2" className={classes.subtitle} gutterBottom>
        Master Patient Index
      </Typography>
      <Typography variant="body1" gutterBottom>
        A Master Patient Index that can work for both administrative and clinical data. Adding continuous quality improvements in
        Master Patient Index solution via commercially available SaaS solutions. Seamingless integration with industry&amp;s most
        advanced identity management software solutions (NexGate)
      </Typography>

      <Typography variant="h5" component="h2" className={classes.subtitle} gutterBottom>
        Enhanced data protection
      </Typography>
      <Typography variant="body1" gutterBottom>
        Reducing the risk of data breaches and patient re-identification via PII ‘identifiers hashing’ data security architecture.
        Separation of personal direct identifiers from clinical data. Enabling opt-outs mechanism for state residents.
      </Typography>

      <Typography variant="h5" component="h2" className={classes.subtitle} gutterBottom>
        Data lakes architecture
      </Typography>
      <Typography variant="body1" gutterBottom>
        Data lakes architecture optimized for quick linkages, research and policy analyses. Policy making and evaluation intersects
        with outcomes and quality research, surveillance and consumer information. Transitioning from legacy data warehouses to
        agile data lakes and data linkages to health care and non-health care data sources. Automated registries built for
        populations, risk groups, diseases, drug classes.
      </Typography>

      <Typography variant="h5" component="h2" className={classes.subtitle} gutterBottom>
        Stewardship workflow automation
      </Typography>
      <Typography variant="body1" gutterBottom>
        Automate data requests forms, DUA management, delivery of authorized data sets to authorized requestors via Salesforce
        workflow modules. Get insights into data requests, data products, revenue projections via standardized reports in the APCD
        portal. Limit the proliferation of word documents, pdf files and excel documents. Efficiently manage your hundreds of
        researcher DUAs, compliance with data request terms, data destruction, etc.
      </Typography>

      <Typography variant="h5" component="h2" className={classes.subtitle} gutterBottom>
        Analytics and reporting
      </Typography>
      <Typography variant="body1" gutterBottom>
        Value adds for risk stratification and bundles of care for all lines of business. Common standard reports for service
        expenditure and utilization, quality of care and performance evaluations safely designed for state evaluation purposes.
        Select from hundreds of pre-calculated metrics for medical and drug costs, service utilization, quality of care and
        prevention, provider performance assessment. Design your population health management strategy and select applicable
        reports. Design your value based care strategy and select applicable reports.
      </Typography>

      <Typography variant="h5" component="h2" className={classes.subtitle} gutterBottom>
        High performance computing workspaces
      </Typography>
      <Typography variant="body1" gutterBottom>
        Providing cloud based, secured and performant virtual workspaces, enabling authorized analysts to get access to data,
        performing complex analyses, ad-hoc reports scalable to add as many users as possible, with tier models for data access /
        analysis offering self-service data analysis packages from business intelligence, statistical and GIS tools to advanced
        machine learning containers. Compliant to FISMA NIST 800-53A standards and HIPPA requirements industry most performant and
        cost effective data storage (S3 buckets, Glacier). Industry most performant and cost effective database software solutions
        (Aurora, Redshift, Snowflake)
      </Typography>
    </>
  );
}
APCDPlots.propTypes = {
  color: PropTypes.string,
};

APCDPlots.defaultProps = {
  color: '#fff',
};
export default APCDPlots;
