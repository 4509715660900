import React from 'react';
import RequestReport from '../components/RequestReport';

function Search() {
  return (
    <>
      <RequestReport />
      <RequestReport />
    </>);
}

export default Search;
