import {
  TextField, Typography, CircularProgress, Chip, makeStyles,
} from '@material-ui/core';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import DrugsList from './DrugsList';
import ReactGA from '../modules/google-analytics';

function DrugSearchByCondition() {
  const [condition, setDeviceName] = useState('');
  const [searchCondition] = useDebounce(condition, 1000);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const [deviceTypes, setConditions] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [drugs, setDrugs] = useState([]);
  const [ready, setReady] = useState(true);

  const classes = makeStyles((theme) => ({
    chips: {
      marginBottom: theme.spacing(2),
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    resultsArea: {
      marginTop: theme.spacing(2),
    },
  }))();

  const handleDeviceNameChange = (e) => {
    setDeviceName(e.target.value);
  };

  useEffect(() => {
    setMessage('');
    setError(null);
    setSelectedCondition(null);
    setConditions(null);
    setDrugs(null);

    async function getConditions() {
      setReady(false);
      try {
        const response = await API.get('rawData', '/conditions', { queryStringParameters: { q: searchCondition.trim() } });
        if (response.error) {
          throw response.error;
        }
        setConditions(response);
        if (response.length === 0) {
          setMessage('No results. Please try a different search.');
        }
      } catch (e) {
        setError(e);
      } finally {
        setReady(true);
      }
    }

    // if we have something to work with
    if (searchCondition && searchCondition.length > 2) {
      ReactGA.event({
        category: 'Drug search',
        action: 'Searched condition',
        label: searchCondition,
      });
      getConditions();
    }
  }, [searchCondition]);

  useEffect(() => {
    setError(null);
    async function getDrugsByCondition() {
      setDrugs(null);
      setReady(false);
      try {
        const response = await API.get('rawData', '/drugs', { queryStringParameters: { condition: selectedCondition } });
        if (response.error) {
          throw response.error;
        }
        setDrugs(response);
        if (response.length === 0) {
          setMessage('No results. Please try a different search.');
        }
      } catch (e) {
        setError(e);
      } finally {
        setReady(true);
      }
    }

    if (selectedCondition) {
      ReactGA.event({
        category: 'Drug search',
        action: 'Selected condition',
        label: selectedCondition,
      });
      getDrugsByCondition();
    }
  }, [selectedCondition]);

  return (
    <>
      <TextField
        label="Search by condition"
        value={condition}
        onChange={handleDeviceNameChange}
        fullWidth
      />
      <div className={classes.resultsArea}>
        {message && <Typography variant="body1" color="primary">{message}</Typography>}
        {error && <Typography variant="body1" color="error">{error.message}</Typography>}
        {ready && deviceTypes && !selectedCondition && (
          <div className={classes.chips}>
            {deviceTypes.map((t) => (
              <Chip
                color="primary"
                key={t.class_name}
                label={t.class_name}
                onClick={() => {
                  setSelectedCondition(t.class_name);
                  setDrugs([]);
                }}
              />
            ))}
          </div>
        )}
        {ready && selectedCondition
          && (
            <div className={classes.chips}>
              <Chip label={selectedCondition} color="secondary" onDelete={() => (setSelectedCondition(null))} />
            </div>
          )}
        {ready && selectedCondition && drugs && <DrugsList drugs={drugs} />}
        {!ready && <CircularProgress />}
      </div>
    </>
  );
}

export default DrugSearchByCondition;
