import React from 'react';
import {
  Grid, Typography, Paper, makeStyles, Avatar,
} from '@material-ui/core';

function Bio({
  name, title, initials, bio, sidePadding, backgroundColor,
}) {
  const classes = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(sidePadding || 2),
      // background: 'rgb(49,27,146)',
      background: 'linear-gradient(180deg, rgba(13,8,32,1) 0%, rgba(117,117,117,0.8) 100%)',
      // backgroundImage: 'linear-gradient(rgba(14, 10, 33, 0.8), rgba(183, 203, 222, 0.2)), url(/images/pills5.jpeg)',
    },
    bio: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    avatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      marginBottom: theme.spacing(2),
      backgroundColor: backgroundColor || theme.palette.secondary.main,
      color: theme.palette.getContrastText(backgroundColor || theme.palette.secondary.main),
    },
    title: {
      marginTop: theme.spacing(2),
      color: backgroundColor,
    },
  }))();

  return (

    <Paper className={classes.root}>
      <Grid container>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Avatar className={classes.avatar}>{initials}</Avatar>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">{name}</Typography>
          <Typography variant="subtitle1" align="center" className={classes.title}>{title}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs className={classes.bio}>
          <Typography variant="body1">
            {bio}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Bio;
