import React, { useContext } from 'react';
import {
  Typography,
  Table, TableBody, TableRow, TableCell, Button,
  TableHead,
} from '@material-ui/core';
import CartContext from '../contexts/CartContext';
import UserContext from '../contexts/UserContext';

function CartContents() {
  const { drugs, toggleDrug } = useContext(CartContext);
  const { subscribedDrugs } = useContext(UserContext);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Drug name</TableCell>
          <TableCell align="right">Subscription price</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {drugs.map((drug) => (
          <TableRow key={drug}>
            <TableCell align="left">
              {drug}
              {subscribedDrugs.includes(drug)
                && (
                  <Typography variant="body2" color="error">
                    You are already subscribed to this drug. Please remove to avoid multiple charges.
                  </Typography>
                )}
            </TableCell>
            <TableCell align="right"><Typography variant="body2">$5000 set up and first year. $950 yearly renewal</Typography></TableCell>
            <TableCell align="right" onClick={() => { toggleDrug(drug); }}><Button color="secondary">Delete</Button></TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell />
          <TableCell align="right">
            Total:
            {formatter.format(drugs.length * 5000)}
          </TableCell>
          <TableCell />
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default CartContents;
