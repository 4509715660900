import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import TitledComponent from '../TitledComponent';
import { formatCurrency } from '../../modules/text';

function DrugPrice() {
  const { drug } = useParams();
  const [price, setPrice] = useState(0);
  const [unit, setUnit] = useState('EA');
  const [error, setError] = useState(null);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (drug) {
      setReady(false);
      axios
        .get(`https://data.medicaid.gov/resource/rt4v-78r4.json?$where=ndc_description%20like%20%27${drug.toUpperCase()}%25%27`)
        .then((response) => {
          if (response.status === 200) {
            const prices = response.data;
            setPrice(prices[0].nadac_per_unit);
            setUnit(prices[0].pricing_unit);
          } else {
            setError(new Error('Could not fetch price information'));
          }
        })
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          setReady(true);
        });
    }
  }, [drug]);

  if (!ready) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Typography variant="body1" color="error" align="center">
        {error.message}
      </Typography>
    );
  }

  return (
    <TitledComponent title="Price" alignTitle="left">
      <Typography variant="body1">{`${formatCurrency(price)}/${unit}`}</Typography>
    </TitledComponent>
  );
}

export default DrugPrice;
