/* eslint-disable camelcase */
import { Typography } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Cell, Legend, Pie, PieChart, ResponsiveContainer,
} from 'recharts';
import ReportPublicDataComponent from '../ReportPublicDataComponent';

const metricId = 'social_sentiment_post_counts';

const RenderComponent = ({ data }) => {
  if (!data || data.length === 0) {
    return <Typography variant="body1">N/A</Typography>;
  }

  const shapedData = [];

  // negative_count: 535
  // neutral_count: 66
  // positive_count: 278

  shapedData.push({ name: 'Negative', value: data[0].negative_count });
  shapedData.push({ name: 'Neutral', value: data[0].neutral_count });
  shapedData.push({ name: 'Positive', value: data[0].positive_count });

  const colors = ['#e15759', '#4e79a7', '#59a14f'];

  return (
    <ResponsiveContainer height={300}>
      <PieChart
        data={_.values(shapedData)}
      >
        <Pie data={shapedData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={100}>
          {
            shapedData.map((entry, index) => <Cell key={entry.name} fill={colors[index]} />)
          }
        </Pie>
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

RenderComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ positive_count: PropTypes.number, neutral_count: PropTypes.number, negative_count: PropTypes.number })),
};

RenderComponent.defaultProps = {
  data: [],
};

function SocialSentimentPostCounts() {
  const { drug } = useParams();
  return (
    <ReportPublicDataComponent title="Social: Patient Sentiment" metricId={metricId} drug={drug}>
      <RenderComponent />
    </ReportPublicDataComponent>
  );
}

export default SocialSentimentPostCounts;
