/* eslint-disable react/jsx-one-expression-per-line */
import { Grid, Link, makeStyles, Typography, Box } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import TwitterIcon from '@material-ui/icons/Twitter';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ResponsiveContainer } from 'recharts';

function Footer() {
  const classes = makeStyles((theme) => ({
    root: {
      // padding: theme.spacing(4),
      // background: 'rgba(0,0,0,0.37)',
      // color: '#f2f2f2',
      // backdropFilter: 'blur(5px)',
    },
    title: {
      marginBottom: theme.spacing(2),
      '&:last-of-type': {
        marginTop: theme.spacing(4),
      },
    },
    link: {
      color: theme.palette.text.primary,
    },
    map: {
      marginTop: theme.spacing(3),
    },
    phone: {
      marginTop: theme.spacing(2),
    },
    bottomBox: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    bottomLink: {
      color: theme.palette.text.primary,
      marginLeft: theme.spacing(2),
      justifySelf: 'flex-end',
      alignSelf: 'center',
      textDecoration: 'underline',
    },
  }))();
  return (
    <footer className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body2">
            <b>Careplots, Inc.</b>
          </Typography>
          <Typography variant="body2">
            101 Lindenwood Dr, Ste 225-633,
            <br />
            Malvern, PA 19355
            <span
              className={classes.phone}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <PhoneIcon fontSize="small" />
              &nbsp;484 806 0102
            </span>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <EmailIcon fontSize="small" />
              &nbsp;
              <Link className={classes.link} href="mailto:info@careplots.com">
                info@careplots.com
              </Link>
            </span>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <TwitterIcon fontSize="small" />
              &nbsp;
              <Link target="_new" className={classes.link} href="https://twitter.com/careplots">
                @careplots
              </Link>
            </span>
          </Typography>
          <div className={classes.map}>
            <ResponsiveContainer width="80%" aspect={1.8}>
              <img src="/images/careplots-map-office.png" alt="Careplots map" />
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={classes.title}>
                Solutions
              </Typography>
              <Link className={classes.link} component={RouterLink} to="/solutions/value-based-care">
                <Typography variant="body2">Value-Based Payment</Typography>
              </Link>
              <Link className={classes.link} component={RouterLink} to="/solutions/population-health">
                <Typography variant="body2">Population Health Management</Typography>
              </Link>
              <Link className={classes.link} component={RouterLink} to="/solutions/health-care-cost-transparency">
                <Typography variant="body2">Health Care Costs Transparency</Typography>
              </Link>
              {/* <Link className={classes.link} component={RouterLink} to="/solutions/clinical-wasteful-spending">
                <Typography variant="body2">Clinical Wasteful Spending</Typography>
              </Link>
              <Link className={classes.link} component={RouterLink} to="/solutions/social-determinants-of-health">
                <Typography variant="body2">Social Determinants of Health</Typography>
              </Link> */}
              <Link className={classes.link} component={RouterLink} to="/solutions/multi-source-pharmacovigilance">
                <Typography variant="body2">Social Pharmacovigilance</Typography>
              </Link>
              {/* <Link className={classes.link} component={RouterLink} to="/solutions/medical-device-vigilance">
                <Typography variant="body2">Medical Device Vigilance</Typography>
              </Link> 
              <Link className={classes.link} component={RouterLink} to="/solutions/real-world-evidence">
                <Typography variant="body2">Real World Evidence</Typography>
              </Link>
              <Link className={classes.link} component={RouterLink} to="/solutions/healthcare-digital-transformation-solutions">
                <Typography variant="body2">Healthcare Digital Transformation</Typography>
              </Link> */}

              <Typography variant="h6" className={classes.title}>
                Products
              </Typography>

              <Link className={classes.link} component={RouterLink} to="/products/apcd-plots">
                <Typography variant="body2">Claims Plots</Typography>
              </Link>
              <Link className={classes.link} component={RouterLink} to="/products/safety-plots">
                <Typography variant="body2">Safety Plots</Typography>
              </Link>
              <Link className={classes.link} component={RouterLink} to="/products/outcome-plots">
                <Typography variant="body2">Outcome Plots</Typography>
              </Link>
              {/* <Link className={classes.link} component={RouterLink} to="/products/benefit-plots">
                <Typography variant="body2">Benefit Plots</Typography>
              </Link>
              <Link className={classes.link} component={RouterLink} to="/products/mycare-plots">
                <Typography variant="body2">myCare Plots</Typography>
              </Link>
              <Link className={classes.link} component={RouterLink} to="/products/data-plots">
                <Typography variant="body2">Data Plots</Typography>
              </Link> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={classes.title}>
                Services
              </Typography>

              <Link className={classes.link} component={RouterLink} to="/services/data-management">
                <Typography variant="body2">Data Management</Typography>
              </Link>
              <Link className={classes.link} component={RouterLink} to="/services/data-infrastructure">
                <Typography variant="body2">Data Infrastructure</Typography>
              </Link>
              <Link className={classes.link} component={RouterLink} to="/services/health-care-analytics-services">
                <Typography variant="body2">Healthcare Analytics </Typography>
              </Link>
              <Link className={classes.link} component={RouterLink} to="/services/healthcare-digital-transformation-services">
                <Typography variant="body2">Healthcare Digital Transformation</Typography>
              </Link>

              <Typography variant="h6" className={classes.title}>
                About Us
              </Typography>

              <Link className={classes.link} component={RouterLink} to="/about-us/news">
                <Typography variant="body2">News</Typography>
              </Link>
              <Link className={classes.link} component={RouterLink} to="/about-us/mission">
                <Typography variant="body2">Mission</Typography>
              </Link>
              <Link className={classes.link} component={RouterLink} to="/about-us/history">
                <Typography variant="body2">History</Typography>
              </Link>
              <Link className={classes.link} component={RouterLink} to="/about-us/story">
                <Typography variant="body2">Story</Typography>
              </Link>
              <Link className={classes.link} component={RouterLink} to="/about-us/technology">
                <Typography variant="body2">Technology</Typography>
              </Link>
              <Link className={classes.link} component={RouterLink} to="/about-us/executive-team">
                <Typography variant="body2">Team</Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.bottomBox}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}>
          <Typography variant="body1">&copy; {new Date().getFullYear()} Careplots, Inc. All Rights Reserved</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}>
          <Link className={classes.bottomLink} component={RouterLink} to="/privacy-policy">
            <Typography variant="body1">Privacy Policy</Typography>
          </Link>
          <Link className={classes.bottomLink} component={RouterLink} to="/terms-of-service">
            <Typography variant="body1">Terms of Service</Typography>
          </Link>
        </Box>
      </Box>
    </footer>
  );
}

export default Footer;
