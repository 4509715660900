import {
  Button, FormControl, FormHelperText, Input, InputLabel, Typography,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { formStyles } from '../components/formStyles';
import LoginFormsContainer from './LoginFormsContainer';

export default function Verify() {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const classes = formStyles();
  const history = useHistory();

  const confirmationSchema = yup.object({
    email: yup.string().required('Please enter email address.').email('Please enter a valid email address.'),
    confirmationCode: yup.string().required('Please enter confirmation code')
  });

  const validateConfirmation = (values) => {
    setError(null); // reset error
    setSuccess(null);
    Auth.confirmSignUp(values.email, values.confirmationCode).then(() => {
      setSuccess('Email was successfully verified. You will be redirected to the login page shortly.');
      setTimeout(() => { history.push('/login'); }, 3000);
    }).catch((e) => {
      console.error(e);
      setError(e);
    });
  };

  return (
    <LoginFormsContainer title="Verify account">

      <Formik validationSchema={confirmationSchema} onSubmit={validateConfirmation} initialValues={{ confirmationCode: '', email: '' }}>
        {({ handleSubmit, handleChange, touched, errors, values }) => (
          <form noValidate onSubmit={handleSubmit} className={classes.form}>
            <Typography variant="body1" color="textPrimary">Please check your email and find a verification code, then fill and submit this form.</Typography>
            <FormControl margin="normal" fullWidth>
              <InputLabel>Enter email address</InputLabel>
              <Input
                autoFocus
                required
                name="email"
                type="email"
                onChange={handleChange}
                autoComplete="username"
                value={values.email}
                error={touched.email && !!errors.email}
              />
              {(touched.email && !!errors.email) && <FormHelperText type="invalid">{errors.email}</FormHelperText>}

            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel>Enter confirmation code</InputLabel>
              <Input
                required
                type="text"
                name="confirmationCode"
                onChange={handleChange}
                value={values.confirmationCode}
                error={touched.confirmationCode && !!errors.confirmationCode}
              />
              {(touched.confirmationCode && !!errors.confirmationCode) && <FormHelperText type="invalid">{errors.confirmationCode}</FormHelperText>}
            </FormControl>
            {error && <Typography variant="body2" color="error">{error.message}</Typography>}
            {success && <Typography variant="body2" color="primary">{success}</Typography>}
            <Button
              type="submit"
              variant="contained"
              fullWidth
              color="primary"
              className={classes.submit}
            >
              Verify
            </Button>
          </form>
        )}
      </Formik>
    </LoginFormsContainer>
  );
}
