/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Container, Breadcrumbs, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Helmet from 'react-helmet';
import NavBar from '../Navbar';
import Footer from './Footer';

function PageTemplate(props) {
  const { children, backgroundImage, color, hideFooter, hideTitle, hideBreadcrumbs, pageContainerPadding } = props;
  const childrenArray = React.Children.toArray(children);
  const childElements = [];
  childrenArray.forEach((child) => {
    childElements.push(React.cloneElement(child, { backgroundImage, color }));
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [children]);

  const classes = makeStyles((theme) => ({
    root: {
      position: 'relative',
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundAttachment: 'fixed',
      // paddingBottom: theme.spacing(6),
      // minHeight: 600,
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        background: 'linear-gradient(180deg, rgba(13,8,32,1) 0%, rgba(13,8,32,0.5) 25%, rgba(13,8,32,0.5) 100%)',
      },
    },
    titleContainer: {
      position: 'relative',
      marginTop: theme.spacing(2),
    },
    pageContainer: {
      padding: theme.spacing(pageContainerPadding),
      position: 'relative',
      background: 'rgba(0,0,0,0.4)',
      backdropFilter: 'blur(5px)',
      // background: 'linear-gradient(180deg, rgba(13,8,32,1) 0%, rgba(117,117,117,0.5) 100%)',
    },
    footerContainer: {
      position: 'relative',
      background: 'rgba(0,0,0,0.4)',
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      marginTop: theme.spacing(8),
    },
    pageTitle: {
      color: props.color,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  }))();
  const { section, title, maxWidth, sectionPage } = props;

  let pageTitle = title;
  if (!sectionPage) {
    pageTitle += ` | ${section}`;
  }
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="twitter:site" content="@careplots" />
        <meta name="twitter:title" content={`${pageTitle} | Careplots`} />
        <meta name="twitter:creator" content="@careplots" />

        <meta property="og:title" content={`${pageTitle} | Careplots`} />
        <meta property="og:type" content="article" />

        <meta property="og:site_name" content="Careplots" />
      </Helmet>

      <div className={classes.root}>
        <NavBar />
        {!hideBreadcrumbs && !hideTitle && (
          <Container className={classes.titleContainer} maxWidth={maxWidth}>
            {!hideBreadcrumbs && (
              <Breadcrumbs>
                <Typography component={RouterLink} to="/" variant="body1" color="textSecondary">
                  Home
                </Typography>
                {!sectionPage && (
                  <Typography
                    component={RouterLink}
                    to={`/${section.toLowerCase().replace(' ', '-')}`}
                    variant="body1"
                    color="textSecondary">
                    {section}
                  </Typography>
                )}
                {title && (
                  <Typography variant="body1" color="textPrimary">
                    {title}
                  </Typography>
                )}
              </Breadcrumbs>
            )}
            {!hideTitle && (
              <Typography className={classes.pageTitle} component="h1" variant="h4" align="center" gutterBottom>
                {title}
              </Typography>
            )}
          </Container>
        )}
        <Container className={classes.pageContainer} maxWidth={maxWidth}>
          {childElements}
        </Container>
        {!hideFooter && (
          <Container className={classes.footerContainer} maxWidth="lg">
            <Footer />
          </Container>
        )}
      </div>
    </>
  );
}

PageTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  backgroundImage: PropTypes.string,
  section: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  maxWidth: PropTypes.string,
  sectionPage: PropTypes.bool,
  hideFooter: PropTypes.bool,
  hideTitle: PropTypes.bool,
  hideBreadcrumbs: PropTypes.bool,
  pageContainerPadding: PropTypes.number,
};

PageTemplate.defaultProps = {
  backgroundImage: '/images/pills5-min.jpg',
  color: 'white',
  maxWidth: 'md',
  sectionPage: false,
  hideFooter: false,
  hideTitle: false,
  hideBreadcrumbs: false,
  pageContainerPadding: 4,
};

export default PageTemplate;
