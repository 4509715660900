import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { Typography, CircularProgress, makeStyles, Collapse, Button, Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
// import WarningIcon from '@material-ui/icons/Warning';
import TitledComponent from '../TitledComponent';

function LabelInformation() {
  const { drug } = useParams();
  const [label, setLabel] = useState(null);
  const [ready, setReady] = useState(false);
  const [labelExpanded, setLabelExpanded] = useState(false);

  const classes = makeStyles((theme) => ({
    title: {
      fontSize: 'larger',
      fontWeight: 'bold',
    },
    subtitle: {
      fontWeight: 'bold',
      marginLeft: theme.spacing(2),
    },
    paragraph: {
      marginLeft: theme.spacing(4),
    },
    buttonBox: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },
  }))();

  useEffect(() => {
    async function fetchTheLabel() {
      setReady(false);
      const response = await API.get('dailymed', `/label/${drug}`);
      setLabel(JSON.parse(response.data.label));
      setReady(true);
    }

    if (drug) {
      fetchTheLabel();
    }
  }, [drug]);

  if (!ready) {
    return <CircularProgress />;
  }

  if (label) {
    return (
      <TitledComponent title="Drug label information">
        {/* <Typography variant="body1" gutterBottom><WarningIcon /> To view the complete label <Link target="_new" href={`https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=${label.setId}&audience=consumer`} variant="body1">click here</Link></Typography> */}
        <Collapse in={labelExpanded} collapsedHeight={190}>
          {label.sections.map((section) => (
            <div key={section.title}>
              <Typography variant="body1" key={section.title} className={classes.title} gutterBottom>
                {section.title}
              </Typography>
              {section.sections.map((subsection, idx) => {
                if (section.title === '2 DOSAGE AND ADMINISTRATION' && idx > 0) {
                  return null;
                }
                return (
                  <div key={subsection.title}>
                    <Typography variant="body1" className={classes.subtitle} gutterBottom>
                      {subsection.title}
                    </Typography>
                    {subsection.paragraphs.map((paragraph) => (
                      <Typography key={paragraph} variant="body1" className={classes.paragraph} gutterBottom>
                        {paragraph}
                      </Typography>
                    ))}
                  </div>
                );
              })}
            </div>
          ))}
        </Collapse>
        <Box className={classes.buttonBox}>
          <Button
            variant="contained"
            color="primary"
            startIcon={labelExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            endIcon={labelExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={() => {
              setLabelExpanded(!labelExpanded);
            }}>
            {labelExpanded ? 'Collapse' : 'Expand to read more'}
          </Button>
        </Box>
      </TitledComponent>
    );
  }

  return null;
}

export default LabelInformation;
