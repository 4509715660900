import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import ReportDataComponent from './ReportDataComponent';

const metricId = 'social_adverse_reactions_reported';

const RenderComponent = ({ data }) => (<Typography variant="h6" align="right">{(data && data.length > 0 && data[0].adverse_reactions_reported) || 'N/A'}</Typography>);

function SocialMediaAdverseReactionsReported() {
  return (
    <ReportDataComponent title="Adverse Reactions Reported" metricId={metricId} alignTitle="right">
      <RenderComponent />
    </ReportDataComponent>
  );
}

RenderComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ adverse_reactions_reported: PropTypes.number })),
};

RenderComponent.defaultProps = {
  data: [],
};

export default SocialMediaAdverseReactionsReported;
