import { Grid } from '@material-ui/core';
import { purple, teal, red } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PanToolIcon from '@material-ui/icons/PanTool';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import React from 'react';
import Helmet from 'react-helmet';
import InfoBlock from '../components/InfoBlock';

export default function Products({ hideHelmet }) {
  return (
    <>
      {!hideHelmet && (
        <Helmet>
          <meta name="description" content="Careplots products." />
          <meta name="twitter:description" content="Careplots products." />
          <meta property="og:description" content="Careplots products." />
        </Helmet>
      )}
      <div>
        <Grid container direction="row" justify="center" alignItems="stretch" spacing={4}>
          <Grid item xs={12} md={4} style={{ display: 'flex' }}>
            <InfoBlock
              title="Claims Plots"
              text="Claims Plots is a cloud based software-as-a-service platform for operating the next generation of All Payer Claims Database Systems."
              icon={TrendingUpIcon}
              iconColor={red.A100}
              to="/products/apcd-plots"
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ display: 'flex' }}>
            <InfoBlock
              title="Safety Plots"
              text="Safety Plots is a cloud based, software-as-a-service data analytics platform for drug safety signal detection providing powerful insights into emerging risks and patient safety issues, as well as indirect costs associated with treating adverse events."
              icon={PanToolIcon}
              iconColor={teal.A100}
              to="/products/safety-plots"
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ display: 'flex' }}>
            <InfoBlock
              title="Outcome Plots"
              text="Outcome Plots is software-as-a-service analytic platform that enables health care organizations, such as insurers, pharmaceutical manufacturers and employers to evaluate drug effectiveness and track performance."
              icon={AssessmentIcon}
              iconColor={purple.A100}
              to="/products/outcome-plots"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

Products.propTypes = {
  hideHelmet: PropTypes.bool,
};

Products.defaultProps = {
  hideHelmet: false,
};
