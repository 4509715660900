import {
  AppBar,
  Badge,
  Button,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Box,
  ListSubheader,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HomeIcon from '@material-ui/icons/Home';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import FavoriteIcon from '@material-ui/icons/Favorite';
import HealingIcon from '@material-ui/icons/Healing';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PanToolIcon from '@material-ui/icons/PanTool';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import PublicIcon from '@material-ui/icons/Public';
import ComputerIcon from '@material-ui/icons/Computer';
import HistoryIcon from '@material-ui/icons/History';
import NavigationIcon from '@material-ui/icons/Navigation';
import PeopleIcon from '@material-ui/icons/People';
import TimelineIcon from '@material-ui/icons/Timeline';
import TransformIcon from '@material-ui/icons/Transform';
import { Auth } from 'aws-amplify';
import React, { useContext, useState } from 'react';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import CartContext from './contexts/CartContext';
import UserContext from './contexts/UserContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: 'transparent',
    boxShadow: 'none',
    // background: 'linear-gradient(90deg, rgba(13,8,32,1) 0%, rgba(117,117,117,0.8) 100%)',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 400,
  },
}));

const NavigationBar = ({ history }) => {
  const { profile, setProfile } = useContext(UserContext);
  const { drugs } = useContext(CartContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentMenu, setCurrentMenu] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);

  const links = {
    solutions: [
      { title: 'Value-Based Payment', link: '/solutions/value-based-care', icon: LocalOfferIcon },
      { title: 'Population Health Management', link: '/solutions/population-health', icon: HealingIcon },
      { title: 'Health Care Cost Transparency', link: '/solutions/health-care-cost-transparency', icon: AttachMoneyIcon },
      // { title: 'Clinical Wasteful Spending', link: '/solutions/clinical-wasteful-spending', icon: ReportProblemIcon },
      // { title: 'Social Determinants of Health', link: '/solutions/social-determinants-of-health', icon: ImportContactsIcon },
      { title: 'Social Pharmacovigilance', link: '/solutions/multi-source-pharmacovigilance', icon: NotificationsActiveIcon },
      // { title: 'Medical Device Vigilance', link: '/solutions/medical-device-vigilance', icon: DevicesOtherIcon },
      // { title: 'Real World Evidence', link: '/solutions/real-world-evidence', icon: PublicIcon },
      // {
      //   title: 'Healthcare Digital Transformation',
      //   link: '/solutions/healthcare-digital-transformation-solutions',
      //   icon: TransformIcon,
      // },
    ],
    products: [
      { link: '/products/apcd-plots', title: 'Claims Plots', icon: TrendingUpIcon },
      { link: '/products/safety-plots', title: 'Safety Plots', icon: PanToolIcon },
      { link: '/products/outcome-plots', title: 'Outcome Plots', icon: AssessmentIcon },
      // { link: '/products/benefit-plots', title: 'Benefit Plots', icon: TrendingUpIcon },
      // { link: '/products/mycare-plots', title: 'myCare Plots', icon: FavoriteIcon },
      // { link: '/products/data-plots', title: 'Data Plots', icon: DataUsageIcon },
    ],
    services: [
      { link: '/services/data-management', title: 'Data Management', icon: DonutLargeIcon },
      { link: '/services/data-infrastructure', title: 'Data Infrastructure', icon: DonutLargeIcon },

      { link: '/services/health-care-analytics-services', title: 'Healthcare Analytics', icon: DonutLargeIcon },
      // { link: '/services/value-based-healthcare-delivery-models', title: 'Value-Based Care Delivery', icon: MarkunreadMailboxIcon },
      // { link: '/services/healthcare-price-transparency', title: 'Healthcare Price Transparency', icon: VisibilityIcon },
      {
        title: 'Healthcare Digital Transformation',
        link: '/services/healthcare-digital-transformation-services',
        icon: TransformIcon,
      },
    ],
    aboutus: [
      { link: '/about-us/news', title: 'News', icon: NavigationIcon },
      { link: '/about-us/mission', title: 'Mission', icon: NavigationIcon },
      { link: '/about-us/history', title: 'History', icon: HistoryIcon },
      { link: '/about-us/story', title: 'Story', icon: TimelineIcon },
      { link: '/about-us/technology', title: 'Technology', icon: ComputerIcon },
      { link: '/about-us/executive-team', title: 'Team', icon: PeopleIcon },
    ],
  };

  const handleMenuOpen = (menu) => (event) => {
    // console.log(event.currentTarget);
    setCurrentMenu(menu);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentMenu(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setShowDrawer(open);
  };

  const handleLogout = async () => {
    await Auth.signOut();
    setProfile(null);
    handleClose();
    toggleDrawer(false);
    history.push('/');
  };

  const handleNavRequest = (to) => {
    history.push(to);
  };

  const classes = useStyles();

  const DrawerList = ({ title, list }) => (
    <List subheader={<ListSubheader>{title}</ListSubheader>}>
      {list.map((link) => {
        const DisplayIcon = link.icon;
        return (
          <ListItem component={RouterLink} onClick={toggleDrawer(false)} to={link.link} key={link.link}>
            {link.icon && (
              <ListItemIcon>
                <DisplayIcon />
              </ListItemIcon>
            )}
            <ListItemText primary={link.title} primaryTypographyProps={{ style: { color: 'white' } }} />
          </ListItem>
        );
      })}
    </List>
  );

  const list = () => (
    <div className={classes.list} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        <ListItem component={RouterLink} onClick={toggleDrawer(false)} to="/">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" primaryTypographyProps={{ style: { color: 'white' } }} />
        </ListItem>
        <ListItem component={RouterLink} onClick={toggleDrawer(false)} to="/cart">
          <ListItemIcon>
            <Badge badgeContent={drugs.length} color="secondary">
              <ShoppingCartIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary="Shopping Cart" primaryTypographyProps={{ style: { color: 'white' } }} />
        </ListItem>
        {!profile && (
          <ListItem component={RouterLink} onClick={toggleDrawer(false)} to="/login">
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Log in" primaryTypographyProps={{ style: { color: 'white' } }} />
          </ListItem>
        )}
        {profile && (
          <>
            <ListItem component={RouterLink} onClick={toggleDrawer(false)} to="/dashboard">
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" primaryTypographyProps={{ style: { color: 'white' } }} />
            </ListItem>
            <ListItem onClick={handleLogout} component={Button}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="Log out" primaryTypographyProps={{ style: { color: 'white' } }} />
            </ListItem>
          </>
        )}
      </List>
      <DrawerList title="Solutions" list={links.solutions} />
      <Divider />
      <DrawerList title="Products" list={links.products} />
      <Divider />
      <DrawerList title="Services" list={links.services} />
      <Divider />
      <DrawerList title="About Us" list={links.aboutus} />
      <Divider />
    </div>
  );

  return (
    <>
      {/* <div className={classes.root}> */}
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <Box display={{ sm: 'block', md: 'none' }}>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="left" open={showDrawer} onClose={toggleDrawer(false)}>
              {list()}
            </Drawer>
          </Box>
          <Typography variant="h6" className={classes.title}>
            Careplots
          </Typography>

          <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
            <Button component={RouterLink} color="inherit" to="/">
              Home
            </Button>

            <Button color="inherit" onClick={handleMenuOpen('solutionsMenu')} aria-haspopup="true" aria-controls="solutionsMenu">
              Solutions
            </Button>
            <Menu id="solutionsMenu" anchorEl={anchorEl} keepMounted open={currentMenu === 'solutionsMenu'} onClose={handleClose}>
              {links.solutions.map((link) => (
                <MenuItem key={link.link} component={RouterLink} onClick={handleClose} to={link.link}>
                  {link.title}
                </MenuItem>
              ))}
            </Menu>

            <Button color="inherit" onClick={handleMenuOpen('productsMenu')} aria-haspopup="true" aria-controls="productsMenu">
              Products
            </Button>
            <Menu id="productsMenu" anchorEl={anchorEl} keepMounted open={currentMenu === 'productsMenu'} onClose={handleClose}>
              {links.products.map((link) => (
                <MenuItem key={link.link} component={RouterLink} onClick={handleClose} to={link.link}>
                  {link.title}
                </MenuItem>
              ))}
            </Menu>

            <Button color="inherit" onClick={handleMenuOpen('servicesMenu')} aria-haspopup="true" aria-controls="servicesMenu">
              Services
            </Button>
            <Menu id="servicesMenu" anchorEl={anchorEl} keepMounted open={currentMenu === 'servicesMenu'} onClose={handleClose}>
              {links.services.map((link) => (
                <MenuItem key={link.link} component={RouterLink} onClick={handleClose} to={link.link}>
                  {link.title}
                </MenuItem>
              ))}
            </Menu>

            {/* <Button component={RouterLink} color="inherit" to="/about-us">About Us</Button> */}
            <Button color="inherit" onClick={handleMenuOpen('aboutUsMenu')} aria-haspopup="true" aria-controls="aboutUsMenu">
              About Us
            </Button>
            <Menu id="aboutUsMenu" anchorEl={anchorEl} keepMounted open={currentMenu === 'aboutUsMenu'} onClose={handleClose}>
              {links.aboutus.map((link) => (
                <MenuItem key={link.link} component={RouterLink} onClick={handleClose} to={link.link}>
                  {link.title}
                </MenuItem>
              ))}
            </Menu>

            <Button component={RouterLink} variant="outlined" to="/about-us/news">
              News
            </Button>

            {/* <Button component={RouterLink} color="inherit" to="/cart">
                <Badge badgeContent={drugs.length} color="secondary">
                  <ShoppingCartIcon />
                </Badge>
              </Button> */}
            {!profile && (
              <Button
                color="inherit"
                onClick={() => {
                  handleNavRequest('/login');
                }}>
                Login
              </Button>
            )}
            {profile && (
              <Button onClick={handleMenuOpen('userMenu')} aria-haspopup="true" aria-controls="userMenu" color="inherit">
                {profile['custom:firstName']}
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {/* </div> */}
      <Menu id="userMenu" anchorEl={anchorEl} keepMounted open={currentMenu === 'userMenu'} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleNavRequest('/demo');
          }}>
          Product Demo
        </MenuItem>
        <MenuItem onClick={handleLogout}>Log out</MenuItem>
      </Menu>
    </>
  );
};

NavigationBar.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
};

export default withRouter(NavigationBar);
