import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Container } from '@material-ui/core';

function HomeSection({ children, backgroundImage }) {
  const classes = makeStyles((theme) => ({
    container: {
      backgroundImage: `linear-gradient(to bottom, rgba(13,8,32,1), rgba(117,117,117,0.2) ), url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      minHeight: 500,
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  }))();
  return (
    <div className={classes.container}>
      <Container>{children}</Container>
    </div>
  );
}

HomeSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  backgroundImage: PropTypes.string.isRequired,
};

export default HomeSection;
