import { blue, green, indigo, lime, orange, pink, purple, red, teal } from '@material-ui/core/colors';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PageTemplate from './components/PageTemplate';
import AboutUsExecutiveTeam from './containers/AboutUsExecutiveTeam';
import AboutUsHistory from './containers/AboutUsHistory';
import AboutUsMission from './containers/AboutUsMission';
import AboutUsStory from './containers/AboutUsStory';
import AboutUs from './containers/AboutUs';
import AboutUsTechnology from './containers/AboutUsTechnology';
import BenefitPlots from './containers/BenefitPlots';
import Cart from './containers/Cart';
import ClinicalWastefulSpending from './containers/ClinicalWastefulSpending';
import DataPlots from './containers/DataPlots';
import DrugReport from './containers/DrugReport';
import ForgotPassword from './containers/ForgotPassword';
import ForgotPasswordReset from './containers/ForgotPasswordReset';
import HealthcareAnalyticsServices from './containers/HealthcareAnalyticsServices';
import HealthCarePriceTransparency from './containers/HealthCarePriceTransparency';
import Home from './containers/Home';
import LoginContainer from './containers/LoginContainer';
import MyCarePlots from './containers/myCarePlots';
import NotFound from './containers/NotFound';
import OutcomePlots from './containers/OutcomePlots';
import PopHealth from './containers/PopHealth';
import SafetyPlots from './containers/SafetyPlots';
import Search from './containers/Search';
import SignUp from './containers/SignUp';
import SocialDeterminantsOfHealth from './containers/SocialDeterminantsOfHealth';
import SolutionsEffectivenessAndEfficacyEvaluationReports from './containers/SolutionsEffectivenessAndEfficacyEvaluationReports';
import SolutionsMedicalDeviceVigilance from './containers/SolutionsMedicalDeviceVigilance';
import SolutionsMultiSourcePharmacovigilance from './containers/SolutionsMultiSourcePharmacovigilance';
import SolutionsValueBasedCare from './containers/SolutionsValueBasedCare';
import SolutionsRealWorldEvidence from './containers/SolutionsRealWorldEvidence';
import SolutionsHealthCareDigitalTransformations from './containers/SolutionsHealthCareDigitalTransformations';
import ServicesHealthCareDigitalTransformations from './containers/ServicesHealthCareDigitalTransformations';
import Solutions from './containers/Solutions';
import Products from './containers/Products';
import Services from './containers/Services';
import UserDashboard from './containers/UserDashboard';
import ValueBasedHealthCareDeliveryModels from './containers/ValueBasedHealthCareDeliveryModels';
import Verify from './containers/Verify';
import DeviceDetails from './containers/DeviceDetails';
import DrugDetails from './containers/DrugDetails';
import RequestReportDevices from './components/RequestReportDevices';
import RequestReport from './components/RequestReport';
import TermsOfService from './containers/TermsOfService';
import PrivacyPolicy from './containers/PrivacyPolicy';
import DataManagement from './containers/DataManagement';
import DataInfrastructure from './containers/DataInfrastructure';
import APCDPlots from './containers/APCDPlots';
import News from './containers/News';
import NewsArticle from './containers/NewsArticle';
import DemoHome from './containers/DemoHome';
import Spotfire from './containers/Spotfire';
import SpotfirePublic from './containers/SpotfirePublic';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/terms-of-service">
        <PageTemplate section="User" title="Terms of Service" backgroundImage="/images/backgrounds/tos.jpg">
          <TermsOfService />
        </PageTemplate>
      </Route>
      <Route path="/privacy-policy">
        <PageTemplate section="User" title="Privacy Policy" backgroundImage="/images/backgrounds/privacy.jpg">
          <PrivacyPolicy />
        </PageTemplate>
      </Route>
      <Route path="/login">
        <PageTemplate section="User" title="Log in">
          <LoginContainer />
        </PageTemplate>
      </Route>
      <Route path="/forgot-password">
        <PageTemplate section="User" title="Forgot Password">
          <ForgotPassword />
        </PageTemplate>
      </Route>
      <Route path="/forgot-password-reset">
        <PageTemplate section="User" title="Reset Password">
          <ForgotPasswordReset />
        </PageTemplate>
      </Route>
      <Route path="/signup">
        <PageTemplate section="User" title="Sign up">
          <SignUp />
        </PageTemplate>
      </Route>
      <Route path="/verify">
        <PageTemplate section="User" title="Verify email">
          <Verify />
        </PageTemplate>
      </Route>
      <Route path="/cart">
        <PageTemplate section="User" title="Cart">
          <Cart />
        </PageTemplate>
      </Route>
      <Route path="/dashboard/report/:subscriptionId">
        <PageTemplate section="User" title="Report">
          <DrugReport />
        </PageTemplate>
      </Route>
      <Route path="/dashboard">
        <PageTemplate section="User" title="Dashboard">
          <UserDashboard />
        </PageTemplate>
      </Route>
      <Route path="/search">
        <PageTemplate section="Home" title="Search">
          <Search />
        </PageTemplate>
      </Route>
      {/* <Route path="/products/data-platform"><PageTemplate><ProductsDataPlatform /></PageTemplate></Route> */}
      {/* <Route path="/products/analytic-products"><PageTemplate><ProductsAnalyticProducts /></PageTemplate></Route> */}
      <Route path="/about-us/executive-team">
        <PageTemplate
          maxWidth="lg"
          backgroundImage="/images/backgrounds/executive-team.jpg"
          section="About Us"
          title="Executive Team"
          color={orange.A200}>
          <AboutUsExecutiveTeam />
        </PageTemplate>
      </Route>
      <Route path="/about-us/mission">
        <PageTemplate backgroundImage="/images/backgrounds/mission.jpg" section="About Us" title="Mission" color={teal.A200}>
          <AboutUsMission />
        </PageTemplate>
      </Route>
      <Route path="/about-us/history">
        <PageTemplate backgroundImage="/images/backgrounds/history.jpg" section="About Us" title="History" color={purple.A200}>
          <AboutUsHistory />
        </PageTemplate>
      </Route>
      <Route path="/about-us/story">
        <PageTemplate backgroundImage="/images/backgrounds/story.jpg" section="About Us" title="Story" color={orange.A200}>
          <AboutUsStory />
        </PageTemplate>
      </Route>
      <Route path="/about-us/technology">
        <PageTemplate backgroundImage="/images/backgrounds/technology.jpg" section="About Us" title="Technology" color={blue.A200}>
          <AboutUsTechnology />
        </PageTemplate>
      </Route>
      <Route path="/about-us/news/:slug">
        <PageTemplate maxWidth="lg" section="About Us" title="News" backgroundImage="/images/backgrounds/news.jpg">
          <NewsArticle />
        </PageTemplate>
      </Route>
      <Route path="/about-us/news">
        <PageTemplate
          maxWidth="lg"
          color={indigo.A100}
          section="About Us"
          title="News"
          backgroundImage="/images/backgrounds/news.jpg">
          <News />
        </PageTemplate>
      </Route>
      <Route path="/about-us">
        <PageTemplate section="About Us" title="About Us" sectionPage maxWidth="lg">
          <AboutUs />
        </PageTemplate>
      </Route>
      {/* <Route path="/products/data-platform"><PageTemplate><ProductsDataPlatform /></PageTemplate></Route> */}
      {/* <Route path="/products/analytic-products"><PageTemplate><ProductsAnalyticProducts /></PageTemplate></Route> */}
      <Route path="/products/safety-plots">
        <PageTemplate
          backgroundImage="/images/backgrounds/safety-plots.jpg"
          section="Products"
          title="Safety Plots"
          color={teal.A100}>
          <SafetyPlots />
        </PageTemplate>
      </Route>
      <Route path="/products/outcome-plots">
        {/* <PageTemplate
          backgroundImage="/images/backgrounds/outcome-plots.jpg"
          section="Products"
          title="Outcome Plots"
          color={purple.A100}> */}
        <OutcomePlots
          backgroundImage="/images/backgrounds/outcome-plots.jpg"
          section="Products"
          title="Outcome Plots"
          color={purple.A100}
        />
        {/* </PageTemplate> */}
      </Route>
      <Route path="/products/apcd-plots">
        <PageTemplate backgroundImage="/images/backgrounds/apcd-plots.jpg" section="Products" title="Claims Plots" color={red.A100}>
          <APCDPlots />
        </PageTemplate>
      </Route>
      <Route path="/products/benefit-plots">
        <PageTemplate
          backgroundImage="/images/backgrounds/benefit-plots.jpg"
          section="Products"
          title="Benefit Plots"
          color={orange.A100}>
          <BenefitPlots />
        </PageTemplate>
      </Route>
      <Route path="/products/mycare-plots">
        <PageTemplate
          backgroundImage="/images//backgrounds/mycare-plots.jpg"
          section="Products"
          title="myCare Plots"
          color={pink.A400}>
          <MyCarePlots />
        </PageTemplate>
      </Route>
      <Route path="/products/data-plots">
        <PageTemplate backgroundImage="/images/backgrounds/data-plots.jpg" section="Products" title="Data Plots" color={lime.A400}>
          <DataPlots />
        </PageTemplate>
      </Route>
      <Route path="/products">
        <PageTemplate
          backgroundImage="/images/backgrounds/products.jpg"
          title="Products"
          section="Products"
          sectionPage
          maxWidth="lg">
          <Products />
        </PageTemplate>
      </Route>
      <Route path="/solutions/value-based-care">
        <PageTemplate
          backgroundImage="/images/backgrounds/value-based-care.jpg"
          section="Solutions"
          title="Value-Based Payment"
          color={green.A200}>
          <SolutionsValueBasedCare />
        </PageTemplate>
      </Route>
      <Route path="/solutions/multi-source-pharmacovigilance">
        <PageTemplate
          backgroundImage="/images/backgrounds/social-pharmacovigilance.jpg"
          section="Solutions"
          title="Social Pharmacovigilance"
          color={red.A200}>
          <SolutionsMultiSourcePharmacovigilance />
        </PageTemplate>
      </Route>
      <Route path="/solutions/medical-device-vigilance">
        <PageTemplate
          backgroundImage="/images/backgrounds/medical-device.jpg"
          section="Solutions"
          title="Medical Device Vigilance"
          color={orange.A200}>
          <SolutionsMedicalDeviceVigilance />
        </PageTemplate>
      </Route>
      <Route path="/solutions/health-care-cost-transparency">
        <PageTemplate
          backgroundImage="/images/backgrounds/healthcare-cost.jpg"
          section="Solutions"
          title="Health Care Costs Transparency"
          color={blue.A200}>
          <SolutionsEffectivenessAndEfficacyEvaluationReports />
        </PageTemplate>
      </Route>
      <Route path="/solutions/social-determinants-of-health">
        <PageTemplate
          backgroundImage="/images/backgrounds/social-determinants.jpg"
          section="Solutions"
          title="Social Determinants of Health"
          color={lime.A200}>
          <SocialDeterminantsOfHealth />
        </PageTemplate>
      </Route>
      <Route path="/solutions/real-world-evidence">
        <PageTemplate
          backgroundImage="/images/backgrounds/real-world-evidence.jpg"
          section="Solutions"
          title="Real World Evidence"
          color={pink.A200}>
          <SolutionsRealWorldEvidence />
        </PageTemplate>
      </Route>
      <Route path="/solutions/healthcare-digital-transformation-solutions">
        <PageTemplate
          backgroundImage="/images/backgrounds/social-determinants.jpg"
          section="Solutions"
          title="Helthcare Digital Transformation Solutions"
          color={purple.A200}>
          <SolutionsHealthCareDigitalTransformations />
        </PageTemplate>
      </Route>
      <Route path="/solutions/population-health">
        <PageTemplate
          backgroundImage="/images/backgrounds/population-health.jpg"
          section="Solutions"
          title="Population Health Management"
          color={blue.A100}>
          <PopHealth />
        </PageTemplate>
      </Route>
      <Route path="/solutions/clinical-wasteful-spending">
        <PageTemplate
          backgroundImage="/images/backgrounds/clinical-wasteful.jpg"
          section="Services"
          title="Clinical Wasteful Spending"
          color={indigo.A100}>
          <ClinicalWastefulSpending />
        </PageTemplate>
      </Route>
      <Route path="/solutions">
        <PageTemplate
          backgroundImage="/images/backgrounds/solutions.jpg"
          section="Solutions"
          title="Solutions"
          sectionPage
          maxWidth="lg">
          <Solutions />
        </PageTemplate>
      </Route>
      <Route path="/services/health-care-analytics-services">
        <PageTemplate
          backgroundImage="/images/backgrounds/healthcare-analytics-services.jpg"
          section="Services"
          title="Healthcare Analytics"
          color={green.A100}>
          <HealthcareAnalyticsServices />
        </PageTemplate>
      </Route>
      <Route path="/services/value-based-healthcare-delivery-models">
        <PageTemplate
          backgroundImage="/images/backgrounds/value-based-healthcare-delivery.jpg"
          section="Services"
          title="Value-Based Care Delivery Models"
          color={green.A100}>
          <ValueBasedHealthCareDeliveryModels color={green.A100} />
        </PageTemplate>
      </Route>
      <Route path="/services/data-management">
        <PageTemplate
          backgroundImage="/images/backgrounds/data-management2.jpg"
          section="Services"
          title="Data Management"
          color={blue.A100}>
          <DataManagement />
        </PageTemplate>
      </Route>
      <Route path="/services/data-infrastructure">
        <PageTemplate
          backgroundImage="/images/backgrounds/data-infrastructure.jpg"
          section="Services"
          title="Data Infrastructure"
          color={lime.A100}>
          <DataInfrastructure />
        </PageTemplate>
      </Route>
      <Route path="/services/clinical-wasteful-spending">
        <PageTemplate
          backgroundImage="/images/backgrounds/clinical-wasteful.jpg"
          section="Services"
          title="Clinical Wasteful Spending"
          color={indigo.A100}>
          <ClinicalWastefulSpending />
        </PageTemplate>
      </Route>
      <Route path="/services/healthcare-digital-transformation-services">
        <PageTemplate
          backgroundImage="/images/backgrounds/clinical-wasteful.jpg"
          section="Services"
          title="Healthcare Digital Transformation"
          color={indigo.A100}>
          <ServicesHealthCareDigitalTransformations />
        </PageTemplate>
      </Route>
      <Route path="/services">
        <PageTemplate
          backgroundImage="/images/backgrounds/services.jpg"
          title="Services"
          section="Services"
          sectionPage
          maxWidth="lg">
          <Services />
        </PageTemplate>
      </Route>
      <Route path="/devices">
        <PageTemplate section="Devices" title="Devices" sectionPage>
          <RequestReportDevices />
        </PageTemplate>
      </Route>
      <Route path="/device-details/:device">
        <PageTemplate backgroundImage="/images/backgrounds/devices.jpg" section="Devices" title="Device Details">
          <DeviceDetails />
        </PageTemplate>
      </Route>
      <Route path="/drugs">
        <PageTemplate sectionPage section="Drugs" title="Drugs">
          <RequestReport />
        </PageTemplate>
      </Route>
      <Route path="/drug-details/:drug">
        <PageTemplate maxWidth="lg" section="Drugs" title="Drug Details">
          <DrugDetails />
        </PageTemplate>
      </Route>
      <Route path="/public-demo/:template">
        <PageTemplate
          maxWidth="xl"
          section="Demo"
          title="Report Demo"
          hideFooter
          hideTitle
          hideBreadcrumbs
          pageContainerPadding={0}>
          <SpotfirePublic />
        </PageTemplate>
      </Route>
      <Route path="/demo/:template">
        <PageTemplate
          maxWidth="xl"
          section="Demo"
          title="Report Demo"
          hideFooter
          hideTitle
          hideBreadcrumbs
          pageContainerPadding={0}>
          <Spotfire />
        </PageTemplate>
      </Route>
      <Route path="/demo">
        <PageTemplate maxWidth="xl" section="Demo" title="Product Demo">
          <DemoHome />
        </PageTemplate>
      </Route>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <PageTemplate section="" title="Page Not Found">
          <NotFound />
        </PageTemplate>
      </Route>
    </Switch>
  );
}
