import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  TextField, CircularProgress, Typography, makeStyles,
} from '@material-ui/core';
import DrugsList from './DrugsList';
import ReactGA from '../modules/google-analytics';

function DrugSearchName() {
  const [drugName, setDrugName] = useState('');
  const [searchDrugName] = useDebounce(drugName, 800);
  const [drugs, setDrugs] = useState([]);
  const [error, setError] = useState(null);
  const [ready, setReady] = useState(true);
  const [message, setMessage] = useState('');

  const classes = makeStyles((theme) => ({
    resultsArea: {
      marginTop: theme.spacing(2),
    },
  }))();

  const handleDrugNameChange = (e) => {
    setDrugName(e.target.value);
  };

  useEffect(() => {
    setError(null);
    setMessage('');
    async function getDrugs() {
      setReady(false);
      try {
        const response = await API.get('rawData', '/drugs',
          {
            queryStringParameters:
            {
              drug: searchDrugName.trim(),
            },
          });
        setDrugs(response);
        if (response.length === 0) {
          setMessage('No results. Please try a different search.');
        }
      } catch (e) {
        setError(e);
      } finally {
        setReady(true);
      }
    }

    if (searchDrugName && searchDrugName.trim().length > 2) {
      ReactGA.event({
        category: 'Drug search',
        action: 'Searched drug name',
        label: searchDrugName,
      });
      getDrugs();
    }
  }, [searchDrugName]);

  return (
    <>
      <TextField
        label="Search by drug name"
        value={drugName}
        onChange={handleDrugNameChange}
        fullWidth
      />
      <div className={classes.resultsArea}>
        {error && <Typography variant="body1" color="error">{error.message}</Typography>}
        {message && <Typography variant="body1" color="textPrimary">{message}</Typography>}
        {ready && <DrugsList drugs={drugs} />}
        {!ready && <CircularProgress />}
      </div>
    </>
  );
}

export default DrugSearchName;
