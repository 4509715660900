/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteCart = /* GraphQL */ `
  mutation DeleteCart(
    $input: DeleteCartInput!
    $condition: ModelCartConditionInput
  ) {
    deleteCart(input: $input, condition: $condition) {
      id
      drugs
      userId
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      username
      stripeCustomerId
      subscribedDrugs
      subscriptions {
        object
        has_more
        data {
          id
          object
          application_fee_percent
          billing_cycle_anchor
          billing_thresholds
          cancel_at
          cancel_at_period_end
          canceled_at
          collection_method
          created
          current_period_end
          current_period_start
          customer
          days_until_due
          default_payment_method
          default_source
          default_tax_rates
          discount
          ended_at
          latest_invoice
          livemode
          next_pending_invoice_item_invoice
          pending_invoice_item_interval
          pending_setup_intent
          pending_update
          quantity
          schedule
          start_date
          status
          tax_percent
          trial_end
          trial_start
        }
      }
      stripeProfile {
        id
        default_source
        sources {
          object
        }
        subscriptions {
          object
          has_more
        }
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      username
      stripeCustomerId
      subscribedDrugs
      subscriptions {
        object
        has_more
        data {
          id
          object
          application_fee_percent
          billing_cycle_anchor
          billing_thresholds
          cancel_at
          cancel_at_period_end
          canceled_at
          collection_method
          created
          current_period_end
          current_period_start
          customer
          days_until_due
          default_payment_method
          default_source
          default_tax_rates
          discount
          ended_at
          latest_invoice
          livemode
          next_pending_invoice_item_invoice
          pending_invoice_item_interval
          pending_setup_intent
          pending_update
          quantity
          schedule
          start_date
          status
          tax_percent
          trial_end
          trial_start
        }
      }
      stripeProfile {
        id
        default_source
        sources {
          object
        }
        subscriptions {
          object
          has_more
        }
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      username
      stripeCustomerId
      subscribedDrugs
      subscriptions {
        object
        has_more
        data {
          id
          object
          application_fee_percent
          billing_cycle_anchor
          billing_thresholds
          cancel_at
          cancel_at_period_end
          canceled_at
          collection_method
          created
          current_period_end
          current_period_start
          customer
          days_until_due
          default_payment_method
          default_source
          default_tax_rates
          discount
          ended_at
          latest_invoice
          livemode
          next_pending_invoice_item_invoice
          pending_invoice_item_interval
          pending_setup_intent
          pending_update
          quantity
          schedule
          start_date
          status
          tax_percent
          trial_end
          trial_start
        }
      }
      stripeProfile {
        id
        default_source
        sources {
          object
        }
        subscriptions {
          object
          has_more
        }
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCart = /* GraphQL */ `
  mutation CreateCart(
    $input: CreateCartInput!
    $condition: ModelCartConditionInput
  ) {
    createCart(input: $input, condition: $condition) {
      id
      drugs
      userId
      createdAt
      updatedAt
    }
  }
`;
export const updateCart = /* GraphQL */ `
  mutation UpdateCart(
    $input: UpdateCartInput!
    $condition: ModelCartConditionInput
  ) {
    updateCart(input: $input, condition: $condition) {
      id
      drugs
      userId
      createdAt
      updatedAt
    }
  }
`;
