import { makeStyles, Box } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import React from 'react';

function TextStatement(props) {
  const { children, height, color } = props;

  const classes = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(4),
      marginTop: theme.spacing(2),
      backgroundColor: color,
      minHeight: height,
    },
  }))();

  return <Box className={classes.root}>{children}</Box>;
}

TextStatement.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  height: PropTypes.number,
  color: PropTypes.string,
};

TextStatement.defaultProps = {
  color: orange.A700,
  height: 100,
};

export default TextStatement;
