import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles, Link, Grid } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

function DrugsList({ drugs }) {
  const classes = makeStyles((theme) => ({
    list: {
      // height: 200,
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignContent: 'stretch',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      // overflowY: 'auto',
    },
    listItem: {
      display: 'block',
    },
    drugName: {
      textTransform: 'capitalize',
    },
  }))();

  if (drugs.length === 0) {
    return null;
  }

  const items = [...drugs];
  const half = Math.ceil(items.length / 2);
  const firstHalf = items.splice(0, half);
  const secondHalf = items.splice(-half);

  return (
    <Grid container>
      {/* <Typography variant="h6" gutterBottom color="secondary">Please select a drug</Typography> */}
      <Grid item xs={12} sm={6}>
        {firstHalf.map((p) => (
          <Link
            component={RouterLink}
            className={classes.listItem}
            key={p.drug_name}
            to={`/drug-details/${encodeURIComponent(p.drug_name.toLowerCase())}`}>
            <Typography variant="body1" component="span" className={classes.drugName}>
              {p.drug_name.toLowerCase()}
            </Typography>
          </Link>
        ))}
      </Grid>
      <Grid item xs={12} sm={6}>
        {secondHalf.map((p) => (
          <Link
            component={RouterLink}
            className={classes.listItem}
            key={p.drug_name}
            to={`/drug-details/${encodeURIComponent(p.drug_name.toLowerCase())}`}>
            <Typography variant="body1" component="span" className={classes.drugName}>
              {p.drug_name.toLowerCase()}
            </Typography>
          </Link>
        ))}
      </Grid>
    </Grid>
  );
}

DrugsList.propTypes = {
  drugs: PropTypes.arrayOf(PropTypes.shape({ drug_name: PropTypes.string })).isRequired,
};

export default DrugsList;
