import { Typography } from '@material-ui/core';
import React from 'react';

function ServicesHealthCareDigitalTransformations() {
  return (
    <>
      <Typography variant="body1" gutterBottom>
        The health care industry is undergoing rapid changes driven by tech trends. Value based care technologies can link costs to
        outcomes dramatically changing the reimbursement system. By incorporating artificial intelligence technologies, medical
        practitioners can get help with making diagnosis and surgery assistance and with telehealth technology, patients can receive
        quality remote care through virtual medical providers. The Internet of Medical Things (IoMT) encourages the usages of
        wearables, advancing the patient experience and new ways of providing care, while new data security technology allow safe
        storage, exchange, and data processing within all compliance regulations. Careplots &apos;digital transformation
        framework&apos; is a developed strategy to improve the health care businesses digitally. Using this framework, experienced
        consultants from Careplots will assess your current architecture maturity and digital readiness against best practices and
        industry benchmarks, focusing on:
      </Typography>
      <ol>
        <li>
          <Typography variant="body1" gutterBottom>
            Business Architecture: We’ll bring our deep domain expertise to highlight key priority areas for your business and
            recommendations for target state business capabilities map. Also, within this engagement, we’ll highlight opportunities
            to streamline and modernize your technology investments.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            Information Architecture Management: Experienced architects from Careplots will assess your existing data architecture
            and management capabilities via focused interviews and working sessions. Our experts will leverage our modern data
            management framework to help you iteratively improve and modernize your existing data management capabilities on your
            path to a digital transformation.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            Integration Architecture: Do you have an enterprise API management and monetization strategy? Have you deployed an
            enterprise integration backbone to facilitate data and process integration across multiple disparate data sources? Are
            you able to address temporal requirements of your consuming processes and/or business users? Our experts will be able to
            help here.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            Advanced Analytics: Our experts will assess your analytics capabilities (including tooling) via focused sessions with
            your architects, business owners, data scientists and other SMEs to identify gaps and white spaces. We will provide
            prescriptive recommendations and roadmap to shore up your analytics capability.
          </Typography>
        </li>
      </ol>

      <Typography variant="body1" gutterBottom>
        Careplots team has the expertise and experience to radically improve the performance and/or reach of enterprises through a
        holistic approach to digital transformation. Our experienced consultants are well versed in leveraging digital advances such
        as advanced analytics, mobility, social media and smart embedded devices while improving the use of legacy technologies to
        improve customer relationships, business processes and value propositions. By leveraging our experience across different
        sectors of the health care ecosystem as well as other industries, our consulting services can offer optimal solutions to
        help organizations getting the most out of their investments in digital technologies.
      </Typography>
    </>
  );
}

export default ServicesHealthCareDigitalTransformations;
