import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

function DataInfrastructure({ color }) {
  const classes = makeStyles((theme) => ({
    subtitle: {
      marginTop: theme.spacing(2),
      color,
    },
  }))();
  return (
    <>
      <Helmet>
        <meta name="description" content="Leverage our experience in building and operating secure data hosting platforms." />
        <meta
          name="twitter:description"
          content="Leverage our experience in building and operating secure data hosting platforms."
        />
        <meta
          property="og:description"
          content="Leverage our experience in building and operating secure data hosting platforms."
        />
      </Helmet>

      <Typography variant="h5" component="h2" className={classes.subtitle} gutterBottom>
        Data hosting services
      </Typography>

      <Typography variant="body1" gutterBottom>
        Leverage our experience in building and operating secure data hosting platforms within on-premise, public and hybrid clouds,
        designed to protect data, promote analysis, enable research and collaboration. We contracted with lead organizations to
        securely host sensitive microdata for several federal agencies, states and research foundations.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Careplots can host or, depending on security requirements, can work with its partners to host your data in secure computing
        data center, architected to store, aggregate and analyze health care data in clinical and academic settings and compliant
        with the Health Insurance Portability and Accountability Act (HIPAA) or Health Information Technology for Economic and
        Clinical Health (HITECH) Act or the Federal Information Security Management Act (FISMA) based on NIST 800-53A risk
        standards. We offer secure data hosting services and computational virtual workspaces that can provide agility and
        flexibility while still maintaining the rigorous standards of data protection, access and dissemination required by federal
        and state institutions.
      </Typography>

      <Typography variant="h5" component="h2" className={classes.subtitle} gutterBottom>
        Compliance with data security standards
      </Typography>

      <Typography variant="body1" gutterBottom>
        Careplots data privacy services team is uniquely qualified and experienced in data privacy law, information security and
        data technology. This allows our team to ensure that every data workflow is secure and adheres to every privacy requirement,
        without compromising analytic activities. Careplots decouples patient identity from clinical information to eliminate data
        security risks and compliance liabilities. We are well versed in maintaining Business Associate Agreements (BAA), Data
        Sharing Agreements (DSA) and Data User Agreements (DUA).
      </Typography>
      <Typography variant="body1" gutterBottom>
        We are working with our partner experts to conduct thorough risk analysis, business impact analysis for disaster recovery
        planning, contingency planning, revising security procedures, holding training sessions, and continuously evaluating
        information infrastructure. Let us free your analysts and data scientists to focus more on analysis and research, while
        satisfying regulations and compliance standards. We will design, implement and manage comprehensive solutions that protect
        information and maintain regulatory compliance across your data needs.
      </Typography>

      <Typography variant="h5" component="h2" className={classes.subtitle} gutterBottom>
        High performance virtual data workspaces
      </Typography>

      <Typography variant="body1" gutterBottom>
        Leverage our high performance computing cloud environments or virtual data workspaces to securely access and analyze your
        data. A virtual data workspace (VDW) is a secure data and computing environment, designed to facilitate virtual access to
        hosted data with safeguards to prohibit or limit data export. We can help your data storage and analysis needs by providing
        data workspaces that meet this definition as a virtual, secure, cloud-based data environment, controlling user access with
        regulatory and technical protections, and controlling the download of patient-level data from the environment.
      </Typography>
      <Typography variant="body1" gutterBottom>
        The virtual data workspace is also architected as a powerful computational platform for developing code, database extracts,
        statistical models or machine learning algorithms. Researchers are able to leverage both &quot;raw&quot; health care data,
        or carefully curated derivatives. Our secure virtual data workspaces provides your analysts with a secure, remotely
        accessible, virtual desktop environment to collaboratively analyze PII and PHI data as an alternative to traditional
        physical computing environments. Protected health information (PHI) data is safeguarded by employing multilayered security,
        including scalable, virtual PC environment that can be tailored, based on user requirements to run researchers&apos; tools.
      </Typography>
    </>
  );
}

DataInfrastructure.propTypes = {
  color: PropTypes.string,
};

DataInfrastructure.defaultProps = {
  color: '#fff',
};

export default DataInfrastructure;
