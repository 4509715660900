import { Typography } from '@material-ui/core';
import React from 'react';
import Helmet from 'react-helmet';

function MyCarePlots() {
  return (
    <>
      <Helmet>
        <meta name="description" content="myCare Plots is a consumer-centric product offering personalized care information." />
        <meta
          name="twitter:description"
          content="myCare Plots is a consumer-centric product offering personalized care information."
        />
        <meta
          property="og:description"
          content="myCare Plots is a consumer-centric product offering personalized care information."
        />
      </Helmet>
      <Typography variant="body1" gutterBottom>
        myCare Plots is another consumer-centric product offering personalized care information that consumers can use to find out
        which drugs and treatments will work best for patients with common characteristics and symtomatics. While general
        information exists in numerous websites, no central site exists to inform users which drugs or doses or combination of
        medications work best for &apos;patients like them&apos;. myCarePlots is an informational site, not a dignostic tool. The
        product uses data reported by patients in specialized social media sites, as well as safety issues reported by providers
        from many countries, claims data from insurance systems and patient self reported data in online surveys.
      </Typography>
      <Typography variant="body1" gutterBottom>
        With the explosion of the social media and medical social networks in the last decade, terabytes of textual data is waiting
        for mature technology solutions to collect, parse and classify this data, ultimately deriving unique insights that cannot be
        extracted from clinical trials, experimental studies and EMRs and can be valuable to providers and consumers, as well as
        insurers or pharma or device manufacturers. It is estimated that ~ 24% of adults that use the Internet have read online
        reviews of a particular drug or medical treatment
      </Typography>
      <Typography variant="body1" gutterBottom>
        Hundreds of web sites have proliferated in recent times containing millions of patient reviews of a drug, or a device, or a
        medical procedure, or a condition, or a provider (physician, facility) or an insurer. This data sits in hundreds of data
        store silos worldwide, reflecting real life patient stories from million of individuals, with a wealth of information
        exposed as never before.
      </Typography>
      <Typography variant="body1" gutterBottom>
        While the healthcare industry was slow to adopt social media data analysis compared to other industries, the reluctance was
        largely attributed to the reliability factor, the casual anonymity of the users and the validity of the posted information.
        However, there is increased interest from the research and business community in analyzing health-related content of social
        media. Technology has advanced to efficiently mine this data, parse out the ‘noise&apos; and analyze reliable and valid
        comments from many clusters of patients worldwide. Careplots is filling this void, pioneering the use of real patient
        reviews to build a bridge toward the goal of enabling the practice of &quot;personalized medicine&quot;.
      </Typography>
    </>
  );
}

export default MyCarePlots;
