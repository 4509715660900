import { Typography } from '@material-ui/core';
import React from 'react';
import Helmet from 'react-helmet';

function AboutUsStory() {
  return (
    <>
      <Helmet>
        <meta name="description" content="We're building the 'blue book' guide of the health care products." />
        <meta name="twitter:description" content="We're building the 'blue book' guide of the health care products." />
        <meta property="og:description" content="We're building the 'blue book' guide of the health care products." />
      </Helmet>
      <Typography variant="body1" gutterBottom>
        We&apos;re building the &apos;blue book&apos; guide of the health care products. We believe this will provide benefits to
        both consumers and health care organizations in determining the value of these products to improve the patient’s health and
        the business bottom line. The current system has made it difficult for patients, insurers and employers to pay for products
        that really work, or for products showing measurable improvements in treating a patient&apos;s health condition or an
        individual productivity. The pay for results principle is governing pretty much everything around us, except for the health
        system in which the &apos;fee for service&apos; still dominates reimbursement models with privately negotiated fees per
        procedure, visit or hospital stay. Within this model, medical products such as drugs and devices, are still paid by pill, or
        per use, regardless of the end results.
      </Typography>
      <Typography variant="body1" gutterBottom>
        The transformation from pay per pill to pay per outcome Is a difficult task as it requires complex technology solutions to
        continuously measure clinical and economic outcomes, in order to enable reimbursements subjected to meeting performance
        goals. To help bridge this gap, new data platforms and solutions are coming online. Careplots is bringing product evaluation
        back to the forefront of good medicine. We&apos;re providing a software platform that quantifies the efficacy and
        effectiveness of drugs to treat specific conditions and serves as a comprehensive valuation guide to measure outcomes for
        contracting purposes.
      </Typography>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          marginBottom: 20,
        }}>
        <img src="/images/cr_safety2.png" alt="safety metrics" />
      </div>
    </>
  );
}

export default AboutUsStory;
