import { Typography } from '@material-ui/core';
import React from 'react';
import Helmet from 'react-helmet';

function HealthCarePriceTransparency() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Recent cost control pressures have placed greater focus on patient's share of the healthcare costs."
        />
        <meta
          name="twitter:description"
          content="Recent cost control pressures have placed greater focus on patient's share of the healthcare costs."
        />
        <meta
          property="og:description"
          content="Recent cost control pressures have placed greater focus on patient's share of the healthcare costs."
        />
      </Helmet>
      <Typography variant="body1" gutterBottom>
        In an era of ever-increasing healthcare costs, traditional (B2B) business to business transactions are now becoming consumer
        facing. Recent cost control pressures along with legislators requiring disclosure of price data, employer/ insurers
        disclosure of cost estimations have placed greater focus on patient&apos;s share of the healthcare costs. Under new rules
        enacted by the administration and legislated in the US Congress, health insurers, providers, and self-insured group health
        plans would be required to either furnish the patient with out-of-pocket cost information or make the costs associated with
        their procedure available. The patient must be able to know the estimated cost before receiving treatment.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Health care organizations positioned to compete on value will fare better than those who are not. This is the overriding
        objective of pricing (and value) transparency. Our leading experts each with more than twenty plus years of healthcare
        strategy, policy, and claims data experience can work with your team to evaluate your organization&apos;s transparency
        readiness:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">Comply with regulatory reporting requirements due Jan. 1, 2021</Typography>
        </li>
        <li>
          <Typography variant="body1">
            Implement an enterprise advanced analytics strategy enabling more effective measurement and timely resolution of
            clinical/financial performance variation
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Accelerate the transition toward a digital financial patient relationship with special emphasis on patient estimation,
            scheduling and the overall patient experience
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Embrace value-driven, prospective or capitated managed care incentives; de-emphasize charge-sensitive payment
            arrangements
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Implement patient-level costing and clinical reporting, enabling measurement and reduction of clinical and cost
            variation
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Benchmark the cost of procedures to peers and regional or national costs and Medicare
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Case mix adjustment and risk stratification for your patient population and comparison to regional norms
          </Typography>
        </li>
      </ul>

      <Typography variant="body1" gutterBottom>
        If you would like to learning more about how Careplots can help your organization to navigate price transparency regulatory
        requirements, schedule a call with us and we will provide you a free customized opportunity analysis.
      </Typography>
    </>
  );
}

export default HealthCarePriceTransparency;
