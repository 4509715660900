import { Grid, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import DrugInfo from '../components/public-drug-report/DrugInfo';
import OtherDrugsInClass from '../components/public-drug-report/OtherDrugsInClass';
import SocialAdverseReactions from '../components/public-drug-report/SocialAdverseReactions';
import SafetyAdverseReactions from '../components/public-drug-report/SafetyAdverseReactions';
import SocialTopAdverseReactions from '../components/public-drug-report/SocialTopAdverseReactions';
import SafetyTopAdverseReactions from '../components/public-drug-report/SafetyTopAdverseReactions';
import SafetyTopAdverseReactionsClass from '../components/public-drug-report/SafetyTopAdverseReactionsClass';
// import SocialTopAdverseReactionsInClass from '../components/public-drug-report/SocialTopAdverseReactionsInClass';
import SocialTopAdverseReactionsTrends from '../components/public-drug-report/SocialTopAdverseReactionsTrends';
// import SocialTopAdverseReactionsTrendsClass from '../components/public-drug-report/SocialTopAdverseReactionsTrendsClass';
import SocialSentimentPostCounts from '../components/public-drug-report/SocialSentimentPostCounts';
import DrugInfoTreatedConditions from '../components/public-drug-report/DrugInfoTreatedConditions';
import LabelInformation from '../components/public-drug-report/LabelInformation';
import DrugDisclaimers from '../components/DrugDisclaimers';
import TitledComponent from '../components/TitledComponent';
import SafetyTopAdverseReactionsTrends from '../components/public-drug-report/SafetyTopAdverseReactionsTrends';
import TextStatement from '../components/TextStatement';
import DrugPrice from '../components/public-drug-report/DrugPrice';

function DeviceDetails() {
  /** ******
   * Drug Name, Class, Other drugs in class, Duration Since Market Release
   * Safety Data: Count of AE reported, Trends by Year, Serious Index Trend
   * Safety Data: Most frequent Top 30 AEs, Trends by AE by Year
   * Safety Data: AE Comparison with Other Drugs in Class
   * Social Data: Patient Sentiment
   * Social Data: Most frequent Top 30 AEs, Trends by AE by Year
   * Social Data: AE Comparison with Other Drugs in Class
   */

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DrugDisclaimers />
        </Grid>
        <Grid item xs={12}>
          <DrugInfo />
        </Grid>
        <Grid item xs={12}>
          <DrugPrice />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DrugInfoTreatedConditions />
        </Grid>
        <Grid item xs={12} sm={6}>
          <OtherDrugsInClass />
        </Grid>

        <Grid item xs={12}>
          <LabelInformation />
        </Grid>

        <Grid item xs={12}>
          <TextStatement height={140} color={grey[800]}>
            <Typography variant="body1">
              Virtually all prescription drugs have side effects (or adverse events) and everyone reacts to medications differently.
              Side effects are defined as unexpected events or reactions to a drug, varying from minor problems like a runny nose to
              life-threatening events, such as an increased risk of a heart attack. Adverse Events are used interchangeably with
              Adverse Reactions, however Adverse Drug Reactions (ADR) refer to dangerous effects that can occur with any type of
              medication, during usual clinical use. An Adverse Event is considered serious if the result is: death;
              life-threatening; hospitalization; disability or permanent damage; or causing birth defect. See the frequency of ADRs
              that were reported for your drug of interest.
            </Typography>
          </TextStatement>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SafetyAdverseReactions />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SocialAdverseReactions />
        </Grid>

        <Grid item xs={12}>
          <TitledComponent alignTitle="center" title="Top adverse reactions">
            <TextStatement height={140} color={grey[800]}>
              <Typography variant="body1">
                What are the most frequent Adverse Reactions of this drug reported by providers to the FDA? On average, how is this
                compared to the other drugs in the class reported by providers to the FDA? What are the most frequent Adverse
                Reactions of this drug reported by patients in Social Media channels? See information below.
              </Typography>
            </TextStatement>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <SafetyTopAdverseReactions />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SafetyTopAdverseReactionsClass />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SocialTopAdverseReactions />
              </Grid>
            </Grid>
          </TitledComponent>
        </Grid>

        {/* <Grid item xs={12} sm={6}>
          <SocialTopAdverseReactionsInClass />
        </Grid> */}

        <Grid item xs={12}>
          <SafetyTopAdverseReactionsTrends />
        </Grid>
        <Grid item xs={12}>
          <SocialTopAdverseReactionsTrends />
        </Grid>
        {/* <Grid item xs={12}>
          <SocialTopAdverseReactionsTrendsClass />
        </Grid> */}
        <Grid item xs={12}>
          <SocialSentimentPostCounts />
        </Grid>
      </Grid>
    </>
  );
}

export default DeviceDetails;
