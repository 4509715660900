import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Typography, Box, Button, Paper, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import sanityClient from '../modules/sanity';

export default function NewsHome({ backgroundImage }) {
  const [news, setNews] = useState([]);

  const classes = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    container: {
      // backgroundImage: `url(${backgroundImage})`,
      backgroundImage: `linear-gradient(90deg, rgba(13,8,32,1) 0%, rgba(117,117,117,0.2) 100%), url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      minHeight: 200,
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    mainFeaturedPostContent: {
      position: 'relative',
      padding: theme.spacing(3),
      paddingTop: theme.spacing(6),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(6),
        paddingTop: theme.spacing(12),
        paddingRight: 0,
      },
    },
  }))();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "newsPost"]| order(releaseDate desc)[0..2] {
      title,
      slug,
      releaseDate,
      body
    }`
      )
      .then((data) => {
        setNews(data);
      })
      .catch(console.error);
  }, []);

  return (
    <>
      <Paper className={classes.mainFeaturedPost} square>
        <div className={classes.container}>
          <Grid container className={classes.root} spacing={2} justify="center">
            <Grid item xs={12} md={8}>
              <Typography component="h2" variant="h4" color="inherit" gutterBottom>
                News
              </Typography>
              <Grid container justify="center" alignItems="stretch" spacing={2}>
                {news &&
                  news.map((post) => (
                    <Grid item md={4} xs={12} key={post.slug.current} className={classes.item}>
                      <Typography variant="body2" color="inherit">
                        {moment(post.releaseDate).format('MMMM D, YYYY')}
                      </Typography>

                      <Typography component="h3" variant="h5" color="inherit" gutterBottom>
                        <Link component={RouterLink} to={`/about-us/news/${post.slug.current}`} color="inherit">
                          {post.title}
                        </Link>
                      </Typography>

                      {/* <Box display="flex" justifyContent="flex-start">
                        <Button component={RouterLink} to={`/about-us/news/${post.slug.current}`} variant="outlined">
                          Read more...
                        </Button>
                      </Box> */}
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </>
  );
}
