import { CircularProgress, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TitledComponent from './TitledComponent';
import { getPublicData } from '../modules/reportData';

function ReportPublicDataComponent(props) {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  const { metricId, drug, alignTitle, title, children } = props;

  useEffect(() => {
    if (drug) {
      setReady(false);
      getPublicData({ setData, setReady, setError, metricId, drug });
    }
  }, [drug]);

  if (!ready) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Typography variant="body1" color="error" align="center">
        {error.message}
      </Typography>
    );
  }

  return (
    <TitledComponent title={title} alignTitle={alignTitle}>
      {React.cloneElement(children, { data })}
    </TitledComponent>
  );
}

ReportPublicDataComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  metricId: PropTypes.string.isRequired,
  title: PropTypes.string,
  alignTitle: PropTypes.string,
  drug: PropTypes.string.isRequired,
};

ReportPublicDataComponent.defaultProps = {
  alignTitle: 'left',
  title: '',
};

export default ReportPublicDataComponent;
