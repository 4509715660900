import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';

export default function LoginContainer({ children, title }) {
  const classes = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(4),
      // background: 'rgb(49,27,146)',
      background: 'linear-gradient(180deg, rgba(13,8,32,1) 0%, rgba(117,117,117,0.8) 100%)',
      // backgroundImage: 'linear-gradient(rgba(14, 10, 33, 0.8), rgba(183, 203, 222, 0.2)), url(/images/pills5.jpeg)',
    },
  }))();
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={8} sm={6} className={classes.root}>
          {children}
        </Grid>
      </Grid>
    </>
  );
}
