import { Typography } from '@material-ui/core';
import React from 'react';
import Helmet from 'react-helmet';

function DataPlots() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Data Plots is a 'data as a service' product delivering drug, device and disease data registries to lifescience organizations."
        />
        <meta
          name="twitter:description"
          content="Data Plots is a 'data as a service' product delivering drug, device and disease data registries to lifescience organizations."
        />
        <meta
          property="og:description"
          content="CData Plots is a 'data as a service' product delivering drug, device and disease data registries to lifescience organizations."
        />
      </Helmet>
      <Typography variant="body1" gutterBottom>
        Data Plots is a &apos;data as a service&apos; product delivering drug, device and disease data registries to lifescience
        organizations looking to evaluate patient outcomes related to their product use in the real world. A &apos;Data Plots&apos;
        registry is a collection of safety, social media, medical and pharmacy claims, lab results, clinical and social determinants
        data sets specific to the drug of interest and the competitor products, the population and the condition the product is
        treating.
      </Typography>
      <Typography variant="body1" gutterBottom>
        &apos;Data Plots&apos; are observational &apos;data lakes&apos; that are compliant to HIPAA deidentification &apos;safe
        harbor&apos; method, while maintaining encrypted unique identifiers for patients, providers, facilities and service
        timelines that are crucial for research acuity. For instance, a disease registry includes records of patients who have or
        have had a disease or condition of interest, enabling researchers to study the population with a particular disease and
        adverse event profiles for different treatments. A drug registry is composed of patients exposed to a health care product
        (drug) facilitating studies of drug utilization patterns, outcomes, etc.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Data Plots are designed as organized data systems that uses observational sources to collect uniform and longitudinal data
        to evaluate specified outcomes for a population defined by a particular disease, condition or exposure and that serves one
        or more predetermined scientific, clinical or policy purposes. Observational studies derived from registries are an
        important part of the research alongside interventional studies, such as randomized controlled trials (RCTs) and
        retrospective studies, such as studies derived exclusively from administrative claims data. These study types are more
        complementary than competitive, precisely because some research questions are better answered by one method than the other.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Interventional studies are considered to provide the highest-grade evidence for evaluating whether a drug has the ability to
        bring about an intended effect in optimal or &quot;ideal world&quot; situations, a concept also known as
        &quot;efficacy.&quot; On the other hand, observational designs are particularly well suited for studying broader
        populations, understanding actual results (e.g., some safety outcomes) in real-world practice and for obtaining more
        representative quality-of-life information. This is particularly true when the factors surrounding the decision to treat are
        an important aspect of understanding treatment effectiveness.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Careplots applications are primarily concentrated on determining clinical effectiveness or cost-effectiveness or assessing
        safety or harm are generally hypothesis driven and concentrate on evaluating the effects of specific treatments on patient
        outcomes. The questions typically addressed in &apos;Data Plots&apos; registries range from purely descriptive questions
        aimed at understanding the characteristics of people who develop the disease and how the disease generally progresses, to
        highly focused questions intended to support decision making. In addition, &apos;Data Plots&apos; registries may be used as
        surveillance systems for monitoring of adverse events, as well as to quantify and evaluate risk and benefit throughout a
        product&apos;s lifecycle.
      </Typography>
    </>
  );
}

export default DataPlots;
