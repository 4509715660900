import React from 'react';
import Login from '../components/Login';
import LoginFormsContainer from './LoginFormsContainer';

export default function LoginContainer() {
  return (
    <LoginFormsContainer title="Log in">
      <Login />
    </LoginFormsContainer>
  );
}
