import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

function DataManagement({ color }) {
  const classes = makeStyles((theme) => ({
    subtitle: {
      marginTop: theme.spacing(2),
      color,
    },
  }))();
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Modern cloud-based data management framework will ensure the delivery of a scalable set of capabilities that meet the operational requirements, planned analysis, and associated use cases, while providing mechanisms to manage the cost of operating and maintaining the system."
        />
        <meta
          name="twitter:description"
          content="Modern cloud-based data management framework will ensure the delivery of a scalable set of capabilities that meet the operational requirements, planned analysis, and associated use cases, while providing mechanisms to manage the cost of operating and maintaining the system."
        />
        <meta
          property="og:description"
          content="Modern cloud-based data management framework will ensure the delivery of a scalable set of capabilities that meet the operational requirements, planned analysis, and associated use cases, while providing mechanisms to manage the cost of operating and maintaining the system."
        />
      </Helmet>

      <Typography variant="body1" gutterBottom>
        Data and information management are key drivers for the health care transformation toward high quality and accessible value
        based care. Careplots data management framework is designed to address important challenges facing policy makers and health
        professionals today such as data fragmentation, data quality, data consistency, interoperability, standards, compliance,
        linkages.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Our modern cloud-based data management framework include innovative techniques in key domains of data architecture and
        governance, data lifecycle, and data storage, with applicability in major public data initiatives such as All Payer Claims
        Databases, Health Information Exchanges and public health data lakes.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Leverage our team of technical and healthcare domain experts to take on your most complex data operations challenges.
        Careplots offers comprehensive data technology services meet the needs of your fast paced business needs. Achieve greater
        scale and speed with our advanced cloud based data management services. Leverage industry best practices and proven
        technologies to drive greater data and analytical throughputs.
      </Typography>

      <Typography variant="h5" component="h2" className={classes.subtitle} gutterBottom>
        Data Exchanges
      </Typography>
      <Typography variant="body1" gutterBottom>
        Deploy cutting edge reusable APIs to simplify, link and streamline data integration efforts across disparate healthcare data
        sources. Leverage advancements in health care interoperability standards (FHIR) for patient data exchanges from EHRs and
        health insurance systems. Make use of CMS innovations to standardize API data exchanges for public and commercial insurers
        (CARIN and BCD FHIR BULK data exchanges)
      </Typography>

      <Typography variant="h5" component="h2" className={classes.subtitle} gutterBottom>
        Data Collection
      </Typography>
      <Typography variant="body1" gutterBottom>
        Deploy robust and sophisticated data submission portal powered by Salesforce to track, audit, report, and manage work flow
        activities around data ingestion. Streamline data submitter &apos; stakeholder registration procedures, manage workflows,
        get alerts &apos; notifications throughout the data submission life cycle. Maximize user experience, engage and stay in
        touch with relevant healthcare partners and participants leveraging the power of Salesforce CRM.
      </Typography>

      <Typography variant="h5" component="h2" className={classes.subtitle} gutterBottom>
        Data Quality Management
      </Typography>
      <Typography variant="body1" gutterBottom>
        Achieve highest data quality outputs by driving standards from national standards, CDL 2 submission guide. Leverage our
        multi-factored, multi-tiered data quality framework for assessing data quality from initial record count and paid field
        reconciliation, data completeness checks, frequency distribution analysis, data integrity checks, volumes by service
        category, to trending of PMPM / PMPY measures. Consume and author the data quality workflow outputs in our automated
        workflow engine powered by Salesforce.
      </Typography>

      <Typography variant="h5" component="h2" className={classes.subtitle} gutterBottom>
        Data Lakes and Warehousing
      </Typography>
      <Typography variant="body1" gutterBottom>
        Leverage our decades long experience and technical wisdom around data warehouse and data lakes to maximize data curation and
        consumption. Modern data exchanges via API architecture for real time data ingestion to complement conventional data
        transfers Manage and author the modality of disparate legacy data sources both legacy and new to achieve great data
        insights. Build comprehensive metadata repository for curating data warehouses and data lakes. Leverage data processing
        automation including scalable ETL framework and integrated value adds to reduce the latency to actionable information.
      </Typography>

      <Typography variant="h5" component="h2" className={classes.subtitle} gutterBottom>
        Data Enrichment
      </Typography>
      <Typography variant="body1" gutterBottom>
        Make use of industry’s best specialized risk groupers and episodic groupers to enrich your data for greater analytic
        insights into your healthcare data. From DRG, APR-DRG, CDPS, HCC, ACG, MEG and ETG, let our extensive experience applying
        grouper software help you to support your most demanding advanced analytics and reporting needs. Deploy our performance
        measurement library, supported by HEDIS, CMS, CQM, AHRQ, NQF, and IHA specifications for insight into your healthcare
        population. Leverage commercial and CMS based attribution algorithms to better evaluate and measure the delivery and quality
        of care.
      </Typography>

      <Typography variant="h5" component="h2" className={classes.subtitle} gutterBottom>
        Data Security
      </Typography>
      <Typography variant="body1" gutterBottom>
        Leverage HIPAA and HITECH compliance data centers for your most sensitive data storage needs. Make use of our data
        encryption framework, with encryption anywhere in transit, at rest, in drives, databases, and workbooks. Encrypt PHI &amp;
        PII fields prior to data ingestion and integration to minimize security risk, without affecting the utility or the integrity
        of the key fields in your data set.
      </Typography>
    </>
  );
}

DataManagement.propTypes = {
  color: PropTypes.string,
};

DataManagement.defaultProps = {
  color: '#fff',
};

export default DataManagement;
