import React, { useEffect, useState } from 'react';
import { blue, green, indigo, lime, orange, pink, purple, red, teal } from '@material-ui/core/colors';
import PanToolIcon from '@material-ui/icons/PanTool';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AssessmentIcon from '@material-ui/icons/Assessment';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import PregnantWomanIcon from '@material-ui/icons/PregnantWoman';
import { Auth } from 'aws-amplify';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { CircularProgress, Link, Grid, Typography, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Helmet from 'react-helmet';

function DemoHome() {
  const [ready, setReady] = useState(false);

  const classes = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    item: {
      marginBottom: theme.spacing(4),
    },
    icon: {
      fontSize: 40,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  }))();

  const history = useHistory();
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        setReady(true);
      })
      .catch(() => {
        history.push('/login');
      });
  }, []);

  if (!ready) return <CircularProgress />;

  return (
    <>
      <Helmet>
        <title>Demo</title>
      </Helmet>

      <Grid container spacing={2} justify="center">
        <Grid item xs={12} md={8}>
          <Grid container className={classes.root} justifyContent="center" spacing={2}>
            <Grid item md={4} xs={12} className={classes.item}>
              <Typography component="h3" variant="h5" gutterBottom>
                <Link component={RouterLink} style={{ color: `${teal.A100}` }} to="/demo/add5a9b9-aeeb-4b20-b72c-bdba46c8d56c">
                  <PanToolIcon />
                  &nbsp;Safety Plots
                </Link>
              </Typography>
            </Grid>
            <Grid item md={4} xs={12} className={classes.item}>
              <Typography component="h3" variant="h5" gutterBottom>
                <Link component={RouterLink} style={{ color: `${red.A100}` }} to="/demo/7ea5e1a6-8623-4ff9-a170-d03bfb98b67a">
                  <TrendingUpIcon />
                  &nbsp;Claims Plots
                </Link>
              </Typography>
            </Grid>
            <Grid item md={4} xs={12} className={classes.item}>
              <Typography component="h3" variant="h5" gutterBottom>
                <Link component={RouterLink} style={{ color: `${blue.A100}` }} to="/demo/cc8ea052-e096-45c1-9a80-bbc4d94f3fc7">
                  <BubbleChartIcon />
                  &nbsp;Rate Plots
                </Link>
              </Typography>
            </Grid>
            <Grid item md={4} xs={12} className={classes.item}>
              <Typography component="h3" variant="h5" gutterBottom>
                <Link component={RouterLink} style={{ color: `${orange.A100}` }} to="/demo/d9d6f51a-f581-48dc-a0fd-422a1e6743af">
                  <AssessmentIcon />
                  &nbsp;Benefits Plots
                </Link>
              </Typography>
            </Grid>
            <Grid item md={4} xs={12} className={classes.item}>
              <Typography component="h3" variant="h5" gutterBottom>
                <Link component={RouterLink} style={{ color: `${lime.A100}` }} to="/demo/9f4da7eb-3801-4c02-a4f8-395547faaa84">
                  <SearchIcon />
                  &nbsp;Transparency Plots
                </Link>
              </Typography>
            </Grid>
            <Grid item md={4} xs={12} className={classes.item}>
              <Typography component="h3" variant="h5" gutterBottom>
                <Link component={RouterLink} style={{ color: `${pink.A100}` }} to="/demo/f467a255-2b26-4118-a078-fdd185cc0c22">
                  <PregnantWomanIcon />
                  &nbsp;Population Health Plots
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default DemoHome;
