import {
  TextField, Typography, CircularProgress, Chip, makeStyles,
} from '@material-ui/core';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import DevicesList from './DevicesList';
import ReactGA from '../modules/google-analytics';

function DeviceSearchType() {
  const [deviceName, setDeviceName] = useState('');
  const [searchDeviceType] = useDebounce(deviceName, 1000);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [selectedDeviceType, setSelectedDeviceType] = useState(null);
  const [devices, setDevices] = useState([]);
  const [ready, setReady] = useState(true);

  const classes = makeStyles((theme) => ({
    chips: {
      marginBottom: theme.spacing(2),
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    resultsArea: {
      marginTop: theme.spacing(2),
    },
  }))();

  const handleDeviceNameChange = (e) => {
    setDeviceName(e.target.value);
  };

  useEffect(() => {
    setMessage('');
    setError(null);
    setSelectedDeviceType(null);
    setDeviceTypes(null);
    setDevices(null);

    async function getDeviceTypes() {
      setReady(false);
      try {
        const response = await API.get('devices', '/deviceTypes', { queryStringParameters: { deviceType: searchDeviceType } });
        if (response.error) {
          throw response.error;
        }
        setDeviceTypes(response);
        if (response.length === 0) {
          setMessage('No results. Please try a different search.');
        }
      } catch (e) {
        setError(e);
      } finally {
        setReady(true);
      }
    }

    // if we have something to work with
    if (searchDeviceType && searchDeviceType.length > 2) {
      ReactGA.event({
        category: 'Device search',
        action: 'Searched device type',
        label: searchDeviceType,
      });
      getDeviceTypes();
    }
  }, [searchDeviceType]);

  useEffect(() => {
    setError(null);
    async function getDevicesByType() {
      setDevices(null);
      setReady(false);
      try {
        const response = await API.get('devices', '/devicesByType', { queryStringParameters: { deviceType: selectedDeviceType } });
        if (response.error) {
          throw response.error;
        }
        setDevices(response);
        if (response.length === 0) {
          setMessage('No results. Please try a different search.');
        }
      } catch (e) {
        setError(e);
      } finally {
        setReady(true);
      }
    }

    if (selectedDeviceType) {
      ReactGA.event({
        category: 'Device search',
        action: 'Selected device type',
        label: selectedDeviceType,
      });
      getDevicesByType();
    }
  }, [selectedDeviceType]);

  return (
    <>
      <TextField
        label="Search by device type"
        value={deviceName}
        onChange={handleDeviceNameChange}
        fullWidth
      />
      <div className={classes.resultsArea}>
        {message && <Typography variant="body1" color="primary">{message}</Typography>}
        {error && <Typography variant="body1" color="error">{error.message}</Typography>}
        {ready && deviceTypes && !selectedDeviceType && (
          <div className={classes.chips}>
            {deviceTypes.map((t) => (
              <Chip
                color="primary"
                key={t}
                label={t.deviceType}
                onClick={() => {
                  setSelectedDeviceType(t.deviceType);
                  setDevices([]);
                }}
              />
            ))}
          </div>
        )}
        {ready && selectedDeviceType
          && (
            <div className={classes.chips}>
              <Chip label={selectedDeviceType} color="secondary" onDelete={() => (setSelectedDeviceType(null))} />
            </div>
          )}
        {ready && selectedDeviceType && devices && <DevicesList products={devices} />}
        {!ready && <CircularProgress />}
      </div>
    </>
  );
}

export default DeviceSearchType;
