import React from 'react';
import { useParams } from 'react-router-dom';
import ReportPublicDataComponent from '../ReportPublicDataComponent';
import LineChartWithFilters from '../LineChartWithFilters';

const metricId = 'social_adverse_reactions_trends';

function SocialTopAdverseReactionsTrends() {
  const { drug } = useParams();
  return (
    <ReportPublicDataComponent title="What is the trend for most frequent Adverse Reactions reported by patients in social media channels?" metricId={metricId} drug={drug}>
      <LineChartWithFilters />
    </ReportPublicDataComponent>
  );
}

export default SocialTopAdverseReactionsTrends;
