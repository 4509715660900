import { Typography } from '@material-ui/core';
import React from 'react';
import Helmet from 'react-helmet';

function AboutUsHistory() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="The original idea was conceived in early 2018 by a group of technology entrepreneurs and health care business veterans"
        />
        <meta
          name="twitter:description"
          content="The original idea was conceived in early 2018 by a group of technology entrepreneurs and health care business veterans"
        />
        <meta
          property="og:description"
          content="The original idea was conceived in early 2018 by a group of technology entrepreneurs and health care business veterans"
        />
      </Helmet>
      <Typography variant="body1" gutterBottom>
        Careplots business idea was conceived in early 2018 by a group of technology entrepreneurs and informatics experts who came
        together to work on a software product that will change the way medical products are paid for based on real world data, real
        world outcomes and performance. The founders had deep knowledge of health systems operations, including delivery of care,
        insurance and financing, research and development and manufacturing.{' '}
      </Typography>

      <Typography variant="body1" gutterBottom>
        The platform has been envisioned as a 'consumer reports' site for drugs and devices performance. The founders team made an
        early decision to focus on measuring performance of various instruments of care, such as drugs and medical devices, in
        contrast to the traditional method of judging the performance of physicians and hospitals. The team used elastic cloud
        infrastructure and modern data management framework to collect and ingest data from numerous public and private sources. The
        data lakes have been curated, integrated and analyzed through statistical modeling and machine learning techniques to
        understand the clinical efficacy and economical effectiveness of various products that are marketed for treating specific
        conditions. The results confirmed that not all products worked consistently in the long run, not all patients are responding
        positively to these treatments, and often no additional benefit is observed when compared to similar products in their
        categories, which echoed findings from recent studies published in prestigious scientific journals.{' '}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Manufacturers are taking significant financial risk to bring products on the market, investing heavily in research and
        development, clinical trials and marketing, however performance evaluation information is not readily available, and largely
        done in academic studies. In response to this gap, Careplots software platform has been designed to subjectively measure
        drug effectiveness and efficacy in the real world, using longitudinal data sets from millions of patients from a variety of
        global data sources. We believe that by empowering consumers and business organizations with value-based care information
        that is simple to digest, common sense actionable, and with immediate impact on everyday lives, the health care costs can be
        contained, access to quality care may be improved and utilization of high cost services may be reduced, providing
        significant savings for patients and businesses.
      </Typography>

      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          marginBottom: 20,
        }}>
        <img src="/images/healthaffairs.png" alt="health affairs" />
        <img src="/images/sciencedaily.png" alt="sience daily" />
      </div> */}
    </>
  );
}

export default AboutUsHistory;
