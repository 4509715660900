import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

function PleaseLogin() {
  return (
    <Typography gutterBottom variant="body1">
      Please
      <Link component={RouterLink} to="/login">
        Log in
      </Link>
      or
      <Link component={RouterLink} to="/signup">
        Sign up
      </Link>
      before checking out.
    </Typography>
  );
}

export default PleaseLogin;
