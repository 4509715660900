import React from 'react';
import { useParams } from 'react-router-dom';
import ReportPublicDataComponent from '../ReportPublicDataComponent';
import ListWithBars from '../ListWithBars';

const metricId = 'social_top_adverse_reactions_reported';

function SocialTopAdverseReactions() {
  const { drug } = useParams();
  return (
    <ReportPublicDataComponent title={`ADRs For ${drug} In Social Media`} metricId={metricId} drug={drug}>
      <ListWithBars />
    </ReportPublicDataComponent>
  );
}

export default SocialTopAdverseReactions;
