import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Table, TableHead, TableRow, TableCell, TableBody,
} from '@material-ui/core';
import ReportDataComponent from './ReportDataComponent';

const metricId = 'social_top_adverse_reactions_reported';

const RenderComponent = ({ data }) => {
  if (!data || data.length === 0) {
    return <Typography variant="body1">N/A</Typography>;
  }

  const postCount = data.reduce((accumulator, currentValue) => accumulator + currentValue.posts, 0);

  const top = data.splice(0, 20);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>&nbsp;</TableCell>
          <TableCell><Typography variant="body1">Posts</Typography></TableCell>
          <TableCell><Typography variant="body1">%</Typography></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {top.map((d) => (
          <TableRow key={d.preferred_text}>
            <TableCell><Typography variant="body1">{d.preferred_text}</Typography></TableCell>
            <TableCell><Typography variant="body1">{d.posts}</Typography></TableCell>
            <TableCell>
              <Typography variant="body1">
                {Math.round((d.posts / postCount) * 100).toFixed(0)}
                %
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

function SocialMediaTopAdverseReactionsReported() {
  return (
    <ReportDataComponent title="Top Adverse Reactions Reported" metricId={metricId} alignTitle="left">
      <RenderComponent />
    </ReportDataComponent>
  );
}

RenderComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ adverse_reactions_reported: PropTypes.number })),
};

RenderComponent.defaultProps = {
  data: [],
};

export default SocialMediaTopAdverseReactionsReported;
