import { Typography } from '@material-ui/core';
import React from 'react';
import Helmet from 'react-helmet';

function SocialDeterminantsOfHealth() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="The COVID-19 epidemic has brought widespread attention to the complex interplay between social factors and health outcomes."
        />
        <meta
          name="twitter:description"
          content="The COVID-19 epidemic has brought widespread attention to the complex interplay between social factors and health outcomes."
        />
        <meta
          property="og:description"
          content="The COVID-19 epidemic has brought widespread attention to the complex interplay between social factors and health outcomes."
        />
      </Helmet>
      <Typography variant="body1" gutterBottom>
        The COVID-19 epidemic has brought widespread attention to the complex interplay between social factors and health outcomes.
        Determining correlations, causality and relationships remains a challenging endeavor because data on community factors are
        spread across different datasets published by numerous agencies in various web sites with varying documentation. Careplots
        platform is filling this gap by making available actionable data sets, aggregated and precomputed for easy linkages in order
        to study the intersection of community level risk factors and health outcomes and to inform efforts to reduce those risks.
        Careplots data platform focuses on the social determinants impact on medical products efficacy and effectiveness.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Our &apos;data as a service&apos; Data Plots product contains harmonized and &apos;analysis ready&apos; SODH data lakes with
        highest data integrity, data accuracy, and consistency amongst the data products. The platform includes millions of data
        points covering a number of domains at different geographical units such as county and zip code levels, across
        &quot;domains&quot; such as:
      </Typography>

      <ul>
        <li>
          <Typography variant="body1">Demographics: age, race, ethnic, religion, and gender distribution</Typography>
        </li>
        <li>
          <Typography variant="body1">Safety: community safety, crime, legislation</Typography>
        </li>
        <li>
          <Typography variant="body1">Economic: employment, income, poverty, property value</Typography>
        </li>
        <li>
          <Typography variant="body1">Education: schools, literacy and high school graduation</Typography>
        </li>
        <li>
          <Typography variant="body1">
            Infrastructure: housing, transportation, food availability, recreational facilities
          </Typography>
        </li>
        <li>
          <Typography variant="body1">Climate: weather patterns, climate change, disasters</Typography>
        </li>
        <li>
          <Typography variant="body1">Cultural: access to cultural events, sport events</Typography>
        </li>
        <li>
          <Typography variant="body1">Religious: religious distribution, religious organizations</Typography>
        </li>
        <li>
          <Typography variant="body1">Fitness: number of gyms, membership trends</Typography>
        </li>
        <li>
          <Typography variant="body1">
            Healthcare: medical services use, condition incidence, prescription drug usage, access to insurance, health behaviors,
            patient safety, healthcare spending.
          </Typography>
        </li>
      </ul>
    </>
  );
}

export default SocialDeterminantsOfHealth;
