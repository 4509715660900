import { Typography } from '@material-ui/core';
import React from 'react';
import Helmet from 'react-helmet';

function ClinicalWastefulSpending() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Careplots has developed a solution to support health care policy evaluators and insurers to identify and quantify wasteful healthcare spending."
        />
        <meta
          name="twitter:description"
          content="Careplots has developed a solution to support health care policy evaluators and insurers to identify and quantify wasteful healthcare spending."
        />
        <meta
          property="og:description"
          content="Careplots has developed a solution to support health care policy evaluators and insurers to identify and quantify wasteful healthcare spending."
        />
      </Helmet>
      <Typography variant="body1" gutterBottom>
        In an era where almost 18% of Gross Domestic Product or $3.5 trillion is spent on healthcare, emphasis on cost control and
        efficiency is of upmost concern. A recent study performed by Agency for Healthcare Research and Quality found that almost
        $34 billion in aggregate hospital costs were potentially avoidable. Careplots has developed a solution to support health
        care policy evaluators and insurers to identify and quantify wasteful healthcare spending. It identifies potentially
        unnecessary services to control costs and improve healthcare efficiency. The goals are to eliminate potentially inefficient
        and unnecessary medical services such as overtreatment, medically unnecessary disease screening, failure of care delivery,
        failure of care coordination, triggers to profile providers, and to improve overall healthcare efficiency while reducing
        costs.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Clinical waste is created by the production of low-value outputs or spending to produce services that provide marginal or no
        health benefit over less costly alternatives. Wasteful services include those that have detrimental health effects, or small
        positive health effects, compared with less costly alternatives. There is significant overlap between clinical waste and or
        providing the wrong service, and operational waste, or the inefficient production of services. For example, the overuse of
        diagnostic tests might be due to operational inefficiencies such as lost medical records or to the clinically inefficient
        use of these tests.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Careplots calculates wasteful clinical spending as the total cost and percent of health spending for certain
        &quot;wasteful&quot; clinical procedures, as determined by experts from medical fields that were indicated as potentially
        generating large amounts of waste (e.g., radiology, heart disease, back pain). Careplots analytics team has the know-how and
        technological capability to mine billions of health care records to find ‘degree of wasteful spending&apos; and occurrences,
        based on medical associations guidelines such as ‘choosing wisely&apos;, or evidence based clinical pathways.
      </Typography>
      <Typography variant="body1" gutterBottom>
        If you would like to learning more about how Careplots can help your organization to implement wasteful spending
        capabilities, schedule a call with us and we will provide you a free customized opportunity analysis.
      </Typography>
    </>
  );
}
export default ClinicalWastefulSpending;
