import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ReportDataComponent from './ReportDataComponent';

const metricId = 'social_social_media_posts';
const RenderComponent = ({ data }) => (<Typography variant="h6" align="right">{(data && data.length > 0 && data[0].social_media_posts) || 'N/A'}</Typography>);


function SocialSocialMediaPosts() {
  return (
    <ReportDataComponent title="Social Media Posts" metricId={metricId} alignTitle="right">
      <RenderComponent />
    </ReportDataComponent>
  );
}

RenderComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ social_media_posts: PropTypes.number })),
};

RenderComponent.defaultProps = {
  data: [],
};

export default SocialSocialMediaPosts;
