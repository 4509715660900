import {
  makeStyles, Paper, Tab, Tabs,
} from '@material-ui/core';
import React, { useState } from 'react';
import RequestReportContext from '../contexts/RequestReportContext';
import DrugSearchByCondition from './DrugSearchByCondition';
import DrugSearchName from './DrugSearchName';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function RequestReport() {
  const [condition, setCondition] = useState(null);
  const [drugName, setDrugName] = useState('');
  const [drug, setDrug] = useState(null);
  const [tab, setTab] = useState(0);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <RequestReportContext.Provider value={{
      drug, setDrug, condition, setCondition, drugName, setDrugName,
    }}
    >
      <div>
        <Paper className={classes.root}>
          <Tabs
            value={tab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"

          >
            <Tab label="Search by Condition" />
            <Tab label="Search by Drug Name" />
          </Tabs>
          {tab === 0 && <DrugSearchByCondition />}
          {tab === 1 && <DrugSearchName />}
        </Paper>
      </div>
    </RequestReportContext.Provider>
  );
}
