import { Typography } from '@material-ui/core';
import React from 'react';
import Helmet from 'react-helmet';

function SolutionsMedicalDeviceVigilance() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="The Medical Device Vigilance solution is designed to collect information on post-market incidents or adverse events related to medical devices."
        />
        <meta
          name="twitter:description"
          content="The Medical Device Vigilance solution is designed to collect information on post-market incidents or adverse events related to medical devices."
        />
        <meta
          property="og:description"
          content="The Medical Device Vigilance solution is designed to collect information on post-market incidents or adverse events related to medical devices."
        />
      </Helmet>
      <Typography variant="body1" gutterBottom>
        The Medical Device Vigilance solution is designed to collect information on post-market incidents or adverse events related
        to medical devices and to distribute or disseminate such information to prevent adverse events from recurring. Safety Plots
        is a comprehensive software solution designed to streamline medical device vigilance and post-market surveillance processes,
        handling the reporting and management of all serious and non-serious adverse events. It simplifies the need to classify,
        review and maintain device vigilance data and adverse event reports within an easy-to-use application and it provides a
        simpler reporting interface for both manufacturers and consumers.
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          marginBottom: 20,
        }}>
        <img src="/images/medical-device-vigilance.png" alt="Social ADR" />
      </div>
      <Typography variant="body1" gutterBottom>
        Careplots collects, integrates, curates and standardizes data from US regulatory safety and product recall systems, as well
        as international global safety data sources. The safety and recall data points are analyzed to identify potential risks
        associated with medical devices as well as collecting patient commentaries and experiences associated with that medical
        device using processed via specialized clinical natural language processing algorithm.
      </Typography>

      <Typography variant="body1" gutterBottom>
        The resulting outputs are used to report on effectiveness opinions, social sentiment, and side effects to be compared with
        adverse events from regulatory data. The information is organized and disseminated differently depending on the audience.
        The main utilizers are device manufacturers and insurers. Medical device manufacturers must assess and understand device
        risk during the product design stage of medical device development, as well as post market release, to ensure the safety and
        effectiveness of medical devices and avoid the financial and legal liabilities associated with a device recall.
      </Typography>

      <Typography variant="body1" gutterBottom>
        The primary purpose is also to protect and improve safeguards for patients, users and others by preventing or reducing the
        likelihood of reoccurrence of incidents elsewhere. Insurers and providers need to monitor risks and incidence of short term
        adverse effects as well as long term effectiveness of these products. The secondary interface and system is designed for
        consumers, either patients or family members. Too often a procedure is performed without the necessary information of how
        safe and effective a device, which is a foreign object in the body, may be. In recent years, many stories about
        ineffectiveness or danger of certain devices have flooded the media and the web.
      </Typography>

      <Typography variant="body1" gutterBottom>
        In addition, the lack of interoperability and data sources integration worldwide, prevented alerts of several major events
        that were reported in other countries but never made it in US. While this is a small percentage in the grand schema and
        device manufacturers are doing best possible to mitigate these risks, consumers will greatly benefit from have a centralized
        web site to search on medical devices benefits and hazards as well as to use personalized medical guides indicating which
        devices may work best for ‘patients like them&apos;.
      </Typography>
    </>
  );
}

export default SolutionsMedicalDeviceVigilance;
