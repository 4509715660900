import React from 'react';
import { useParams } from 'react-router-dom';
import ReportPublicDataComponent from '../ReportPublicDataComponent';
import ListWithBars from '../ListWithBars';

const metricId = 'safety_top_adverse_reactions_reported_class';

function SafetyTopAdverseReactionsClass() {
  const { drug } = useParams();
  return (
    <ReportPublicDataComponent title="ADRs For Other Drugs In Class" data={drug} metricId={metricId} drug={drug}>
      <ListWithBars />
    </ReportPublicDataComponent>
  );
}

export default SafetyTopAdverseReactionsClass;
