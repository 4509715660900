import { Typography } from '@material-ui/core';
import React from 'react';
import Helmet from 'react-helmet';

function SafetyPlots() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Safety Plots is a Cloud based, software-as-a-service data analytics platform for drug safety signal detection."
        />
        <meta
          name="twitter:description"
          content="Safety Plots is a Cloud based, software-as-a-service data analytics platform for drug safety signal detection."
        />
        <meta
          property="og:description"
          content="Safety Plots is a Cloud based, software-as-a-service data analytics platform for drug safety signal detection."
        />
      </Helmet>
      <Typography variant="body1" gutterBottom>
        Safety Plots is a cloud based, software-as-a-service data analytics platform for drug safety signal detection providing
        powerful insights into emerging risks and patient safety issues, as well as indirect costs associated with treating adverse
        events. Delivered in a secure and compliant environment, using proprietary machine learning (ML) and natural language
        processing (NLP) algorithms, Safety Plots delivers insights that improve compliance, product safety and add value to a
        manufacturer&apos;s product development process.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Our proprietary software connects pre-marketing safety data collected in clinical trial with post-marketing safety data
        collected from many global sources, such as:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            FDA Adverse Event Reporting System (FAERS) and other global sources of adverse events systems
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Adverse events data detected in medical and pharmacy claims data collected by insurers
          </Typography>
        </li>
        <li>
          <Typography variant="body1">Social media data reported by patients in numerous social network web sites</Typography>
        </li>
        <li>
          <Typography variant="body1">Patient self-reported data obtained from Careplots online surveys and calculators</Typography>
        </li>
      </ul>
      <Typography variant="body1" gutterBottom>
        while ensuring the highest degree of data security, privacy, quality and accuracy.
      </Typography>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          marginBottom: 20,
        }}>
        <img src="/images/safety-plots-adr-bench.png" alt="ADR" />
      </div>

      <Typography variant="body1" gutterBottom>
        Safety Plots continuously monitors safety signals worldwide providing a wholistic approach to pharmacovigilance, enabling
        professionals to track and resolve safety signals. The platform is improving compliance, data quality and efficiency while
        reducing cost and complexity by eliminating manual, repetitive tasks so biopharma and medtech companies can focus on
        delivering safer, more effective drugs and devices. The product can be used individually for global pharmacovigllance and
        safety detection purposes, or as a package within the carePlots platform to assess a drug performance in outcome based
        contracts.
      </Typography>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          marginBottom: 20,
        }}>
        <img src="/images/safety-plots-social-adr.png" alt="Social ADR" />
      </div>

      <Typography variant="body1" gutterBottom>
        This type of reimbursement contract is defined as an arrangement between a pharmaceutical manufacturer and a health plan,
        where the price paid for a drug is based on negotiated criteria related to the patient clinical outcomes after using the
        drug. For example, a new drug may be more expensive to cover than an existing drug, but it may also have fewer side effects
        or follow-on treatment requirements, thus lowering the total cost of care. Measuring the clinical impact and the costs of
        drug safety issues is an automated capability in the Careplots platform.
      </Typography>
    </>
  );
}

export default SafetyPlots;
