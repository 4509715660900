import { Button, FormControl, FormHelperText, Grid, Input, InputLabel, Link, Snackbar, SnackbarContent } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import UserContext from '../contexts/UserContext';
import { formStyles } from './formStyles';
import snackbarStyles from './snackbarStyles';

export default function Login() {
  const [error, setError] = useState(null);
  const { setProfile } = useContext(UserContext);
  const history = useHistory();

  const classes = formStyles();
  const snackbarClasses = snackbarStyles();

  const handleSubmit = async (values) => {
    setError(null);
    try {
      await Auth.signIn(values.email, values.password);
      const user = await Auth.currentAuthenticatedUser();
      setProfile(user.attributes);
      history.push('/demo');
    } catch (e) {
      setError(new Error('Invalid email or password.'));
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(null);
  };

  const schema = yup.object({
    email: yup.string().required('Please enter email address.').email('Please enter a valid email address.'),
    password: yup.string().required('Please enter password.'),
  });

  return (
    <>
      <Snackbar
        open={!!error}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        variant="error"
        className={snackbarClasses.error}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}>
        <SnackbarContent
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={snackbarClasses.message}>
              <ErrorIcon className={clsx(snackbarClasses.icon, snackbarClasses.iconVariant)} />
              {error && error.message}
            </span>
          }
          className={snackbarClasses.error}
        />
      </Snackbar>

      <Formik
        validationSchema={schema}
        handleSubmit={handleSubmit}
        onSubmit={handleSubmit}
        initialValues={{ email: '', password: '' }}>
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <form noValidate onSubmit={handleSubmit} className={classes.form}>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                autoFocus
                required
                type="email"
                id="email"
                name="email"
                label="Email"
                autoComplete="email"
                value={values.email}
                onChange={handleChange}
                error={touched.email && !!errors.email}
              />
              {touched.email && !!errors.email && <FormHelperText error>{errors.email}</FormHelperText>}
            </FormControl>
            {/* </Grid> */}
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                required
                id="password"
                type="password"
                name="password"
                label="Password"
                autoComplete="current-password"
                value={values.password}
                onChange={handleChange}
                error={touched.password && !!errors.password}
              />
              {touched.password && !!errors.password && (
                <FormHelperText error type="invalid">
                  {errors.password}
                </FormHelperText>
              )}
            </FormControl>

            <Button type="submit" variant="contained" fullWidth color="primary" className={classes.submit}>
              Login
            </Button>

            <Grid container>
              <Grid item xs>
                <Link component={RouterLink} to="/forgot-password" color="inherit" variant="body1">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                {/* <Link component={RouterLink} to="/signup" color="inherit" variant="body1">
                  Sign Up
                </Link> */}
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
}
