import { Grid } from '@material-ui/core';
import { blue, green, lime, orange } from '@material-ui/core/colors';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import VisibilityIcon from '@material-ui/icons/Visibility';
import StorageIcon from '@material-ui/icons/Storage';
import TransformIcon from '@material-ui/icons/Transform';
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import InfoBlock from '../components/InfoBlock';

export default function Services({ hideHelmet }) {
  return (
    <>
      {!hideHelmet && (
        <Helmet>
          <meta name="description" content="Careplots services." />
          <meta name="twitter:description" content="Careplots services." />
          <meta property="og:description" content="Careplots services." />
        </Helmet>
      )}

      <Grid container direction="row" justify="center" alignItems="stretch" spacing={4}>
        <Grid item xs={12} md={6} style={{ display: 'flex' }}>
          <InfoBlock
            title="Data Management"
            text="Careplots provides end to end data management services to APCDs, HIEs, and Public Health Data Lakes, including API data exchanges, scalable ETL processes and integrated value adds to reduce the latency to actionable information."
            icon={StorageIcon}
            iconColor={blue.A100}
            to="/services/data-management"
          />
        </Grid>

        <Grid item xs={12} md={6} style={{ display: 'flex' }}>
          <InfoBlock
            title="Data Infrastructure"
            text="Careplots provides consulting services to build and operate secure data hosting platforms within public clouds, designed to protect data, enable self-service analysis, and promote collaboration."
            icon={DonutLargeIcon}
            iconColor={lime.A100}
            to="/services/data-infrastructure"
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ display: 'flex' }}>
          <InfoBlock
            title="Healthcare Analytics"
            text="Careplots provides consulting and data analytics services to implement industry's most advanced capabilities including risk adjustment and episodes of care software, quality of care and performance metrics, low value services, choosing wisely measures."
            icon={VisibilityIcon}
            iconColor={green.A100}
            to="/services/health-care-analytics-services"
          />
        </Grid>

        {/* 
      <Grid item xs={12} md={6} style={{ display: 'flex' }}>
        <InfoBlock
          title="Healthcare price transparency"
          text="With accurate patient estimates, hospitals, insurers and pharmaceutical companies can educate their patients on the cost of their care and ease the anxiety of growing out-of-pocket healthcare costs."
          icon={VisibilityIcon}
          iconColor={purple.A100}
          to="/services/healthcare-price-transparency"
        />
      </Grid>
      <Grid item xs={12} md={4} style={{ display: 'flex' }}>
        <InfoBlock
          title="Value-based care delivery"
          text="Careplots platform provides accurate signal detection as well as proactive risk management, with tools and methods for identifying, analyzing, evaluating, communicating potentially serious issues."
          icon={MarkunreadMailboxIcon}
          iconColor={green.A100}
          to="/services/value-based-healthcare-delivery-models"
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ display: 'flex' }}>
        <InfoBlock
          title="Value-based care delivery"
          text="Careplots platform provides accurate signal detection as well as proactive risk management, with tools and methods for identifying, analyzing, evaluating, communicating potentially serious issues."
          icon={MarkunreadMailboxIcon}
          iconColor={green.A100}
          to="/services/value-based-healthcare-delivery-models"
        />
      </Grid>
      
      
      
       */}
        <Grid item xs={12} md={6} style={{ display: 'flex' }}>
          <InfoBlock
            title="Healthcare digital transformation"
            text="Careplots provides management consulting services to help our customers get the most out of their investments in digital technologies through a holistic approach to digital transformation."
            icon={TransformIcon}
            iconColor={orange.A100}
            to="/services/healthcare-digital-transformation-services"
          />
        </Grid>
      </Grid>
    </>
  );
}

Services.propTypes = {
  hideHelmet: PropTypes.bool,
};

Services.defaultProps = {
  hideHelmet: false,
};
