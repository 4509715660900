import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import {
  Button, CircularProgress, Typography, Grid,
} from '@material-ui/core';
import { useParams, Link as RouterLink } from 'react-router-dom';
import ReportContext from '../contexts/ReportContext';
import SocialSocialMediaPosts from '../components/SocialSocialMediaPosts';
import SocialMediaAdverseReactionsReported from '../components/SocialMediaAdverseReactionsReported';
import SocialMediaTopAdverseReactionsReported from '../components/SocialMediaTopAdverseReactionsReported';
import SocialPeriodStartDate from '../components/SocialPeriodStartDate';
import SocialPeriodEndDate from '../components/SocialPeriodEndDate';
import SocialTopMentalDysfunctionsReported from '../components/SocialTopMentalDysfunctionsReported';
import SocialBodyPartsAffected from '../components/SocialBodyPartsAffected';

function DrugReport() {
  const [token, setToken] = useState(null);
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);
  const { subscriptionId } = useParams();

  useEffect(() => {
    async function getToken() {
      const response = await API.get('stripe', '/token', { queryStringParameters: { subscriptionId } });
      if (!response.success) {
        setError(response.error);
      } else {
        setToken(response.token);
      }
      setReady(true);
    }

    if (subscriptionId) {
      getToken();
    }
  }, [subscriptionId]);

  if (!ready) {
    return <CircularProgress />;
  }

  if (error) {
    return (<Typography variant="body1" color="error" align="center">{error.message}</Typography>);
  }

  return (
    <>
      <Button component={RouterLink} to="/dashboard" color="primary">Back to dashboard</Button>
      <ReportContext.Provider value={{ token }}>
        <Grid container spacing={2}>
          <Grid item xs={2}><SocialSocialMediaPosts /></Grid>
          <Grid item xs={3}><SocialMediaAdverseReactionsReported /></Grid>
          <Grid item xs={2}><SocialPeriodStartDate /></Grid>
          <Grid item xs={2}><SocialPeriodEndDate /></Grid>
          <Grid item xs={4}><SocialMediaTopAdverseReactionsReported /></Grid>
          <Grid item xs={4}><SocialTopMentalDysfunctionsReported /></Grid>
          <Grid item xs={4}><SocialBodyPartsAffected /></Grid>
        </Grid>
      </ReportContext.Provider>
    </>
  );
}

export default DrugReport;
