/* eslint-disable no-unused-vars */
import React from 'react';

const UserContext = React.createContext({
  // email: null,
  profile: null,
  // setEmail: (email) => { },
  setProfile: (profile) => { },
  subscribedDrugs: [],
  setSubscribedDrugs: (drugs) => { },
  updateSubscribedDrugs: () => { },
});

export default UserContext;
