import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { makeStyles, Paper, Typography, Button } from '@material-ui/core';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { Link as RouterLink } from 'react-router-dom';
import UserContext from '../contexts/UserContext';
import PleaseLogin from '../components/PleaseLogin';
import CartContents from '../components/CartContents';
import StripeCheckout from '../components/StripeCheckout';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    // [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    //   width: 600,
    //   marginLeft: 'auto',
    //   marginRight: 'auto',
    // },
  },
  paper: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

export default function Cart() {
  const { profile } = useContext(UserContext);
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>Shopping Cart</title>
      </Helmet>

      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom>
            Order details
          </Typography>
          <CartContents />
        </Paper>
        <Typography variant="body1">
          <Button component={RouterLink} to="/search" size="small" color="secondary">
            Continue Shopping
          </Button>
          or Check out:
        </Typography>
        <Paper className={classes.paper}>
          {profile && (
            <>
              <Typography variant="h6" gutterBottom>
                Payment method
              </Typography>
              <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}>
                <Elements>
                  <StripeCheckout />
                </Elements>
              </StripeProvider>
            </>
          )}
          {!profile && <PleaseLogin />}
        </Paper>
      </main>
    </>
  );
}
