import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import DeviceDisclaimers from '../components/DeviceDisclaimers';
/**
 * Search by for 'Interstim', either direct device name or via specialty and device type
Report will show main page including:
* Historical Count of reports, Trends by Year and % Change Up or Down, Specialities using, Manufacturers
* Secondary page: Count and % of Reports by Event Type (Death, Malfunction, , Trends by Year and % Change Up or Down
* Third page: Count and % of Reports by Problem Type, Trends by Year and % Change Up or Down
* Fourth page: Count and % of Reports by Patient Outcome, Trends by Year and % Change Up or Down
* Fifth page: Count and % of Reports by NLP BIOMEDICUS, Trends by Year
* Sixth Page; Drill through to TEXT, see what the problem reported was
 */

function DeviceDetails() {
  const { device } = useParams();

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DeviceDisclaimers />
        </Grid>
      </Grid>
      {/* This is where we&apos;ll display report for */}

      {/* {device} */}
      {/* .  */}
    </>
  );
}

export default DeviceDetails;
