import { makeStyles, Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import * as d3 from 'd3';
import _ from 'lodash';

const ListWithBars = ({ data }) => {
  if (!data || data.length === 0) {
    return <Typography variant="body1">N/A</Typography>;
  }

  const classes = makeStyles(() => ({
    label: {
      textTransform: 'capitalize',
      textSize: 'smaller',
    },
  }))();

  const colors = d3.scaleLinear()
    .domain([1, d3.max(data, (d) => d.value)])
    .range(['white', 'red']);

  const lines = d3.scaleLinear()
    .domain([1, d3.max(data, (d) => d.value)])
    .range([0, 60]);

  const items = _.sortBy(data, (d) => (-d.value));

  return (
    <Grid container>
      <Grid item xs={12}>
        {items.map((d) => (
          <Grid container key={d.label}>
            <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" component="span" className={classes.label}>
                {d.label.toLowerCase()}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <svg height="5" width="60">
                <rect x="0" y="0" width={lines(d.value)} height="5" fill={colors(d.value)} />
              </svg>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

ListWithBars.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.number })),
};

ListWithBars.defaultProps = {
  data: [],
};

export default ListWithBars;
