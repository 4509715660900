import React from 'react';
import { Typography } from '@material-ui/core';

function TermsOfService() {
  return (
    <>
       <Typography variant="h6">Agreement between User and Careplots.com</Typography>
      <Typography variant="body1" gutterBottom>
        Welcome to www.careplots.com. The www.careplots.com (Careplots.com) website (the "Site") is comprised of various web pages
        operated by Careplots Inc ("Careplots"). www.careplots.com is offered to you conditioned on your acceptance without
        modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of www.careplots.com constitutes
        your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Careplots.com is a health analytics information Site, delivering information to businesses and consumers regarding the
        efficacy and effectiveness of various medical products in treating patient's health conditions.
      </Typography>
      <Typography variant="h6">Electronic Communications </Typography>
      <Typography variant="body1" gutterBottom>
        Visiting www.careplots.com or sending emails to Careplots constitutes electronic communications. You consent to receive
        electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide
        to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.
      </Typography>
      <Typography variant="h6">Your Account </Typography>
      <Typography variant="body1" gutterBottom>
        The website displays information classified as ‘real world evidence’ regarding performance of certain medical products, the
        efficacy and effectiveness of these products in treating medical conditions, after their release in the market. General high
        level information is freely available to consumers in the website. In addition to the Website, Careplots provides
        proprietary analytics products, through a separate portal the Careplots (“Platform”), and access to the Platform is subject
        to the terms and conditions of that certain license agreement between each business customer and user of the Platform and
        Careplots (the “License Agreement”). By accessing the Website or using the Platform in any way, you acknowledge and confirm
        that you have read and understood these Terms and agree to abide by these Terms. If you do not agree to any of the Terms,
        you must not use the website.
      </Typography>
      <Typography variant="body1" gutterBottom>
        If you use the Platform, you are responsible for maintaining the confidentiality of your account and password and for
        restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account
        or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that
        Careplots is not responsible for third party access to your account that results from theft or misappropriation of your
        account. Careplots and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit
        content in our sole discretion.
      </Typography>
      <Typography variant="h6">Children Under Thirteen </Typography>
      <Typography variant="body1" gutterBottom>
        Careplots does not knowingly collect, either online or offline, personal information from persons under the age of thirteen.
        If you are under 18, you may use www.careplots.com only with permission of a parent or guardian.
      </Typography>
      <Typography variant="h6">Links to Third Party Sites/Third Party Services</Typography>
      <Typography variant="body1" gutterBottom>
        www.careplots.com may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of
        Careplots and Careplots is not responsible for the contents of any Linked Site, including without limitation any link
        contained in a Linked Site, or any changes or updates to a Linked Site. Careplots is providing these links to you only as a
        convenience, and the inclusion of any link does not imply endorsement by Careplots of the site or any association with its
        operators.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Certain services made available via www.careplots.com are delivered by third party sites and organizations. By using any
        product, service or functionality originating from the www.careplots.com domain, you hereby acknowledge and consent that
        Careplots may share such information and data with any third party with whom Careplots has a contractual relationship to
        provide the requested product, service or functionality on behalf of www.careplots.com users and customers.
      </Typography>
      <Typography variant="h6">No Unlawful or Prohibited Use/Intellectual Property</Typography>
      <Typography variant="body1" gutterBottom>
        You are granted a non-exclusive, non-transferable, revocable license to access and use www.careplots.com strictly in
        accordance with these terms of use. As a condition of your use of the Site, you warrant to Careplots that you will not use
        the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could
        damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may
        not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided
        for through the Site.
      </Typography>
      <Typography variant="body1" gutterBottom>
        All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and
        any software used on the Site, is the property of Careplots or its suppliers and protected by copyright and other laws that
        protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary
        notices, legends or other restrictions contained in any such content and will not make any changes thereto.
      </Typography>
      <Typography variant="body1" gutterBottom>
        You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or
        in any way exploit any of the content, in whole or in part, found on the Site. Careplots content is not for resale. Your use
        of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not
        delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your
        personal use, and will make no other use of the content without the express written permission of Careplots and the
        copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any
        licenses, express or implied, to the intellectual property of Careplots or our licensors except as expressly authorized by
        these Terms.
      </Typography>
      <Typography variant="h6">Use of Communication Services </Typography>
      <Typography variant="body1" gutterBottom>
        The Site may contain bulletin board services, chat areas, news groups, forums, communities, personal web pages, calendars,
        and/or other message or communication facilities designed to enable you to communicate with the public at large or with a
        group (collectively, "Communication Services"). You agree to use the Communication Services only to post, send and receive
        messages and material that are proper and related to the particular Communication Service.
      </Typography>
      <Typography variant="body1" gutterBottom>
        By way of example, and not as a limitation, you agree that when using a Communication Service, you will not: defame, abuse,
        harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others; publish,
        post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful
        topic, name, material or information; upload files that contain software or other material protected by intellectual
        property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or have received all
        necessary consents; upload files that contain viruses, corrupted files, or any other similar software or programs that may
        damage the operation of another's computer; advertise or offer to sell or buy any goods or services for any business
        purpose, unless such Communication Service specifically allows such messages; conduct or forward surveys, contests, pyramid
        schemes or chain letters; download any file posted by another user of a Communication Service that you know, or reasonably
        should know, cannot be legally distributed in such manner; falsify or delete any author attributions, legal or other proper
        notices or proprietary designations or labels of the origin or source of software or other material contained in a file that
        is uploaded; restrict or inhibit any other user from using and enjoying the Communication Services; violate any code of
        conduct or other guidelines which may be applicable for any particular Communication Service; harvest or otherwise collect
        information about others, including e-mail addresses, without their consent; violate any applicable laws or regulations.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Careplots has no obligation to monitor the Communication Services. However, Careplots reserves the right to review materials
        posted to a Communication Service and to remove any materials in its sole discretion. Careplots reserves the right to
        terminate your access to any or all of the Communication Services at any time without notice for any reason whatsoever.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Careplots reserves the right at all times to disclose any information as necessary to satisfy any applicable law,
        regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in
        whole or in part, in Careplots's sole discretion.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Always use caution when giving out any personally identifying information about yourself or your children in any
        Communication Service. Careplots does not control or endorse the content, messages or information found in any Communication
        Service and, therefore, Careplots specifically disclaims any liability with regard to the Communication Services and any
        actions resulting from your participation in any Communication Service. Managers and hosts are not authorized Careplots
        spokespersons, and their views do not necessarily reflect those of Careplots.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction and/or
        dissemination. You are responsible for adhering to such limitations if you upload the materials.
      </Typography>
      <Typography variant="h6">Materials Provided to www.careplots.com or Posted on Any Careplots Web Page</Typography>
      <Typography variant="body1" gutterBottom>
        Careplots does not claim ownership of the materials you provide to www.careplots.com (including feedback and suggestions) or
        post, upload, input or submit to any Careplots Site or our associated services (collectively "Submissions"). However, by
        posting, uploading, inputting, providing or submitting your Submission you are granting Careplots, our affiliated companies
        and necessary sublicensees permission to use your Submission in connection with the operation of their Internet businesses
        including, without limitation, the rights to: copy, distribute, transmit, publicly display, publicly perform, reproduce,
        edit, translate and reformat your Submission; and to publish your name in connection with your Submission.
      </Typography>
      <Typography variant="body1" gutterBottom>
        No compensation will be paid with respect to the use of your Submission, as provided herein. Careplots is under no
        obligation to post or use any Submission you may provide and may remove any Submission at any time in Careplots's sole
        discretion.
      </Typography>
      <Typography variant="body1" gutterBottom>
        By posting, uploading, inputting, providing or submitting your Submission you warrant and represent that you own or
        otherwise control all of the rights to your Submission as described in this section including, without limitation, all the
        rights necessary for you to provide, post, upload, input or submit the Submissions.
      </Typography>
      <Typography variant="h6">Third Party Accounts </Typography>
      <Typography variant="body1" gutterBottom>
        You will be able to connect your Careplots account to third party accounts. By connecting your Careplots account to your
        third party account, you acknowledge and agree that you are consenting to the continuous release of information about you to
        others (in accordance with your privacy settings on those third party sites). If you do not want information about you to be
        shared in this manner, do not use this feature.
      </Typography>
      <Typography variant="h6">International Users </Typography>
      <Typography variant="body1" gutterBottom>
        The Service is controlled, operated and administered by Careplots from our offices within the USA. If you access the Service
        from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the
        Careplots Content accessed through www.careplots.com in any country or in any manner prohibited by any applicable laws,
        restrictions or regulations.
      </Typography>
      <Typography variant="h6">Indemnification </Typography>
      <Typography variant="body1" gutterBottom>
        You agree to indemnify, defend and hold harmless Careplots, its officers, directors, employees, agents and third parties,
        for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your
        use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this
        Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations.
        Careplots reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject
        to indemnification by you, in which event you will fully cooperate with Careplots in asserting any available defenses.
      </Typography>
      <Typography variant="h6">Arbitration </Typography>
      <Typography variant="body1" gutterBottom>
        In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms and
        Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other
        relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act,
        conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a similar arbitration
        service selected by the parties, in a location mutually agreed upon by the parties. The arbitrator's award shall be final,
        and judgment may be entered upon it in any court having jurisdiction. In the event that any legal or equitable action,
        proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to
        recover its costs and reasonable attorney's fees. The parties agree to arbitrate all disputes and claims in regards to these
        Terms and Conditions or any disputes arising as a result of these Terms and Conditions, whether directly or indirectly,
        including Tort claims that are a result of these Terms and Conditions. The parties agree that the Federal Arbitration Act
        governs the interpretation and enforcement of this provision. The entire dispute, including the scope and enforceability of
        this arbitration provision shall be determined by the Arbitrator. This arbitration provision shall survive the termination
        of these Terms and Conditions.
      </Typography>
      <Typography variant="h6">Class Action Waiver </Typography>
      <Typography variant="body1" gutterBottom>
        Any arbitration under these Terms and Conditions will take place on an individual basis; class arbitrations and
        class/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER
        ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR
        REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both
        you and Careplots agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise
        preside over any form of a representative or class proceeding.
      </Typography>
      <Typography variant="h6">Liability Disclaimer </Typography>
      <Typography variant="body1" gutterBottom>
        THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR
        TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. CAREPLOTS INC AND/OR ITS SUPPLIERS MAY MAKE
        IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
      </Typography>
      <Typography variant="body1" gutterBottom>
        CAREPLOTS INC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND
        ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE
        MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE
        PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. CAREPLOTS INC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL
        WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL
        IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
      </Typography>
      <Typography variant="body1" gutterBottom>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL CAREPLOTS INC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY
        DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT
        LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF
        THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES,
        OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING
        OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF CAREPLOTS
        INC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW
        THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
        IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS
        TO DISCONTINUE USING THE SITE.
      </Typography>
      <Typography variant="h6">Termination/Access Restriction </Typography>
      <Typography variant="body1" gutterBottom>
        Careplots reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any
        portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws
        of the Commonwealth of Pennsylvania and you hereby consent to the exclusive jurisdiction and venue of courts in Pennsylvania
        in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that
        does not give effect to all provisions of these Terms, including, without limitation, this section.
      </Typography>
      <Typography variant="body1" gutterBottom>
        You agree that no joint venture, partnership, employment, or agency relationship exists between you and Careplots as a
        result of this agreement or use of the Site. Careplots's performance of this agreement is subject to existing laws and legal
        process, and nothing contained in this agreement is in derogation of Careplots's right to comply with governmental, court
        and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by
        Careplots with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to
        applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the
        invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the
        intent of the original provision and the remainder of the agreement shall continue in effect.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and Careplots with
        respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or
        written, between the user and Careplots with respect to the Site. A printed version of this agreement and of any notice
        given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this
        agreement to the same extent and subject to the same conditions as other business documents and records originally generated
        and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be
        written in English.
      </Typography>
      <Typography variant="h6">Changes to Terms </Typography>
      <Typography variant="body1" gutterBottom>
        Careplots reserves the right, in its sole discretion, to change the Terms under which www.careplots.com is offered. The most
        current version of the Terms will supersede all previous versions. Careplots encourages you to periodically review the Terms
        to stay informed of our updates.
      </Typography>
      <Typography variant="h6">Contact Us </Typography>
      <Typography variant="body1" gutterBottom>
        Careplots welcomes your questions or comments regarding the Terms:  
      </Typography>
      <Typography variant="body1" gutterBottom>
        Careplots Inc <br />
        101 Lindenwood Dr <br />
        Malvern, Pennsylvania 19355  
      </Typography>
      <Typography variant="body1" gutterBottom>
        Email Address: <br />
        info@careplots.com
      </Typography>
       
      <Typography variant="body1" gutterBottom>
        Telephone number: <br />
        484-806-0102
      </Typography>
       
      <Typography variant="body1" gutterBottom>
        Effective as of December 25, 2020
      </Typography>
    </>
  );
}

export default TermsOfService;
