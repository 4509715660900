/* eslint-disable camelcase */
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import ReportPublicDataComponent from '../ReportPublicDataComponent';

const metricId = 'data_treated_conditions';

const RenderComponent = ({ data }) => {
  if (!data || data.length === 0) {
    return <Typography variant="body1">N/A</Typography>;
  }

  return (
    <ul>
      {data.map((d) => (<li key={d.class_name}>{d.class_name}</li>))}
    </ul>
  );
};

RenderComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ class_name: PropTypes.string })),
};

RenderComponent.defaultProps = {
  data: [],
};

function DrugInfoTreatedConditions() {
  const { drug } = useParams();
  return (
    <ReportPublicDataComponent title="Treated Conditions" metricId={metricId} drug={drug}>
      <RenderComponent />
    </ReportPublicDataComponent>
  );
}

export default DrugInfoTreatedConditions;
