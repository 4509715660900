import { makeStyles, Container, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import HeroVigilance from '../components/HeroVigilance';
import HomeSection from '../components/HomeSection';
import RequestReport from '../components/RequestReport';
import Footer from '../components/Footer';
import RequestReportDevices from '../components/RequestReportDevices';

function OutcomePlots(props) {
  const { color, backgroundImage, maxWidth, title, sectionPage, section } = props;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  const classes = makeStyles((theme) => ({
    root: {
      position: 'relative',
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundAttachment: 'fixed',
      paddingBottom: theme.spacing(6),
      // minHeight: 600,
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        background: 'linear-gradient(180deg, rgba(13,8,32,1) 0%, rgba(13,8,32,0.5) 25%, rgba(13,8,32,0.5) 100%)',
      },
    },
    titleContainer: {
      position: 'relative',
      marginTop: theme.spacing(2),
    },
    pageContainer: {
      padding: theme.spacing(4),
      position: 'relative',
      background: 'rgba(0,0,0,0.4)',
      backdropFilter: 'blur(5px)',
      // background: 'linear-gradient(180deg, rgba(13,8,32,1) 0%, rgba(117,117,117,0.5) 100%)',
    },
    footerContainer: {
      position: 'relative',
      background: 'rgba(0,0,0,0.4)',
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      marginTop: theme.spacing(8),
    },
    pageTitle: {
      color,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  }))();

  let pageTitle = title;
  if (!sectionPage) {
    pageTitle += ` | ${section}`;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="twitter:title" content={`${pageTitle} | Careplots`} />
        <meta property="og:title" content={`${pageTitle} | Careplots`} />
        <meta
          name="description"
          content="Outcome Plots is software-as-a-service analytic platform that enables health care stakeholders to evaluate drug effectiveness and track performance."
        />
        <meta
          name="twitter:description"
          content="Outcome Plots is software-as-a-service analytic platform that enables health care stakeholders to evaluate drug effectiveness and track performance."
        />
        <meta
          property="og:description"
          content="Outcome Plots is software-as-a-service analytic platform that enables health care stakeholders to evaluate drug effectiveness and track performance."
        />
      </Helmet>

      <HeroVigilance />

      <Container className={classes.titleContainer} maxWidth={maxWidth}>
        <Typography className={classes.pageTitle} component="h1" variant="h4" align="center" gutterBottom>
          {title}
        </Typography>
      </Container>
      <Container className={classes.pageContainer} maxWidth={maxWidth}>
        <Typography variant="body1" gutterBottom>
          Outcome Plots is software-as-a-service analytic platform that enables insurers, pharmaceutical manufacturers and employers
          to evaluate medical products effectiveness based on observational data analysis on billions of data points. The data
          platform integrates regulator&apos;s safety data, provider&apos;s clinical data, insurer&apos;s claims data and consumer's
          social media data, tracking key performance indicators for prescription drugs and medical devices.{' '}
        </Typography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            padding: 10,
          }}>
          <img src="/images/outcome-plots.png" alt="Outcome plots" />
        </div>
        <Typography variant="body1" gutterBottom>
          Our scalable and secure cloud platform automates clinical and economic outcomes measurements and benchmarking against peer
          products and it can be used equally by insurers, pharma and employers to determine how value is examined in value based
          care arrangements. Reporting simultaneously on clinical efficacy, economical effectiveness, safety risks and true patient
          sentiment, the platform is enabling efficient design and implementation of value-based care payment models for health care
          products.
        </Typography>
      </Container>
      <HomeSection backgroundImage="/images/pills1-min.jpg">
        <div>
          <Typography color="textPrimary" variant="h2" align="center" style={{ textTransform: 'uppercase' }} gutterBottom>
            Start by searching
          </Typography>
          <Typography variant="h4" component="h3" gutterBottom>
            Drugs
          </Typography>
          <RequestReport />
          <Typography variant="h4" component="h3" gutterBottom style={{ marginTop: '1.2em' }}>
            Devices
          </Typography>

          <RequestReportDevices />
        </div>
      </HomeSection>
      <Container className={classes.footerContainer} maxWidth="lg">
        <Footer />
      </Container>
    </div>
  );
}

OutcomePlots.propTypes = {
  backgroundImage: PropTypes.string,
  color: PropTypes.string,
  maxWidth: PropTypes.string,
  section: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  sectionPage: PropTypes.bool,
};

OutcomePlots.defaultProps = {
  backgroundImage: '/images/pills5-min.jpg',
  color: 'white',
  maxWidth: 'lg',
  sectionPage: false,
};

export default OutcomePlots;
