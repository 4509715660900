import {
  TextField, Typography, CircularProgress, makeStyles,
} from '@material-ui/core';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import DevicesList from './DevicesList';
import ReactGA from '../modules/google-analytics';

function DeviceSearchName() {
  const [deviceName, setDeviceName] = useState('');
  const [searchDeviceName] = useDebounce(deviceName, 1000);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const [products, setProducts] = useState([]);
  const [ready, setReady] = useState(true);

  const classes = makeStyles((theme) => ({
    resultsArea: {
      marginTop: theme.spacing(2),
    },
  }))();

  const handleDeviceNameChange = (e) => {
    setDeviceName(e.target.value);
  };

  useEffect(() => {
    setMessage('');

    async function getDevices() {
      setReady(false);
      try {
        const response = await API.get('devices', '/devicesByName', { queryStringParameters: { name: searchDeviceName } });
        if (response.error) {
          throw response.error;
        }
        setProducts(response);
        if (response.length === 0) {
          setMessage('No results. Please try a different search.');
        }
      } catch (e) {
        setError(e);
      } finally {
        setReady(true);
      }
    }

    // if we have something to work with
    if (searchDeviceName && searchDeviceName.length > 2) {
      ReactGA.event({
        category: 'Device search',
        action: 'Searched device name',
        label: searchDeviceName,
      });
      getDevices();
    }
  }, [searchDeviceName]);

  return (
    <>
      <TextField
        label="Search by device name"
        value={deviceName}
        onChange={handleDeviceNameChange}
        fullWidth
      />
      <div className={classes.resultsArea}>
        {message && <Typography variant="body1" color="primary">{message}</Typography>}
        {error && <Typography variant="body1" color="error">{error.message}</Typography>}
        {ready && <DevicesList products={products} />}
        {!ready && <CircularProgress />}
      </div>
    </>
  );
}

export default DeviceSearchName;
