import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Typography, makeStyles, Grid, Box } from '@material-ui/core';
import { lightBlue } from '@material-ui/core/colors';
import moment from 'moment';
import _ from 'lodash';
import ReportPublicDataComponent from '../ReportPublicDataComponent';
import LabelValue from '../LabelValue';

const metricId = 'data_drug_information';

const RenderComponent = ({ data }) => {
  if (!data || data.length === 0) {
    return <Typography variant="body1">N/A</Typography>;
  }

  const classes = makeStyles((theme) => ({
    label: {
      marginTop: theme.spacing(2),
      color: lightBlue['200'],
      fontWeight: 'bold',
      fontSize: 'larger',
      textTransform: 'uppercase',
    },
    drugName: {
      textTransform: 'capitalize',
      display: 'block',
    },
  }))();

  const {
    atc4classname,
    proprietaryname,
    atc3classname,
    nonproprietaryname,
    startmarketingdate,
    routename,
    dosageformname,
  } = data[0];

  const manufacturers = _.uniq(data.map((d) => d.labelername));

  // sort doses by strength
  const doses = _.uniq(
    _.orderBy(data, [(o) => Number.parseInt(o.active_numerator_strength, 10)]).map(
      (d) => `${d.active_numerator_strength} ${d.active_ingred_unit}`
    )
  );

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <LabelValue label="Drug Name">
          <span className={classes.drugName}>
            {proprietaryname.toLowerCase()} ({nonproprietaryname})
          </span>
        </LabelValue>

        <LabelValue label="Class ATC4">{atc4classname}</LabelValue>

        <LabelValue label="Class ATC3">{atc3classname}</LabelValue>

        <LabelValue label="Market Release date">{moment(startmarketingdate).format('MM/DD/YYYY')}</LabelValue>

        <LabelValue label="Duration on the market">{moment(startmarketingdate).toNow(true)}</LabelValue>
      </Grid>
      <Grid item xs={12} sm={6}>
        <LabelValue label="route">{routename}</LabelValue>
        <LabelValue label="form">{dosageformname}</LabelValue>

        <Typography variant="overline" className={classes.label}>
          Doses
        </Typography>
        <Box mt={-2}>
          <ul>
            {doses.map((d) => (
              <li key={d}>{d}</li>
            ))}
          </ul>
        </Box>
        <Typography variant="overline" className={classes.label}>
          Manufacturers
        </Typography>
        <Box mt={-2}>
          <ul>
            {manufacturers.map((m) => (
              <li key={m}>{m}</li>
            ))}
          </ul>
        </Box>
      </Grid>
    </Grid>
  );
};

RenderComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      proprietaryname: PropTypes.string,
      nonproprietaryname: PropTypes.string,
      atc4classname: PropTypes.string,
      atc3classname: PropTypes.string,
      labelername: PropTypes.string,
      startmarketingdate: PropTypes.datetime,
      endmarketingdate: PropTypes.datetime,
      routename: PropTypes.string,
      active_numerator_strength: PropTypes.string,
      active_ingred_unit: PropTypes.string,
      dosageformname: PropTypes.string,
      producttypename: PropTypes.string,
    })
  ),
};

RenderComponent.defaultProps = {
  data: [],
};

function DrugInfo() {
  const { drug } = useParams();
  return (
    <ReportPublicDataComponent title="Drug Information" metricId={metricId} drug={drug}>
      <RenderComponent />
    </ReportPublicDataComponent>
  );
}

export default DrugInfo;
