import React, { useState, useContext, useEffect } from 'react';
import {
  Grid, Button, makeStyles, FormHelperText, FormControl, FormLabel,
  RadioGroup, FormControlLabel, Radio, CircularProgress, Typography,
} from '@material-ui/core';
import {
  injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement,
} from 'react-stripe-elements';
import { API, Auth, graphqlOperation } from 'aws-amplify';
// import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import CartContext from '../contexts/CartContext';
import UserContext from '../contexts/UserContext';
import { setCustomerStripeCustomerId } from '../modules/customer';
import './StripeCheckout.css';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  formField: {
    color: theme.palette.primary.contrastText,
  },
}));

function StripeCheckout(props) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [cardSelection, setCardSelection] = useState('newCard');
  const [cards, setCards] = useState([]);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [working, setWorking] = useState(false);
  // const [cookies] = useCookies(['cartId']);
  const history = useHistory();

  const { drugs, resetCart } = useContext(CartContext);
  const { updateSubscribedDrugs } = useContext(UserContext);
  const classes = useStyles();

  // check if the user has a stripe customer id
  useEffect(() => {
    setErrorMessage(null);

    Auth.currentAuthenticatedUser().then((currentUser) => {
      const query = `query GetCustomer($username: String!){
        getCustomer(username: $username) {
          stripeCustomerId
          stripeProfile {
            default_source
            sources {
              data {
                id
                last4
                exp_month
                exp_year
              }
            }
          }
        }
      }`;

      API.graphql(graphqlOperation(query, { username: currentUser.username })).then((result) => {
        if (result.data.getCustomer) {
          setStripeCustomerId(result.data.getCustomer.stripeCustomerId);
          setCards(result.data.getCustomer.stripeProfile.sources.data);
          setCardSelection(result.data.getCustomer.stripeProfile.default_source);
        }
      }).catch((e) => {
        setErrorMessage(e.message);
      });
    }).catch((userError) => {
      setErrorMessage(userError.message);
    });

    // get the user and figure out if we have a stripe customer profile set up for
    // getCustomerStripeCustomerId().then((result) => {
    //   setStripeCustomerId(result);
    // }).catch((e) => {
    //   setErrorMessage(e.message);
    // });
    // Auth.currentAuthenticatedUser().then((user) => {
    //   const { attributes } = user;
    //   setStripeCustomerId(attributes['custom:stripeCustomerId']);
    // });
  }, []);

  // useEffect(() => {
  //   if (stripeCustomerId) {
  //     const query = `query GetCustomer($stripeCustomerId: String) {
  //       customer(stripeCustomerId: $stripeCustomerId) {
  //         id
  //         default_source
  //         sources {
  //           data {
  //             id
  //             last4
  //           }
  //         }
  //       }
  //     }
  //     `;
  //     API.graphql(graphqlOperation(query, { stripeCustomerId })).then((result) => {
  //       // console.log(result);
  //       setCards(result.data.customer.sources.data);
  //       setCardSelection(result.data.customer.default_source);
  //     }).catch((e) => {
  //       setErrorMessage(e.message);
  //       // console.error(e);
  //     });
  //   }
  // }, [stripeCustomerId]);

  function handleChange({ error }) {
    if (error) {
      setErrorMessage(error.message);
    }
  }

  const handleCardChange = (event) => {
    setCardSelection(event.target.value);
  };

  function getFundingSource() {
    return new Promise((resolve, reject) => {
      if (cardSelection === 'newCard') {
        props.stripe.createToken().then(({ error, token }) => {
          if (error) {
            reject(error);
          } else {
            resolve({ type: 'token', id: token.id });
          }
        });
      } else {
        resolve({ type: 'card', id: cardSelection }); // one of the card ids Stripe has on file
      }
    });
  }

  function createOrder() {
    setErrorMessage(null);
    setWorking(true);
    getFundingSource()
      .then((fundingSource) => {
        // console.log(`funding source: ${fundingSource}`);
        Auth.currentAuthenticatedUser()
          .then((currentUser) => {
            // console.log(`current user: ${currentUser}`);
            API.post('stripe', '/orders', {
              body: {
                drugs,
                fundingSource,
                user: { attributes: currentUser.attributes }, // no need to send everything
                stripeCustomerId,
              },
            }).then((result) => {
              // console.log(`result: ${result}`);
              if (result.success === false) {
                setErrorMessage(result.error.message);
                setWorking(false);
              } else {
                const promises = [];
                // 1. look for a new customer flag, update stripe customer table with customer id
                // if (result.customerId !== stripeCustomerId) {
                // promises.push(Auth.updateUserAttributes(currentUser, { 'custom:stripeCustomerId': result.stripeCustomerId }));
                promises.push(setCustomerStripeCustomerId(result.customerId));
                // }
                // 2. reset the cart
                promises.push(resetCart());
                promises.push(updateSubscribedDrugs()); // get fresh subscriptions from Stripe

                // do the clean up, redirect to subscriptions
                Promise.all(promises).then(() => {
                  history.push('/dashboard');
                });
              }
            }).catch((e) => {
              setErrorMessage(e.message);
              setWorking(false);
            });
          }).catch((e) => {
            setErrorMessage(e.message);
            setWorking(false);
          });
      }).catch((e) => {
        setErrorMessage(e.message);
        setWorking(false);
      });
  }

  return (
    <>
      {errorMessage && <Typography variant="body1" color="error">{errorMessage}</Typography>}
      {cards.length > 0
        && (
          <FormControl>
            <FormLabel>Select payment method</FormLabel>
            <RadioGroup onChange={handleCardChange} name="paymentCard" value={cardSelection}>
              {cards.map((card) => <FormControlLabel value={card.id} key={card.id} control={<Radio />} label={`Card ending in ${card.last4}, Exp ${card.exp_month}/${card.exp_year}`} />)}
              <FormControlLabel value="newCard" control={<Radio />} label="Enter new card" />
            </RadioGroup>
          </FormControl>
        )}
      {cardSelection === 'newCard'
        && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <CardNumberElement className={classes.formField} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <CardExpiryElement onChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <CardCVCElement onChange={handleChange} />
            </Grid>
          </Grid>
        )}
      <div className={classes.buttons}>
        {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
        {working && <CircularProgress />}
        {!working && (
          <Button
            className={classes.button}
            disabled={working}
            variant="contained"
            color="primary"
            onClick={() => { createOrder(); }}
          >
            Subscribe
          </Button>
        )}
      </div>
    </>
  );
}

export default injectStripe(StripeCheckout);
