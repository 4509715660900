import { API } from 'aws-amplify';

export async function getData({ setData, setReady, setError, metricId, token }) {
  try {
    const response = await API.get('reportData', '/data', { queryStringParameters: { metricId, token } });
    setData(JSON.parse(response.data.json));
  } catch (e) {
    setError(e);
  }
  setReady(true);
}

export async function getPublicData({ setData, setReady, setError, metricId, drug }) {
  try {
    const response = await API.get('reportData', '/public-data', {
      queryStringParameters: {
        metricId,
        drug,
      },
    });
    if (response.data) {
      setData(response.data);
    }
  } catch (e) {
    setError(e);
  } finally {
    setReady(true);
  }
}

export default getData;
