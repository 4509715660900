import {
  Button, FormControl, FormHelperText, Input, InputLabel, Snackbar,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { formStyles } from '../components/formStyles';
import UserContext from '../contexts/UserContext';
import LoginFormsContainer from './LoginFormsContainer';

export default function ForgotPasswordReset() {
  const [error, setError] = useState(null);
  const { email } = useContext(UserContext);
  const history = useHistory();
  const classes = formStyles();

  const handleSubmit = async (values) => {
    setError(null);
    try {
      await Auth.forgotPasswordSubmit(values.email, values.code, values.password);
      history.push('/login');
    } catch (e) {
      setError(e);
    }
  };

  const schema = yup.object({
    email: yup.string().required('Please enter email address.').email('Please enter a valid email address.'),
    password: yup.string().required('Please enter password').matches(/(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      'Password must be at least 8 characters in length and contain: at least one upper case letter, at least one lower case letter, at least one number or a special character.'),
    code: yup.string().required('Please enter the verification code from the email we sent you.'),
  });

  return (
    <LoginFormsContainer title="Reset password">
      <Snackbar open={error} message={<span>{error && error.message}</span>} />
      <Formik
        validationSchema={schema}
        handleSubmit={handleSubmit}
        onSubmit={handleSubmit}
        initialValues={{ email: email || '', password: '', code: '' }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
        }) => (
            <form noValidate onSubmit={handleSubmit} className={classes.form}>
              <FormControl margin="normal" fullWidth hidden={email}>
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  autoFocus
                  required
                  type="email"
                  name="email"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                />
                {(touched.email && !!errors.email) && <FormHelperText error>{errors.email}</FormHelperText>}
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="code">Code</InputLabel>
                <Input
                  required
                  type="text"
                  name="code"
                  value={values.code}
                  onChange={handleChange}
                />
                {touched.code && errors.code && <FormHelperText type="invalid">{errors.code}</FormHelperText>}
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  required
                  type="password"
                  name="password"
                  autoComplete="current-password"
                  value={values.password}
                  onChange={handleChange}
                />
                {touched.password && errors.password && <FormHelperText type="invalid">{errors.password}</FormHelperText>}
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                fullWidth
                color="primary"
                className={classes.submit}
              >
                Reset password
              </Button>

            </form>
          )}
      </Formik>
    </LoginFormsContainer>
  );
}
