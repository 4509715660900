/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomer = /* GraphQL */ `
  query GetCustomer($username: String!) {
    getCustomer(username: $username) {
      username
      stripeCustomerId
      subscribedDrugs
      subscriptions {
        object
        has_more
        data {
          id
          object
          application_fee_percent
          billing_cycle_anchor
          billing_thresholds
          cancel_at
          cancel_at_period_end
          canceled_at
          collection_method
          created
          current_period_end
          current_period_start
          customer
          days_until_due
          default_payment_method
          default_source
          default_tax_rates
          discount
          ended_at
          latest_invoice
          livemode
          next_pending_invoice_item_invoice
          pending_invoice_item_interval
          pending_setup_intent
          pending_update
          quantity
          schedule
          start_date
          status
          tax_percent
          trial_end
          trial_start
        }
      }
      stripeProfile {
        id
        default_source
        sources {
          object
        }
        subscriptions {
          object
          has_more
        }
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $username: String
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomers(
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        stripeCustomerId
        subscribedDrugs
        subscriptions {
          object
          has_more
        }
        stripeProfile {
          id
          default_source
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCart = /* GraphQL */ `
  query GetCart($id: ID!) {
    getCart(id: $id) {
      id
      drugs
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listCarts = /* GraphQL */ `
  query ListCarts(
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        drugs
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
