import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, makeStyles, Link, Grid,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

function DevicesList({ products }) {
  const classes = makeStyles((theme) => ({
    list: {
      // height: 200,
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignContent: 'stretch',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      // overflowY: 'auto',
    },
    listItem: {
      display: 'block',
    },
    deviceName: {
      textTransform: 'capitalize',
    },
  }))();

  if (products.length === 0) {
    return null;
  }

  const items = [...products];
  const half = Math.ceil(items.length / 2);
  const firstHalf = items.splice(0, half);
  const secondHalf = items.splice(-half);

  return (
    <>
      <Typography variant="h6" gutterBottom color="secondary">Please select a product</Typography>
      <Grid container>
        <Grid item xs={12} sm={6}>
          {firstHalf.map((p) => (
            <Link
              component={RouterLink}
              className={classes.listItem}
              key={p.productName}
              to={`/device-details/${encodeURIComponent(p.productName.toLowerCase())}`}
            >
              <Typography variant="body1" component="span" className={classes.deviceName}>
                {p.productName.toLowerCase()}
              </Typography>
            </Link>
          ))}

        </Grid>
        <Grid item xs={12} sm={6}>
          {secondHalf.map((p) => (
            <Link
              component={RouterLink}
              className={classes.listItem}
              key={p.productName}
              to={`/device-details/${encodeURIComponent(p.productName.toLowerCase())}`}
            >
              <Typography component="span" variant="body1" className={classes.deviceName}>
                {p.productName.toLowerCase()}
              </Typography>
            </Link>
          ))}
        </Grid>
      </Grid>
    </>
  );
}

DevicesList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({ productName: PropTypes.string })).isRequired,
};

export default DevicesList;
