import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Helmet from 'react-helmet';
import moment from 'moment';

import BlockContent from '@sanity/block-content-to-react';
import imageUrlBuilder from '@sanity/image-url';
import sanityClient from '../modules/sanity';
import './NewsArticle.css';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function NewsArticle() {
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();

  const classes = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
  }))();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,          
          body,
          releaseDate
       }`,
        { slug }
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);

  const image = (props) => (
    // console.log(props);
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginBottom: 20,
      }}>
      <img src={urlFor(props.node)} width="85%" alt={props.node.alt} style={{ opacity: 0.9 }} />
      <Typography variant="caption" display="block">
        {props.node.caption}
      </Typography>
    </div>
  );

  function summary(body) {
    let summaryText = body[0].children
      .map((child) => child.text)
      .join(' ')
      .substring(0, 200);

    const lastSpace = summaryText.lastIndexOf(' ') + 1;
    summaryText = `${summaryText.substring(0, lastSpace)}...`;
    return summaryText;
  }

  if (!postData) {
    return <span>Loading..</span>;
  }

  return (
    <>
      <Helmet>
        <title>{postData.title}</title>
        <meta name="description" content={summary(postData.body)} />
        <meta name="twitter:description" content={summary(postData.body)} />
        <meta property="og:description" content={summary(postData.body)} />

        <meta name="twitter:site" content="@careplots" />
        <meta name="twitter:title" content={`${postData.title} | Careplots`} />
        <meta name="twitter:creator" content="@careplots" />

        <meta property="og:title" content={`${postData.title} | Careplots`} />
        <meta property="og:type" content="article" />

        <meta property="og:site_name" content="Careplots" />
      </Helmet>

      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Grid container direction="row" justify="center" alignItems="stretch" spacing={2}>
            <Grid item md={8} xs={12} key={postData.slug.current} className="newsArticle">
              <Typography variant="body2" color="inherit">
                {moment(postData.releaseDate).format('MMMM D, YYYY')}
              </Typography>
              <Typography component="h3" variant="h5" color="inherit" gutterBottom>
                {postData.title}
              </Typography>

              <BlockContent
                blocks={postData.body}
                imageOptions={{ w: 320, h: 240, fit: 'max' }}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
                serializers={{
                  types: {
                    image,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
