import {
  Button, FormControl, FormHelperText, Grid, Input, InputLabel, Snackbar, SnackbarContent, Link,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { formStyles } from '../components/formStyles';
import snackbarStyles from '../components/snackbarStyles';
// import UserContext from '../contexts/UserContext';
import LoginFormsContainer from './LoginFormsContainer';


export default function ForgotPassword() {
  const history = useHistory();
  const [error, setError] = useState(null);
  // const { setEmail } = useContext(UserContext);

  const snackbarClasses = snackbarStyles();
  const classes = formStyles();

  const handleSubmit = async (values) => {
    setError(null);
    try {
      await Auth.forgotPassword(values.email);
      // setEmail(values.email);
      history.push('/forgot-password-reset');
    } catch (e) {
      setError(e);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(null);
  };

  const schema = yup.object({
    email: yup.string().required('Please enter email address.').email('Please enter a valid email address.'),
  });

  return (
    <LoginFormsContainer title="Forgot password">

      <Snackbar
        open={!!error}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        variant="error"
        className={snackbarClasses.error}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          message={
            (
              <span id="client-snackbar" className={snackbarClasses.message}>
                <ErrorIcon className={clsx(snackbarClasses.icon, snackbarClasses.iconVariant)} />
                {error && error.message}
              </span>
            )
          }
          className={snackbarClasses.error} />
      </Snackbar>


      <Formik
        validationSchema={schema}
        handleSubmit={handleSubmit}
        onSubmit={handleSubmit}
        initialValues={{ email: '', password: '' }}>
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input autoFocus required type="email" id="email" name="email" label="Email" autoComplete="email" value={values.email} onChange={handleChange} error={(touched.email && !!errors.email)} />
                {(touched.email && !!errors.email) && <FormHelperText error>{errors.email}</FormHelperText>}
              </FormControl>
              <Button type="submit" variant="contained" fullWidth color="primary" className={classes.submit}>Reset password</Button>
              <Grid container>
                <Grid item xs>
                  <Link component={RouterLink} to="/login" color="inherit" variant="body1">Log in</Link>
                  <br />
                </Grid>
                <Grid item>
                  <Link component={RouterLink} to="/signup" color="inherit" variant="body1">Create account</Link>
                </Grid>
              </Grid>

            </form>
          );
        }}
      </Formik>
    </LoginFormsContainer>
  );
}
