import React from 'react';
import { Typography } from '@material-ui/core';

function PrivacyPolicy() {
  return (
    <>
       
      <Typography variant="body1" gutterBottom>
        Protecting your private information is our priority. This Statement of Privacy applies to Careplots.com and Careplots Inc
        and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to
        Careplots Inc include Careplots.com and Careplots. The Careplots website is a informational site containing evaluation of
        medical products performance site. By using the Careplots website, you consent to the data practices described in this
        statement.  
      </Typography>
      <Typography variant="h6" gutterBottom>
        Collection of your Personal Information
      </Typography>
      <Typography variant="body1" gutterBottom>
        In order to better provide you with products and services offered on our Site, Careplots may collect personally identifiable
        information, such as your:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">E-mail Address</Typography>
        </li>
        <li>
          <Typography variant="body1">
            de-identified health information such as health conditions, medical history, treatments, prescription drugs, medical
            devices, reviews and experiences using certain medical products.
          </Typography>
        </li>
      </ul>
      <Typography variant="body1" gutterBottom>
        Careplots may also collect anonymous demographic information, which is not unique to you, such as your:   
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">Age</Typography>
        </li>
        <li>
          <Typography variant="body1">Gender</Typography>
        </li>
        <li>
          <Typography variant="body1">Race</Typography>
        </li>
        <li>
          <Typography variant="body1">
            Insurance coverage, socio economic status, life style, behavioral factors, treatment compliance
          </Typography>
        </li>
      </ul>
      <Typography variant="body1" gutterBottom>
        Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through
        Careplots's public message boards, this information may be collected and used by others.  
      </Typography>
      <Typography variant="body1" gutterBottom>
        We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required
        to provide certain personal information to us when you elect to use certain products or services available on the Site.
        These may include: (a) registering for an account on our Site; (b) signing up for special offers from selected third
        parties; (c) sending us an email message; (d) submitting your credit card or other payment information when ordering and
        purchasing products and services on our Site. To wit, we will use your information for, but not limited to, communicating
        with you in relation to services and/or products you have requested from us. We also may gather additional personal or
        non-personal information in the future.  
      </Typography>
      <Typography variant="h6" gutterBottom>
        Use of your Personal Information
      </Typography>
      <Typography variant="body1" gutterBottom>
        Careplots collects and uses your personal information to operate its website(s) and deliver the services you have requested.
        Careplots may also use your personally identifiable information to inform you of other products or services available from
        Careplots and its affiliates.  
      </Typography>
      <Typography variant="h6" gutterBottom>
        Sharing Information with Third Parties
      </Typography>
      <Typography variant="body1" gutterBottom>
        Careplots does not sell, rent or lease its customer lists to third parties.  
      </Typography>
      <Typography variant="body1" gutterBottom>
        Careplots may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide
        customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information
        except to provide these services to Careplots, and they are required to maintain the confidentiality of your information.  
      </Typography>
      <Typography variant="body1" gutterBottom>
        Careplots may disclose your personal information, without notice, if required to do so by law or in the good faith belief
        that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Careplots or
        the site; (b) protect and defend the rights or property of Careplots; and/or (c) act under exigent circumstances to protect
        the personal safety of users of Careplots, or the public.  
      </Typography>
      <Typography variant="h6" gutterBottom>
        Tracking User Behavior
      </Typography>
      <Typography variant="body1" gutterBottom>
        Careplots may keep track of the websites and pages our users visit within Careplots, in order to determine what Careplots
        services are the most popular. This data is used to deliver customized content and advertising within Careplots to customers
        whose behavior indicates that they are interested in a particular subject area.  
      </Typography>
      <Typography variant="h6" gutterBottom>
        Automatically Collected Information
      </Typography>
      <Typography variant="body1" gutterBottom>
        Information about your computer hardware and software may be automatically collected by Careplots. This information can
        include: your IP address, browser type, domain names, access times and referring website addresses. This information is used
        for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the
        Careplots website.  
      </Typography>
      <Typography variant="h6" gutterBottom>
        Use of Cookies
      </Typography>
      <Typography variant="body1" gutterBottom>
        The Careplots website may use "cookies" to help you personalize your online experience. A cookie is a text file that is
        placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer.
        Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.  
      </Typography>
      <Typography variant="body1" gutterBottom>
        One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to
        tell the Web server that you have returned to a specific page. For example, if you personalize Careplots pages, or register
        with Careplots site or services, a cookie helps Careplots to recall your specific information on subsequent visits. This
        simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on.
        When you return to the same Careplots website, the information you previously provided can be retrieved, so you can easily
        use the Careplots features that you customized.  
      </Typography>
      <Typography variant="body1" gutterBottom>
        You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually
        modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully
        experience the interactive features of the Careplots services or websites you visit.  
      </Typography>
      <Typography variant="h6" gutterBottom>
        Links
      </Typography>
      <Typography variant="body1" gutterBottom>
        This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices
        of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any
        other site that collects personally identifiable information.  
      </Typography>
      <Typography variant="h6" gutterBottom>
        Security of your Personal Information
      </Typography>
      <Typography variant="body1" gutterBottom>
        Careplots secures your personal information from unauthorized access, use, or disclosure. Careplots uses the following
        methods for this purpose:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">SSL Protocol</Typography>
        </li>
        <li>
          <Typography variant="body1">GeoTrust</Typography>
        </li>
      </ul>
      <Typography variant="body1" gutterBottom>
        When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use
        of encryption, such as the Secure Sockets Layer (SSL) protocol.  
      </Typography>
      <Typography variant="body1" gutterBottom>
        We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal
        information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100%
        secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and
        privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any
        and all information and data exchanged between you and us through this Site cannot be guaranteed.  
      </Typography>
      <Typography variant="h6" gutterBottom>
        Right to Deletion
      </Typography>
      <Typography variant="body1" gutterBottom>
        Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:
      </Typography>
      • Delete your personal information from our records; and • Direct any service providers to delete your personal information
      from their records.  
      <Typography variant="body1" gutterBottom>
        Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or
            product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably
            anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between
            you and us;
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those
            responsible for that activity;
          </Typography>
        </li>
        <li>
          <Typography variant="body1">Debug to identify and repair errors that impair existing intended functionality;</Typography>
        </li>
        <li>
          <Typography variant="body1">
            Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise
            another right provided for by law;
          </Typography>
        </li>
        <li>
          <Typography variant="body1">Comply with the California Electronic Communications Privacy Act;</Typography>
        </li>
        <li>
          <Typography variant="body1">
            Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to
            all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or
            seriously impair the achievement of such research, provided we have obtained your informed consent;
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;
          </Typography>
        </li>
        <li>
          <Typography variant="body1">Comply with an existing legal obligation; or</Typography>
        </li>
        <li>
          <Typography variant="body1">
            Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you
            provided the information.
          </Typography>
        </li>
      </ul>
      <Typography variant="h6" gutterBottom>
        Children Under Thirteen
      </Typography>
      <Typography variant="body1" gutterBottom>
        Careplots does not knowingly collect personally identifiable information from children under the age of thirteen. If you are
        under the age of thirteen, you must ask your parent or guardian for permission to use this website.  
      </Typography>
      <Typography variant="h6" gutterBottom>
        Changes to this Statement
      </Typography>
      <Typography variant="body1" gutterBottom>
        Careplots reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes
        in the way we treat personal information by sending a notice to the primary email address specified in your account, by
        placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the
        Site and/or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the
        modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.  
      </Typography>
      <Typography variant="h6" gutterBottom>
        Contact Information
      </Typography>
      <Typography variant="body1" gutterBottom>
        Careplots welcomes your questions or comments regarding this Statement of Privacy. If you believe that Careplots has not
        adhered to this Statement, please contact Careplots at:  
      </Typography>
      <Typography variant="body1" gutterBottom>
        Careplots Inc <br />
        101 Lindenwood Dr <br />
        Malvern, Pennsylvania 19355  
      </Typography>
      <Typography variant="body1" gutterBottom>
        Email Address: <br />
        info@careplots.com
      </Typography>
       
      <Typography variant="body1" gutterBottom>
        Telephone number: <br />
        484-806-0102
      </Typography>
       
      <Typography variant="body1" gutterBottom>
        Effective as of December 25, 2020
      </Typography>
    </>
  );
}

export default PrivacyPolicy;
