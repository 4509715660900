/* eslint-disable import/prefer-default-export */
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { updateCustomer, createCustomer } from '../graphql/mutations';
import { getCustomer } from '../graphql/queries';

export async function setCustomerStripeCustomerId(stripeCustomerId) {
  const user = await Auth.currentAuthenticatedUser();
  const userProfile = await API.graphql(graphqlOperation(getCustomer, { username: user.username }));
  if (!userProfile.data.getCustomer) {
    // we don't have user profile yet. Set it up
    await API.graphql(graphqlOperation(createCustomer, { input: { username: user.username, stripeCustomerId } }));
  } else {
    // update
    await API.graphql(graphqlOperation(updateCustomer, { input: { username: user.username, stripeCustomerId } }));
  }
}
