import React from 'react';
import Disclaimers from './Disclaimers';

function DrugDisclaimers() {
  return (
    <Disclaimers
      text="Careplots uses drug safety events data reported to FDA by physicians and other healthcare professionals as well as data collected from patients from numerous social media sites. This is a valuable source of information for consumers to understand potential risks and adverse reactions associated with certain drugs use, however FDA advises that the these reports alone cannot be used as an indicator of the safety profile of the drug, due to under-reporting issues and lack of certainty that the drug alone caused the reported event. While the data collected from regulators from other countries and patient data from global social media sites will continue to be integrated in Careplots platform, these inferences may become more plausible. Patients are encouraged to report adverse reactions issues to their physicians, but ultimately to follow the medication treatments prescribed by doctors, as they can best assess risk and benefits for each individual patient profile."
    />
  );
}

export default DrugDisclaimers;
