import React, { useEffect, useState, Suspense } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { CircularProgress, Tabs, Tab } from '@material-ui/core';
import Helmet from 'react-helmet';
// import Subscriptions from '../components/Subscriptions';

const Subscriptions = React.lazy(() => (import('../components/Subscriptions')));

function UserDashboard() {
  const [ready, setReady] = useState(false);
  const [tab, setTab] = useState(0);

  const history = useHistory();
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        setReady(true);
      })
      .catch(() => {
        history.push('/login');
      });
  }, []);

  function handleTabChange(e, v) {
    setTab(v);
  }


  if (!ready) return <CircularProgress />;

  return (
    <>
      <Helmet>
        <title>User Dashboard</title>
      </Helmet>
      <Tabs onChange={handleTabChange} value={tab}>
        <Tab label="Subscriptions" />
        {/* <Tab label="Payment Methods" /> */}
        <Tab label="Profile" />
      </Tabs>
      {tab === 0
        &&
        <Suspense fallback={<CircularProgress />}>
          <Subscriptions />
        </Suspense>
      }

    </>
  );
}

export default UserDashboard;
