import { Typography } from '@material-ui/core';
import React from 'react';
import Helmet from 'react-helmet';

function SolutionsHealthCareDigitalTransformations() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Careplots ‘Digital Transformation Framework’ is a developed strategy to improve the health care businesses digitally and it has a broad practice among healthcare organization."
        />
        <meta
          name="twitter:description"
          content="Careplots ‘Digital Transformation Framework’ is a developed strategy to improve the health care businesses digitally and it has a broad practice among healthcare organization."
        />
        <meta
          property="og:description"
          content="Careplots ‘Digital Transformation Framework’ is a developed strategy to improve the health care businesses digitally and it has a broad practice among healthcare organization."
        />
      </Helmet>

      <Typography variant="body1" gutterBottom>
        The Healthcare industry was slow to adopt digital solutions. Very few companies in healthcare sector are able to combine
        digital activity with strong leadership to turn technology into transformation. Also, quite a few Healthcare organizations
        that have taken the digital leap of faith have focused largely on technology enablers. While digital technologies play a
        significant role in transforming the landscape of healthcare industry, they only represent one dimension of digital
        evolution. Companies are leaving a lot on the table by not paying needed attention to evolving their existing business
        models, underlying business processes and a customer experience centric approach to developing and delivering new
        capabilities.
      </Typography>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          marginBottom: 20,
        }}>
        <img
          src="/images/digital-transformation-framework.png"
          alt="Careplots Digital Transformation Framework"
          style={{ opacity: 0.8 }}
        />
      </div>

      <Typography variant="body1" gutterBottom>
        Careplots ‘Digital Transformation Framework’ is a developed strategy to improve the health care businesses digitally and it
        has a broad practice among healthcare organization. The industry is undergoing rapid changes driven by tech trends. Value
        Based Care technologies can link costs to outcomes dramatically changing the reimbursement system. By incorporating
        Artificial Intelligence technologies, medical practitioners can get help with making diagnosis and surgery assistance and
        with telehealth technology, patients can receive quality remote care through virtual medical providers. The Internet of
        Medical Things (IoMT) encourages the usages of wearables, advancing the patient experience and new ways of providing care,
        while new data security technology allow safe storage, exchange, and data processing within all compliance regulations.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Careplots team has the expertise and experience to radically improve the performance and/or reach of enterprises through a
        holistic approach to digital transformation. Our experienced consultants are well versed in leveraging digital advances such
        as advanced analytics, mobility, social media and smart embedded devices while improving the use of legacy technologies to
        improve customer relationships, business processes and value propositions.
      </Typography>

      <Typography variant="body1" gutterBottom>
        By leveraging our experience across different sectors of the health care ecosystem as well as other industries, our
        consulting services can offer several solutions to help our customers get the most out of their investments in digital
        technologies.
      </Typography>
    </>
  );
}

export default SolutionsHealthCareDigitalTransformations;
