import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, Grid, Typography, Box, Button, Paper } from '@material-ui/core';

function InfoSection({ backgroundImage, title, text, link }) {
  const classes = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    container: {
      // backgroundImage: `url(${backgroundImage})`,
      backgroundImage: `linear-gradient(90deg, rgba(13,8,32,1) 0%, rgba(117,117,117,0.2) 100%), url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      minHeight: 200,
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      background: 'linear-gradient(90deg, rgba(13,8,32,1) 0%, rgba(117,117,117,0.2) 100%)',
      // background: 'linear-gradient(90deg, rgba(13,8,32,1) 0%, rgba(117,117,117,0.2) 100%)',
      // backgroundColor: 'rgba(0,0,0,.3)',
    },
    mainFeaturedPostContent: {
      position: 'relative',
      padding: theme.spacing(3),
      paddingTop: theme.spacing(6),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(6),
        paddingTop: theme.spacing(12),
        paddingRight: 0,
      },
    },
  }))();
  return (
    <Paper className={classes.mainFeaturedPost} square>
      {/* <div className={classes.overlay} /> */}
      <div className={classes.container}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Grid container direction="row" justify="center" alignItems="stretch" spacing={2}>
              <Grid item md={8} xs={12}>
                <Typography component="h2" variant="h4" color="inherit" gutterBottom>
                  {title}
                </Typography>
                <Typography variant="body1" color="inherit" paragraph>
                  {text}
                </Typography>
                <Box display="flex" justifyContent="flex-start">
                  <Button component={RouterLink} to={link} variant="outlined">
                    Learn more...
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}

InfoSection.propTypes = {
  // children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  backgroundImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default InfoSection;
