import { Grid } from '@material-ui/core';
import { blue, green, lime, orange, red, indigo } from '@material-ui/core/colors';
import ComputerIcon from '@material-ui/icons/Computer';
import HistoryIcon from '@material-ui/icons/History';
import NavigationIcon from '@material-ui/icons/Navigation';
import PeopleIcon from '@material-ui/icons/People';
import TimelineIcon from '@material-ui/icons/Timeline';
import React from 'react';
import Helmet from 'react-helmet';
import InfoBlock from '../components/InfoBlock';

export default function Home() {
  return (
    <>
      <Helmet>
        <meta name="description" content="Learn about Careplots. History, technology, executive team." />
        <meta name="twitter:description" content="Learn about Careplots. History, technology, executive team." />
        <meta property="og:description" content="Learn about Careplots. History, technology, executive team." />
      </Helmet>
      <div>
        <Grid container direction="row" justify="center" alignItems="stretch" spacing={4}>
          <Grid item xs={12} md={4} style={{ display: 'flex' }}>
            <InfoBlock
              sidePadding={4}
              title="News"
              text="Learn more about latest recent business developments, software releases, and other news from the Careplots team."
              icon={PeopleIcon}
              iconColor={indigo.A100}
              to="/about-us/news"
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ display: 'flex' }}>
            <InfoBlock
              sidePadding={4}
              title="Executive Team"
              text="Founding team with 100+ years of professional and executive experience in the health care and information technology."
              icon={PeopleIcon}
              iconColor={green.A200}
              to="/about-us/executive-team"
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ display: 'flex' }}>
            <InfoBlock
              sidePadding={4}
              title="Mission"
              text="Our objective is to be the analytic partner of choice for health care organizations such as private and public insurers, manufacturers, employers and benefit managers, that are planning to implement innovative value-based care payment models for products such as prescription drugs or medical devices. "
              icon={NavigationIcon}
              iconColor={red.A200}
              to="/about-us/mission"
            />
          </Grid>

          <Grid item xs={12} md={4} style={{ display: 'flex' }}>
            <InfoBlock
              sidePadding={4}
              title="History"
              text="The company was founded in early 2018 by a group of technology entrepreneurs and health care business veterans who came together to work on a unique business idea, which was simply looking at paying for health care products based on real outcomes and performance, and not just paying for use. "
              icon={HistoryIcon}
              iconColor={orange.A200}
              to="/about-us/history"
            />
          </Grid>

          <Grid item xs={12} md={4} style={{ display: 'flex' }}>
            <InfoBlock
              sidePadding={4}
              title="Story"
              text="We're building the ‘blue book' guide of the health care products.
        This will provide benefits to both consumers and health care businesses in determining the value of
        these products in improving general patient health as well as business bottom line or return on investment."
              icon={TimelineIcon}
              iconColor={blue.A200}
              to="/about-us/story"
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ display: 'flex' }}>
            <InfoBlock
              sidePadding={4}
              title="Technology"
              text="The application architecture is supported by a unified data integration platform automates data checks, normalization, validations, unified data modeling, and has a master patient index, integrating regulatory data with social media and health care clinical and administrative data."
              icon={ComputerIcon}
              iconColor={lime.A200}
              to="/about-us/technology"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
