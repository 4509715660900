import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, makeStyles } from '@material-ui/core';

function Disclaimers({ text }) {
  const classes = makeStyles((theme) => ({
    root: {
      border: '1px solid red',
      padding: theme.spacing(2),
    },
  }))();
  return (
    <Box className={classes.root}>
      <Typography variant="body1">
        {text}
      </Typography>
    </Box>
  );
}

Disclaimers.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Disclaimers;
