import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import ReportDataComponent from './ReportDataComponent';

const metricId = 'social_period_end_date';
const RenderComponent = ({ data }) => (<Typography variant="h6" align="left">{(data && data.length > 0 && moment(data[0].period_end_date).format('M/DD/YYYY')) || 'N/A'}</Typography>);


function SocialPeriodEndDate() {
  return (
    <ReportDataComponent title="Period Start Date" metricId={metricId} alignTitle="left">
      <RenderComponent />
    </ReportDataComponent>
  );
}

RenderComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ period_end_date: PropTypes.string })),
};

RenderComponent.defaultProps = {
  data: [],
};

export default SocialPeriodEndDate;
