/* eslint-disable camelcase */
import {
  Typography, Grid, List, ListItem, ListItemIcon, makeStyles,
} from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Line, LineChart, ResponsiveContainer, XAxis, YAxis,
} from 'recharts';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const LineChartWithFilters = ({ data }) => {
  const [selectedReactions, setSelectedReactions] = useState([]);
  const [reactions, setReactions] = useState([]);
  const [shapedData, setShapedData] = useState({});
  const [reactionDataPoints, setReactionDataPoints] = useState({});

  const classes = makeStyles(() => ({
    root: {},
    scrollDiv: {
      height: 500,
      overflowY: 'auto',
      '&:hover': {
        overflow: 'auto',
        paddingRight: '1px',
      },
    },
    item: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  }))();

  useEffect(() => {
    if (data) {
      const shapedDataObject = {};
      const reactionsTracker = {};

      data.forEach(((d) => {
        const dataPoint = {};
        dataPoint[d.label] = d.value;
        shapedDataObject[d.year] = { ...shapedDataObject[d.year], ...dataPoint, ...{ year: d.year } };
        if (!reactionsTracker[d.label]) {
          reactionsTracker[d.label] = {};
        }
        reactionsTracker[d.label][d.year] = d.value; // record datapoint for a reaction
      }));

      const reactionsList = _.keys(reactionsTracker).sort();
      setReactions(reactionsList);
      setSelectedReactions(reactionsList);
      setShapedData(shapedDataObject);
      setReactionDataPoints(reactionsTracker);
    }
  }, [data]);

  const colors = ['#4e79a7', '#f28e2c', '#e15759', '#76b7b2', '#59a14f', '#edc949', '#af7aa1', '#ff9da7',
    '#9c755f', '#bab0ab', '#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99',
    '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a', '#ffff99', '#b15928', '#1f77b4', '#ff7f0e',
    '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'];

  function toggleSelectedReaction(reaction) {
    if (selectedReactions.includes(reaction)) {
      const r = [...selectedReactions];
      r.splice(selectedReactions.indexOf(reaction), 1);
      setSelectedReactions(r);

      // remove reaction from shaped data
      const sd = { ...shapedData };
      _.keys(sd).forEach((key) => {
        delete sd[key][reaction];
      });
      setShapedData(sd);
    } else {
      setSelectedReactions([...selectedReactions, reaction]);

      // add reaction datapoints to shaped data
      const sd = { ...shapedData };
      _.keys(sd).forEach((key) => {
        sd[key][reaction] = reactionDataPoints[reaction][key];
      });
      setShapedData(sd);
    }
  }

  function toggleAllReactions() {
    const sd = { ...shapedData };
    if (selectedReactions.length === reactions.length) {
      setSelectedReactions([]);
      reactions.forEach((reaction) => {
        _.keys(sd).forEach((key) => {
          delete sd[key][reaction];
        });
      });
    } else {
      setSelectedReactions(reactions);
      reactions.forEach((reaction) => {
        _.keys(sd).forEach((key) => {
          sd[key][reaction] = reactionDataPoints[reaction][key];
        });
      });
    }
    setShapedData(sd);
  }

  if (!data || data.length === 0) {
    return <Typography variant="body1">N/A</Typography>;
  }

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="stretch"
      spacing={4}
    >
      <Grid item xs={12} sm={3}>
        <div className={classes.scrollDiv}>
          <List className={classes.root}>
            <ListItem classes={{ root: classes.item }} button onClick={() => { toggleAllReactions(); }}>
              <ListItemIcon>
                {selectedReactions.length === reactions.length ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              </ListItemIcon>
              All
            </ListItem>
            {reactions.map((condition) => (
              <ListItem key={condition} classes={{ root: classes.item }} button onClick={() => { toggleSelectedReaction(condition); }}>
                <ListItemIcon>
                  {selectedReactions.includes(condition) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                </ListItemIcon>
                {condition}
              </ListItem>
            ))}
          </List>
        </div>
      </Grid>
      <Grid item xs={12} sm={9}>
        <ResponsiveContainer height={500}>
          <LineChart
            data={_.values(shapedData)}
            margin={{
              top: 0, right: 0, left: 0, bottom: 0,
            }}
          >
            <XAxis dataKey="year" />
            <YAxis />
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            {/* <Tooltip /> */}
            {/* <Legend /> */}
            {reactions.map((c, i) => <Line key={c} type="monotone" dataKey={c} stroke={colors[i]} dot={false} />)}
          </LineChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};

LineChartWithFilters.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.number, year: PropTypes.number })),
};

LineChartWithFilters.defaultProps = {
  data: [],
};

export default LineChartWithFilters;
