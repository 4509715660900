import { Typography } from '@material-ui/core';
import React from 'react';
import Helmet from 'react-helmet';

function PopHealth() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Careplots Population Health management specialists can analyze mountains of data into a single, actionable patient record, to improve clinical and financial outcomes."
        />
        <meta
          name="twitter:description"
          content="Careplots Population Health management specialists can analyze mountains of data into a single, actionable patient record, to improve clinical and financial outcomes."
        />
        <meta
          property="og:description"
          content="Careplots Population Health management specialists can analyze mountains of data into a single, actionable patient record, to improve clinical and financial outcomes."
        />
      </Helmet>

      <Typography variant="body1" gutterBottom>
        Careplots population health management specialists can analyze mountains of data into a single, actionable patient record,
        and the actions through which care providers can improve both clinical and financial outcomes.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Lower patient engagement costs:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            Get better insights into administrative, encounter-level, clinical and non-clinical information.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">Identify utilization measures that are driving costs</Typography>
        </li>
        <li>
          <Typography variant="body1">Identify conditions and cohorts that are driving utilization and costs.</Typography>
        </li>
      </ul>

      <Typography variant="body1" gutterBottom>
        Improve quality and close care gaps:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">Close care gaps by identifying gaps in care using quality measures/ metrics</Typography>
        </li>
        <li>
          <Typography variant="body1">Better monitor individual conditions and patients within that group</Typography>
        </li>
        <li>
          <Typography variant="body1">Improve medication adherence with greater insights and monitoring tools</Typography>
        </li>
        <li>
          <Typography variant="body1">
            Set quality improvement goals: effective, safe, efficient, patient-centered, equitable, and timely care into patients
            care plans.
          </Typography>
        </li>
      </ul>

      <Typography variant="body1" gutterBottom>
        Drive better health outcomes:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            Use clinical, administrative, and financial analytical measures to improve quality of care while reducing costs.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Better manage patients with chronic and costly conditions by providing solutions that track and manage their specific
            care.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">Identify high risk / high cost populations for targeted intervention.</Typography>
        </li>
      </ul>

      <Typography variant="body1" gutterBottom>
        If you would to learn more about how Careplots can help your organization to design a population health management strategy
        and implement optimal solutions, schedule a call with us and we will provide you a free customized opportunity analysis.
      </Typography>
    </>
  );
}

export default PopHealth;
