import { Typography } from '@material-ui/core';
import React from 'react';
import Helmet from 'react-helmet';

function HealthcareAnalyticsServices() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="CarePlots professional services consulting team guides organizations through the many challenges of healthcare transformation."
        />
        <meta
          name="twitter:description"
          content="CarePlots professional services consulting team guides organizations through the many challenges of healthcare transformation."
        />
        <meta
          property="og:description"
          content="CarePlots professional services consulting team guides organizations through the many challenges of healthcare transformation."
        />
      </Helmet>
      <Typography variant="body1" gutterBottom>
        The cost containment pressures along with outcomes based care are blurring the lines of traditional healthcare boundaries.
        Evidence based, data driven insights into care will drive the next phase of value-based care. Careplots professional
        services consulting team guides organizations through the many challenges of healthcare transformation. Our consulting and
        solutions enhance financial results, streamline operations, and drive better clinical outcomes.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Leverage our world-class team of technology, analytics, and domain experts to streamline data operations, improve
        collaboration, and harness strategic/business insights from your data assets. Careplots offers analytical, advisory, and
        technology services that help organizations navigate complex regulatory, data/information challenges faced by healthcare,
        pharmaceutical, and research organizations. Our professional services leverage industry best practices, using real world
        data, advanced technologies and methodologies to deliver rapid speed to value.
      </Typography>
      <Typography variant="body1" gutterBottom>
        At Careplots, we partner with our customers to deliver innovative healthcare data and analytic solutions leveraging
        Careplots&apos; integration and analytic platform. Careplots provides a variety of consulting and data integration services
        to enable implementation of industry&apos;s most advanced analytic capabilities:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            risk adjustment algorithms such as: CMS HCC, HHS HCC, JHU ACG, CDPS, 3M&apos;s CRG, IBM&apos;s CCM+.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            episodes of care software such as Altarum&apos;s ECR, IBM&apos;s MEG, Optum&apos;s Symmetry ETG.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            diagnosis, procedure and reimbursement groupers such as AHRQ CCS, 3M&apos;s APR DRG or EAPG.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            evidence-based recommendations and information on when tests and procedures may be appropriate, such as choosing wisely
            measures.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">performance and quality of care tools, such as NCQA HEDIS, AHRQ PQI, NQF Metrics.</Typography>
        </li>
        <li>
          <Typography variant="body1">total cost of care solutions, such as HealthPartners.</Typography>
        </li>
        <li>
          <Typography variant="body1">
            health care transparency solutions via bundles of care data processing, such as HCCI&apos;s Guroo.
          </Typography>
        </li>
      </ul>

      <Typography variant="body1" gutterBottom>
        Let our team of seasoned industry experts assist you in developing and operating All Payer Claims Data systems from secure
        data infrastructure, modern data collection and validation portals, flexible data models incorporating clinical data and
        Social Determinants of health factors, implementation of master patient index solutions and integration of clinical data
        integration via FHIR standards and master provider index solutions. If you would like to learning more about how Careplots
        can help your organization, schedule a call with us and we will provide you a free customized opportunity analysis.
      </Typography>
    </>
  );
}

export default HealthcareAnalyticsServices;
